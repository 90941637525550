import React from 'react';

import styled from 'styled-components';

import Logo from '../../assets/images/vitasis-logo.svg';

interface Props {
    loaderOnly?: boolean;
    color?: string;
}
export default function MainLoader({ loaderOnly = false, color = '#fff' }: Props): JSX.Element {
    if (loaderOnly) {
        return <div className="loader" style={{ color: color }} />;
    }

    return (
        <Wrapper>
            <Loader>
                <img src={Logo} alt="loader-logo" />
                {/* <div className="loader" /> */}
            </Loader>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;
const Loader = styled.div`
    width: 50vw;
    min-width: 150px;
    min-height: 10vh;
    max-width: 700px;

    & img {
        margin-bottom: 40px;
    }

    -webkit-animation: scaler 2s infinite ease;
    animation: scaler 2s infinite ease;

    @-webkit-keyframes scaler {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes scaler {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`;
