import { Category } from "../types";
import { Translates } from "./texts";

export enum Languages {
  si = "si",
  en = "en",
}
export interface ContextType {
  selectedLanguage: Languages;
  selectedLanguageValues: Translates;
  changeLanguage: () => void;
  translateCategory: (category: Category) => string;
}

export interface Header {
  title: string;
  inputPlaceholder: string;
}

export interface SubHeader {
  firstTitle: string;
  firstDescription: string;
  secondTitle: string;
  secondDescription: string;
}

export interface DaysTranslate {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

export interface Companies {
  title: string
}

export interface CategoryTranslate {
  All: string;
  Recognition: string;
  Synthesis: string;
  Translate: string;
}

export interface ProductCategory {
  desription: string;
  subtitle: string;
  products: string[]
}

export interface Product {
  title: string;
  subtitle: string;
  description: string;
  categories: Category[],
  url: string | undefined,
  image: string;
}

export interface AllInOneTranslate {
  titleOne: string;
  titleTwo: string;
  titleThree: string;
  description: string;
  buttonText: string;
}

export interface LegalPagesTranslates {
  termsOfUse: string;
  privacyPolicy: string;
  open: string;
  close: string;
  download: string;
}

export interface SupportTranslates {
  headerText: string;
  subheaderText: string;
  writeToUs: string;
  writeToUsContent: string;
  writeToUsInteractionText: string;
  developers: string;
  developersContent: string;
  developersInteractionText: string;
}

export interface CommonTranslates {
  openVideo: string;
  contactUs: string;
  closed: string;
  contact: string;
  workingHours: string;
}

export interface ErrorTranslates {
  tooManyRequestsError: string,
  genericError: string;
  toLongText: string;
}