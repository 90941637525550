import styled from "styled-components";

const CategoryProductItem = styled.li<{isSmaller: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.isSmaller ? "17" : "20"}px;
  line-height: ${props => props.isSmaller ? "25" : "30"}px;
  color: #000000;
`

export default CategoryProductItem;