interface AudioFragment {
  audio: string;
  idx: number;
  text: string;
}

interface PostEnteredResponse {
  tts: AudioFragment[]
}

interface ILoginResponse {
  access_token: string,
  token_type: string,
}

enum ERROR_VALUES {
  Canceled = "Canceled",
  Common = "Common",
  Too_many_requests = "Too_many_requests",
}

enum SUCCESS_VALUES {
  SUCCESS = "SUCCESS",
}

export {
  ERROR_VALUES,
  SUCCESS_VALUES
}

export type {
  AudioFragment,
  PostEnteredResponse,
  ILoginResponse
}