import React, { useRef, useState } from 'react';

import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';
import MobileMenu from '../Home/MobileMenu';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import Dialog from './Dialog';
import CookiesFooter from './CookiesFooter';
import FadeInSection from './FadeInSection';
import MainLoader from './MainLoader';
import usePath from '../../hooks/usePath';
import VideoModal from './VideoModal';

export default function Layout({ children }: any): JSX.Element {
    const { mountMenu, mainLoading, mobileMenueOpen, toggleMobileMenuOpen } = useAppStateContext();

    const [scrollTop, setScrollTop] = useState<number>(0);

    const scrollRef = useRef<HTMLDivElement>(null);

    const path = usePath();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (mobileMenueOpen) {
            toggleMobileMenuOpen();
        }
    }, [path.pathName]);

    const scroller = () => {
        if (!scrollRef || !scrollRef.current) return;

        setScrollTop(scrollRef.current.scrollTop);
    };

    const determineOpacity = () => {
        //150 determines point where shadow should have opacity of 1.
        const opacity = scrollTop > 150 ? 1 : scrollTop / 150;
        return '-5px 15px 80px -30px rgba(163,204,255,' + opacity / 1.5 + ')';
    };

    if (mainLoading) {
        return <MainLoader />;
    }

    return (
        <LayoutWrapper>
            {path.isPathTruebar() && <VideoModal />}
            <Dialog />
            <Content>
                <MainWrapper>
                    {path.isPathHome() && (
                        <>
                            <Header opacity={determineOpacity()} onTop={scrollTop === 0} />
                            {mountMenu && <MobileMenu />}
                        </>
                    )}

                    <VariantWrapper>
                        <ScrollableContent id="scrollable-parent" onScroll={scroller} ref={scrollRef}>
                            {children}
                            <FadeInSection>
                                <Footer />
                            </FadeInSection>
                        </ScrollableContent>
                    </VariantWrapper>
                </MainWrapper>
            </Content>
            <CookiesFooter />
        </LayoutWrapper>
    );
}

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    -webkit-font-smoothing: antialiased;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 300ms ease-out 0s;
`;

const VariantWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    overflow: hidden;
`;

const ScrollableContent = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    min-height: 0px;
    transform: translate3d(0px, 0px, 0px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    flex: 1 1 0%;
`;
