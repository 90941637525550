import styled from "styled-components";

const SubHeaderDescription = styled.p<{isMobile: boolean | undefined, isTablet: boolean | undefined, }>`
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: ${props => props.isMobile ? "normal" : "500"};
  font-size: ${props => props.isMobile ? "16" : props.isTablet ? "17" : "19"}px;
  line-height: ${props => props.isMobile ? "24" : props.isTablet ? "25" : "28"}px;
  color: #2C2C2C;
`

export default SubHeaderDescription;