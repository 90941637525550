interface Lang {
    si: string;
    en: string;
}

interface IntroSection {
    mainText: {
        firstLine: Lang;
        blueLine: Lang;
    };
    subtitle: Lang;
}

interface ProductsSection {
    productTitle: Lang;
    productDesc: Lang;
    productWebsite: Lang;
    productWebsiteLink: Lang;
}

interface Title {
    productsAndProjectTitle: Lang;
    partnersTitle: Lang;
    contactUsTitle: Lang;
    othersAboutUsTitle: Lang;
}

interface AboutUsSectionText {
    title: Lang;
    firstParagraph: Lang;
    secondParagraph: Lang;
    mission: {
        title: Lang;
        si: string;
        en: string;
    };
    vision: {
        title: Lang;
        firstParagraph: Lang;
        secondParagraph: Lang;
    };
}

interface Card {
    title: Lang;
    text: Lang;
}
interface ProjectCards {
    cards: Card[];
}

interface CookiesFooter {
    text: Lang;
    buttonAccept: Lang;
    buttonReadMore: Lang;
}

interface ProjectDialogFacts {
    finance: Lang;
    invest: Lang;
    duration: Lang;
    partners: Lang;
}

interface CookiesDialog {
    title: Lang;
    terms: Lang;
}

export const projectDialogFacts: ProjectDialogFacts = {
    finance: {
        si: `Financiranje`,
        en: `Financing`,
    },
    invest: {
        si: `Vrednost investicije`,
        en: `Investment amount`,
    },
    duration: {
        si: `Trajanje`,
        en: `Duration`,
    },
    partners: {
        si: `Partnerji`,
        en: `Partners`,
    },
};

export const headerFooterLinks: Lang[] = [
    {
        si: `Produkti`,
        en: `Products`,
    },
    {
        si: `O nas`,
        en: `About us`,
    },
    {
        si: `Kontakt`,
        en: `Contact`,
    },
    {
        si: `Delo`,
        en: `Careers`,
    },
    {
        si: `Projekti`,
        en: `Projects`,
    },
];

export const introSectionTexts: IntroSection = {
    mainText: {
        firstLine: {
            si: `IT-inovacije`,
            en: `IT-innovations`,
        },
        blueLine: {
            si: `za vključujočo in opolnomočeno družbo`,
            en: `for an inclusive and empowered society`,
        },
    },
    subtitle: {
        si: `Verjamemo, da so digitalne inovacije ključne za izboljšanje kakovosti življenja v pametni prihodnosti.`,
        en: `We believe that digital innovations are crucial for improving life quality in the smart future.`,
    },
};

export const firstProductSectionText: ProductsSection = {
    productTitle: {
        si: `Vitabits`,
        en: `Vitabits`,
    },
    productDesc: {
        si: `Platforma za celovito daljinsko spremljanje vitalnih znakov, prehrane in aktivnega
    življenjskega sloga, ki zdravstvenemu osebju, zdravnikom, trenerjem in prehranskim
    svetovalcem omogoča kontinuirano spremljanje, obravnavo in komunikacijo na daljavo.`,
        en: `A platform for comprehensive remote monitoring of vital signs, nutrition and active lifestyle,
    which enables caregivers, doctors, trainers and nutritionists not only continuous monitoring
    of users' health status but also communication with them.`,
    },
    productWebsite: {
        si: `Spletna stran`,
        en: `Webpage`,
    },
    productWebsiteLink: {
        si: `https://vitabits.eu/`,
        en: `https://vitabits.eu/`,
    },
};

export const secondProductSectionText: ProductsSection = {
    productTitle: {
        si: `Truebar`,
        en: `Truebar`,
    },
    productDesc: {
        si: `Nabor rešitev s področja govornih tehnologij, ki vključujejo razpoznavo govora in govorca, sintezo govora ter prevajanje. S Truebarjem ostaja jezik živ tudi v digitalnem
    svetu.`,

        en: `Set of comprehensive solutions in the field of speech technologies, which include speech
    recognition, speech synthesis, speech dictation and voice recognition. With Truebar,
    language remains alive in the digital world as well.`,
    },
    productWebsite: {
        si: `Spletna stran`,
        en: `Webpage`,
    },
    productWebsiteLink: {
        si: `/products/truebar`,
        en: `/products/truebar`,
    },
};

export const appTitles: Title = {
    productsAndProjectTitle: {
        si: `Naši produkti`,
        en: `Our products`,
    },
    partnersTitle: {
        si: `Naši partnerji`,
        en: `Our partners`,
    },
    contactUsTitle: {
        si: `Kontaktirajte nas`,
        en: `Contact us`,
    },
    othersAboutUsTitle: {
        si: `Drugi o nas`,
        en: `Others about us`,
    },
};

export const aboutUsSectonText: AboutUsSectionText = {
    title: {
        si: `Podjetje Vitasis je mlado in hitro rastoče podjetje, ki je bilo leta 2018 ustanovljeno na
    pobudo treh tehnofilov z raznolikimi in bogatimi izkušnjami s področja razvoja sodobnih
    informacijskih tehnologij ter digitalizacije.`,
        en: `Vitasis is a young and fast-growing company founded in 2018 on the initiative of 3
    technophiles with diverse and rich experience in the development of modern information
    technologies and management of IT projects.`,
    },
    firstParagraph: {
        si: `Četrta industrijska revolucija je realnost, ki se ne odraža le v razvoju novih tehnologij, temveč
    vključuje tudi spremembe v naravi dela, odnosih, komunikaciji, miselnosti. Vitasis sledi
    novodobnemu tempu in se osredotoča na razvoj kvalitetnih, dostopnih, uporabnikom
    prijaznih, predvsem pa vključujočih IT-rešitev naslednje generacije.`,
        en: `The fourth industrial revolution is a reality that reflects not only in the development of new
    technologies, but also in changes in the nature of work, relationships, communication,
    mentality. Vitasis follows the innovation pace and focuses on the development of high
    quality, affordable, user-friendly, and above all inclusive next-generation IT solutions.`,
    },
    secondParagraph: {
        si: `Z inovativnimi informacijskimi rešitvami želimo graditi vključujoč digitalni svet ter
    prispevati k opolnomočenju uporabnikov v skrbi za lastno zdravje in dobro počutje.`,
        en: `Our mision is to promote development of inclusive digital world with our innovative IT solutions and to contribute
    to empowerment of users in taking care of their own health and well-being.`,
    },
    mission: {
        title: {
            si: 'NAŠE POSLANSTVO',
            en: 'OUR MISSION',
        },
        si: `Z inovativnimi informacijskimi rešitvami graditi vključujoč digitalni svet ter
    prispevati k opolnomočenju uporabnikov v skrbi za lastno zdravje in dobro počutje.`,
        en: `To promote development of inclusive digital world with our innovative IT solutions and to contribute
    to empowerment of users in taking care of their own health and well-being.`,
    },
    vision: {
        title: {
            si: `NAŠA VIZIJA`,
            en: `OUR VISION`,
        },
        firstParagraph: {
            si: `Postati prepoznavno podjetje v regiji s področja razvoja digitalnih rešitev, ki izboljšujejo kakovost življenja in
      pospešujejo digitalno preobrazbo z namenom opolnomočenja najširšega kroga uporabnikov.`,
            en: `To become a recognizable company in Slovenia and the wider region in the field of planning
      and development of IT solutions that improve quality of life in the information society and
      advance digital transformation in order to empower a wide range of users.`,
        },
        secondParagraph: {
            si: `Biti prepoznani kot eno najprivlačnejših delovnih okolij pri razvoju inovativnih
      informacijskih tehnologij.`,
            en: `To be recognised as one of most attractive employers in the development of innovative
      information technologies.`,
        },
    },
};

const firstCard: Card = {
    title: {
        si: `Govorne tehnologije`,
        en: `Speech technologies`,
    },
    text: {
        si: `Na področju govornih tehnologij se osredotočamo na razvoj tehnik, ki omogočajo upravljanje naprav z govorom. Naše rešitve omogočajo pretvarjanje posnetega govora v besedilo, podnaslavljanje govora v realnem času, detekcijo govora, detekcijo govorca ipd. Na podlagi potreb strank ponujamo razvoj domensko prilagojenih rešitev.`,
        en: `In the field of speech technologies, we focus on variety of tasks that help in developing voice interfaces, including automatic speech recognition, speech synthesis, speaker identification, voice cloning, etc. Based on client's needs we offer the development of domain specific solutions.`,
    },
};
const secondCard: Card = {
    title: {
        si: `Obdelava in upravljanje podatkov`,
        en: `Data processing and management`,
    },
    text: {
        si: `Izkušeni smo pri razvoju arhitektur in programskih rešitev za upravljanje velikih količin podatkov in za razvoj podatkovno intenzivnih sistemov. Naše strokovno znanje se razteza od pridobivanja in prenosa podatkov do njihove analize in vizualizacije.`,
        en: `We are experienced in developing architectures and software solutions for managing big data and for the development of data intensive systems. Our expertise spans from data acquisition and transfer to analysis and visualization.`,
    },
};
const thirdCard: Card = {
    title: {
        si: `Razvoj po meri`,
        en: `Custom development`,
    },
    text: {
        si: `V sodelovanju s svojimi strankami ponujamo načrte rešitev, ki se osredotočajo na vzdržne arhitekture in uporabniške vmesnike, ki ponujajo odlično uporabniško izkušnjo. Z upoštevanjem najboljših praks in standardov ponujamo visokokakovostne in inovativne rešitve.`,
        en: `In cooperation with our clients, we provide solution blueprints focusing on sustainable architectures and user interface that offer great user experience. By following best practices and standards we deliver high quality and innovative solutions.`,
    },
};
export const projectCards: ProjectCards = {
    cards: [firstCard, secondCard, thirdCard],
};

export const cookiesFooter: CookiesFooter = {
    text: {
        si: `Ta stran uporablja piškotke. Z nadaljevanjem uporabe te strani soglašate z uporabo piškotkov.`,
        en: `This site uses cookies. By continuing to use this site, you consent to the use of cookies.`,
    },
    buttonAccept: {
        si: `Sprejmi in nadaljuj`,
        en: `Accept and close`,
    },
    buttonReadMore: {
        si: `Preberi več`,
        en: `Read more`,
    },
};

export const cookiesDialog: CookiesDialog = {
    title: {
        si: `Piškotki`,
        en: `Cookies`,
    },
    terms: {
        si: `Naše spletno mesto lahko v brskalniku vašega računalnika pusti tako imenovani »piškotek«. 
    Piškotki so majhne besedilne datoteke, ki nam dajejo podatke o tem, kolikokrat posameznik obišče našo stran in kaj ga zanima v teh obiskih. 
    Piškotki sami ne vsebujejo nobenih podatkov, ki bi omogočali identifikacijo posameznika, 
    če pa nam te podatke posredujete sami, na primer z registracijo, se ti lahko povežejo s podatki,ki so shranjeni v piškotku. 
    Vedno imate možnost, da piškotke sprejmete ali zavrnete. 
    Večina spletnih brskalnikov samodejno sprejme piškotke, 
    kar lahko spremenite v nastavitvah tako, da računalnik piškotke zavrne ali pa da prejmete opozorilo, preden se piškotek shrani.`,
        en: `
    Our website can drop the so-called »cookie« in your computer’s browser. 
    Cookies are small text files that give us information on how many times an 
    individual visits our page and what is interested in these visits. Cookies themselves
    do not contain any data that would allow the individual to identify, but if you provide this 
    information to us, for example, by registering, they can connect to the data stored in the cookie. You always have the option to accept or reject cookies. 
    Most web browsers automatically accept cookies, which you can change in your settings by rejecting your cookies or receiving a warning before the cookie is saved.
    `,
    },
};
