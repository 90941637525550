import React from 'react';
import FadeInSection from '../../../components/Common/FadeInSection';
import useTheme, { ThemeProvider } from './contexts/Theme/ThemeContext';
import { ColorSchemes } from './contexts/Theme/types';
import useTranslate, { TranslateProvider } from './contexts/Translate/TranslateContext';
import { Languages } from './contexts/Translate/types';
import AllInOne from './sections/AllInOne';
import Header from './sections/Header';
import Products from './sections/Products';
import Recognition from './sections/Recognition';
import SubHeader from './sections/SubHeader';
import Synthesis from './sections/Synthesis';
import Translation from './sections/Translation';

const TruebarContent = () => {
    const {colorScheme, changeToColorScheme} = useTheme()
    const { changeLanguage, selectedLanguage } = useTranslate()

    const handleColorShange = () => {
        if (colorScheme === ColorSchemes.Funky) {
            changeToColorScheme(ColorSchemes.Business)
        } else if (colorScheme === ColorSchemes.Business) {
            changeToColorScheme(ColorSchemes.Funky)
        }
    }

    return (
        <div style={{ backgroundColor: "#FFFFFF", position: 'relative' }}>
            {/*<button onClick={changeLanguage}>{selectedLanguage === Languages.en ? "Slovensko" : "English"}</button>
            <button onClick={handleColorShange}>{colorScheme === ColorSchemes.Business ? "Get funky" : "Down to bussines"}</button>*/}
            <FadeInSection>
                <Header />
            </FadeInSection>
            <FadeInSection>
                <SubHeader />
            </FadeInSection>
            {/*<FadeInSection>
                <Companies />
            </FadeInSection>*/}
            <FadeInSection>
                <Recognition />
            </FadeInSection>
            <FadeInSection>
                <Synthesis />
            </FadeInSection>
            <FadeInSection>
                <Translation />
            </FadeInSection>
            <FadeInSection>
                <Products />
            </FadeInSection>
            <FadeInSection>
                <AllInOne />
            </FadeInSection>
        </div>
    )
}
export default function Truebar(): JSX.Element {
    return (
        <TranslateProvider>
            <ThemeProvider>
                <TruebarContent />
            </ThemeProvider>
        </TranslateProvider>
    );
}




