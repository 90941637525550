import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import MainLoader from './MainLoader';

const Player = () => {
  const { videoModalUrl } = useAppStateContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoWrapperRef = useRef<HTMLDivElement>(null);

  const [videoLoading, setVideoLoading] = useState<boolean>(true);

  return (
    <PlayerWrapper ref={videoWrapperRef}>
      {videoLoading && (
        <LoaderWrapper>
          <MainLoader loaderOnly={true} color={'#83A4CC'} />
        </LoaderWrapper>
      )}
      <VideoFrame
        onLoadedData={() => setVideoLoading(false)}
        ref={videoRef}
        autoPlay={true}
        hidden={videoLoading}
        src={videoModalUrl}
        controls />
    </PlayerWrapper>
  )
}

export default Player;

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const LoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VideoFrame = styled.video`
  height: 100%;
  width: 100%;
  background-color: black;
  border-radius: 16px;
`