import React from 'react';

import styled from 'styled-components';

interface FadeInSectionProps {
    children: any;
}

export default function FadeInSection({ children }: FadeInSectionProps) {
    const [isVisible, setVisible] = React.useState<boolean>(true);
    const domRef = React.useRef<any>(null);

    React.useEffect(() => {
        const element = domRef.current;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => setVisible(entry.isIntersecting));
        });
        observer.observe(element);

        return () => observer.unobserve(element);
    }, []);

    return (
        <FadeInSectionWrapper ref={domRef} isVisible={isVisible}>
            {children}
        </FadeInSectionWrapper>
    );
}

const FadeInSectionWrapper = styled.div<{ isVisible: boolean }>`
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    /*     transform: translateY(20vh); */
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
    transition: opacity 2.2s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
`;
