import React from 'react';

import styled from 'styled-components';

import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages } from '../../Contexts/types';
import { useDimensions } from '../../hooks/useDimensions';

import { aboutUsSectonText, projectCards } from '../../data/texts';

import logo from '../../assets/images/vitasis-logo-white.png';

import govornesvg from '../../assets/images/icons/1.svg';
import obdelavasvg from '../../assets/images/icons/2.svg';
import razvojsvg from '../../assets/images/icons/3.svg';

interface AboutCardProps {
    icon: any;
    title: string;
    text: string;
    tablet?: boolean;
    mobile?: boolean;
    last?: boolean;
}

export default function AboutUsSection(): JSX.Element {
    const { isMobile, currentLanguage } = useAppStateContext();
    const { ref, dimensions } = useDimensions();
    return (
        <AboutUsWrapper id="about-us">
            <AboutUsContent ref={ref}>
                <UpperSection tablet={dimensions.width <= 760} mobile={isMobile}>
                    <LogoWrapper>
                        <img
                            src={logo}
                            alt="logo"
                            style={{
                                width: dimensions.width <= 870 ? '160px' : '185px',
                                height: dimensions.width <= 870 ? '20px' : '25px',
                            }}
                        />
                    </LogoWrapper>
                    <InfoWrapper tablet={dimensions.width <= 760} mobile={isMobile}>
                        <h3>
                            {currentLanguage === Languages.SI ? aboutUsSectonText.title.si : aboutUsSectonText.title.en}
                        </h3>
                        <p>
                            {currentLanguage === Languages.SI
                                ? aboutUsSectonText.firstParagraph.si
                                : aboutUsSectonText.firstParagraph.en}
                        </p>
                        <p>
                            {currentLanguage === Languages.SI
                                ? aboutUsSectonText.secondParagraph.si
                                : aboutUsSectonText.secondParagraph.en}
                        </p>
                    </InfoWrapper>
                </UpperSection>
                <BottomSection tablet={dimensions.width <= 870} mobile={isMobile}>
                    <AboutCard
                        tablet={dimensions.width <= 870 && dimensions.width > 760}
                        mobile={dimensions.width <= 760}
                        icon={govornesvg}
                        title={
                            currentLanguage === Languages.SI
                                ? projectCards.cards[0].title.si
                                : projectCards.cards[0].title.en
                        }
                        text={
                            currentLanguage === Languages.SI
                                ? projectCards.cards[0].text.si
                                : projectCards.cards[0].text.en
                        }
                    />
                    <AboutCard
                        tablet={dimensions.width <= 870 && dimensions.width > 760}
                        mobile={dimensions.width <= 760}
                        icon={obdelavasvg}
                        title={
                            currentLanguage === Languages.SI
                                ? projectCards.cards[1].title.si
                                : projectCards.cards[1].title.en
                        }
                        text={
                            currentLanguage === Languages.SI
                                ? projectCards.cards[1].text.si
                                : projectCards.cards[1].text.en
                        }
                    />
                    <AboutCard
                        tablet={dimensions.width <= 870 && dimensions.width > 760}
                        mobile={dimensions.width <= 760}
                        icon={razvojsvg}
                        title={
                            currentLanguage === Languages.SI
                                ? projectCards.cards[2].title.si
                                : projectCards.cards[2].title.en
                        }
                        text={
                            currentLanguage === Languages.SI
                                ? projectCards.cards[2].text.si
                                : projectCards.cards[2].text.en
                        }
                        last={dimensions.width <= 810}
                    />
                </BottomSection>
            </AboutUsContent>
        </AboutUsWrapper>
    );
}

function AboutCard({ icon, title, text, tablet, mobile, last }: AboutCardProps) {
    return (
        <CardWrapper tablet={tablet} mobile={mobile} style={last ? { marginBottom: '0' } : {}}>
            <img src={icon} alt="whatwedo" />
            <h5>{title}</h5>
            <p>{text}</p>
        </CardWrapper>
    );
}

const CardWrapper = styled.div<{
    tablet: boolean | undefined;
    mobile: boolean | undefined;
}>`
    display: flex;
    height: ${(props) => (props.tablet ? '440px' : props.mobile ? 'auto' : '400px')};

    flex-direction: column;
    width: 100%;
    max-width: ${(props) => (props.tablet ? '200px' : props.mobile ? '100%' : '260px')};

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: ${(props) => (props.mobile ? '120px' : '0px')};

    & img {
        margin-bottom: 65px;
        width: 59px;
        height: 59px;
    }

    & h5 {
        color: #c2d5ed;
        font-size: 17px;
        margin: 0;
        padding: 0;
        margin-bottom: 25px;
    }

    & p {
        font-size: 17px;
        margin: 0;
        padding: 0;
    }
`;

const InfoWrapper = styled.div<{
    tablet: boolean | undefined;
    mobile: boolean | undefined;
}>`
    display: flex;
    width: 100%;
    max-width: 725px;
    flex-direction: column;

    margin-left: ${(props) => (props.tablet ? '0px' : '85px')};
    margin-top: ${(props) => (props.tablet ? '55px' : '0px')};

    & p {
        margin: 0;
        padding: 0;
    }

    & h3 {
        font-size: ${(props) => (props.tablet ? '24px' : '28px')};
        margin: 0;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        color: #c2d5ed;
        margin-bottom: 32px;
    }

    & h4 {
        font-size: ${(props) => (props.tablet ? '20px' : '24px')};
        margin: 0;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        color: #c2d5ed;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    & p {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 16px;
    }
`;

const UpperSection = styled.div<{
    tablet: boolean | undefined;
    mobile: boolean | undefined;
}>`
    display: flex;
    width: 100%;

    ${(props) => props.tablet && 'flex-direction: column;'}
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: ${(props) => (props.tablet ? '120px' : '100px')};
`;

const BottomSection = styled.div<{
    tablet: boolean | undefined;
    mobile: boolean | undefined;
}>`
    /*   display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => (props.tablet ? '200px' : '260px')}, 1fr)
  );
  gap: 45px; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    justify-content: space-between;
    align-items: center;
`;

const LogoWrapper = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
`;

const AboutUsWrapper = styled.div`
    width: 100%;
    min-height: 900px;
    background-color: #000000;
    padding: 80px 3vw 120px 3vw;

    @media (max-width: 812px) {
        padding: 70px 3vw 105px 3vw;
    }

    @media (max-width: 620px) {
        padding: 70px 20px 100px 20px;
    }
`;

const AboutUsContent = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0px auto;
`;
