import React, { FC, SyntheticEvent } from 'react';
import styled from "styled-components";
import useTheme from "../../../contexts/Theme/ThemeContext";
import Play from '../../../assets/images/common/play.svg';
import { useAppStateContext } from '../../../../../../Contexts/AppStateProvider';
import useTranslate from '../../../contexts/Translate/TranslateContext';

interface Props {
  url?: string;
  productName?: string
}
const PlayButton: FC<Props> = ({ url, productName }) => {

  const { colorSchemeValues } = useTheme();
  const { openVideoModal } = useAppStateContext();
  const { selectedLanguageValues } = useTranslate()

  
  if (url) {
    return (
      <ButtonWrapperUrl onClick={() => openVideoModal(url)}>
        <Button backgroundColor={colorSchemeValues.extraordinary}>
          <img alt="Play" width={'15px'} style={{ marginLeft: '5px' }} src={Play} />
        </Button>
        <ButtonText video={true} color={colorSchemeValues.extraordinary}>{selectedLanguageValues.common.openVideo}</ButtonText>
      </ButtonWrapperUrl>
    )
  } else {
    return (
      <ButtonWrapperUrl href={`mailto:info@vitasis.si?subject=${productName}`}>
        <ButtonText video={false} color={colorSchemeValues.extraordinary}>{selectedLanguageValues.common.contactUs}</ButtonText>
      </ButtonWrapperUrl>
    )
  }
}


const ButtonWrapperUrl = styled.a`
  display: flex;
  align-items: center;
  margin-top: 24px;
  z-index: 20;
`

const Button = styled.div<{ backgroundColor: string }>`
  width: 52px;
  height: 52px;
  background-color: ${props => props.backgroundColor};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
`

const ButtonText = styled.p<{ color: string, video: boolean }>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${props => props.color};
  margin-left: ${props => props.video ? "16px" : "8px"};
`
export default PlayButton;