import React, { FC } from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import useTheme from '../../contexts/Theme/ThemeContext';
import { Category } from '../../contexts/types';
import FilterButton, { ButtonBackground } from '../atoms/FilterButton';

interface Props {
  activeFilter: Category | undefined;
  updateCategory: (category: Category) => void;
}
const FilterBar: FC<Props> = ({ activeFilter, updateCategory }) => {

  const { isMobile, isTablet } = useAppStateContext();
  const { getCategoryColor } = useTheme();
  const filters = [Category.All, Category.Recognition, Category.Synthesis, Category.Translate]

  return (
    <FilterBarWrapper isMobile={isMobile} isTablet={isTablet}>
      {filters.map(filter =>
        <FilterButton
          key={filter}
          updateCategory={updateCategory}
          active={activeFilter === filter}
          category={filter}
          mainColor={getCategoryColor(filter)} />
      )}
    </FilterBarWrapper>
  )
}

const FilterBarWrapper = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  display: flex;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
  margin-bottom: ${props => props.isMobile ? "40" : "56"}px;
  ${props => props.isMobile ? "align-items: center" : "justify-content: center"};

  & ${ButtonBackground}:not(:first-child) {
    margin-left: ${props => props.isMobile ? "0" : props.isTablet ? "32" : "40"}px;
  }
`

export default FilterBar;