import React from 'react';

import styled from 'styled-components';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useDimensions } from '../../hooks/useDimensions';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages } from '../../Contexts/types';
import { appTitles } from '../../data/texts';

import googleLogo from '../../assets/images/google-logo.png';

const googleMapDarkThemeStyles = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#212121',
            },
        ],
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#212121',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#181818',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#1b1b1b',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#2c2c2c',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#8a8a8a',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#373737',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#3c3c3c',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [
            {
                color: '#4e4e4e',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#3d3d3d',
            },
        ],
    },
];

const vitasisGeoLoc = {
    lat: 45.812671,
    lng: 14.319397,
};

const markerPosition = {
    lat: 45.812671,
    lng: 14.319397,
};

const containerStyle = {
    width: '100%',
    maxWidth: '720px',
    flexShrink: 1,
    minHeight: '420px',
};

const mapCenter = {
    lat: vitasisGeoLoc.lat,
    lng: vitasisGeoLoc.lng,
};

const googleMapsKey = (window as any)._env_.REACT_APP_GOOGLE_MAPS_KEY;

export default function ContactUsSection(): JSX.Element {
    const { currentLanguage } = useAppStateContext();
    const { ref, dimensions } = useDimensions();

    return (
        <ContactUsSectionWrapper ref={ref} id="contact">
            <ContactUsSectionContent currWidth={dimensions.width}>
                <img
                    src={googleLogo}
                    alt="google logo"
                    style={{ position: 'absolute', zIndex: 100, bottom: '5px', left: '5px', width: '60px' }}
                />
                <LoadScript googleMapsApiKey={googleMapsKey}>
                    <GoogleMap
                        options={{
                            styles: googleMapDarkThemeStyles,
                        }}
                        mapContainerStyle={containerStyle}
                        center={mapCenter}
                        zoom={10}
                    >
                        <Marker position={markerPosition} />
                    </GoogleMap>
                </LoadScript>
                <InfoWrapper currWidth={dimensions.width}>
                    <div>
                        {currentLanguage === Languages.SI && <h5>{appTitles.contactUsTitle.si}</h5>}
                        {currentLanguage === Languages.EN && <h5>{appTitles.contactUsTitle.en}</h5>}

                        <Adress>
                            <span>Vitasis d.o.o</span>
                            <span>Partizanska Cesta 8A</span>
                            <span>1381 Rakek</span>
                            <span>Slovenia</span>
                        </Adress>
                        <Phone>
                            <span>T: +386 31 667 667</span>
                            <a href="mailto:info@vitasis.si">info@vitasis.si</a>
                        </Phone>
                    </div>
                </InfoWrapper>
            </ContactUsSectionContent>
        </ContactUsSectionWrapper>
    );
}

const InfoWrapper = styled.div<{ currWidth: number }>`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: ${(props) => (props.currWidth <= 650 ? 'left' : 'center')};
    padding-left: ${(props) => (props.currWidth <= 650 ? '5%' : '0')};
    width: 100%;

    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #ffffff;

    padding-top: ${(props) => (props.currWidth <= 650 ? '80px' : '0')};
    padding-bottom: ${(props) => (props.currWidth <= 650 ? '72px' : '0')};

    & h5 {
        font-size: 36px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
        padding: 0;
        margin: 0;
        margin-bottom: 35px;

        @media (max-width: 550px) {
            font-size: 28px;
        }
    }
`;

const Adress = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 35px;

    & span:first-child {
        font-weight: bold;
    }
`;

const Phone = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & a {
        color: #ffffff;
    }
`;

const ContactUsSectionWrapper = styled.div`
    width: 100%;
    background-color: #0e0e0e;
    padding: 0;
    padding-left: 0;
`;

const ContactUsSectionContent = styled.div<{ currWidth: number }>`
    position: relative;
    width: 100%;
    display: flex;
    min-height: ${(props) => (props.currWidth <= 650 ? '830px' : props.currWidth <= 780 ? '460px' : '612px')};
    flex-direction: ${(props) => (props.currWidth <= 650 ? 'column-reverse' : 'row')};
    max-width: 1200px;

    margin: 0px auto;
`;
