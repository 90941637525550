import React from 'react';
import email from '../../assets/images/icons/email.svg';
import developers from '../../assets/images/icons/developers.svg';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import useTranslate, { TranslateProvider } from '../products/truebar/contexts/Translate/TranslateContext';
import { Languages } from '../products/truebar/contexts/Translate/types';
import SupportCard from './SupportCard';

const Support = () => {

    const {
        isMobile,
        isTablet,
    } = useAppStateContext()

    const {
        selectedLanguageValues,
        selectedLanguage,
        changeLanguage
    } = useTranslate()

    /*const [isEmailClientOpened, setIsEmailClientOpened] = useState<boolean>(false);
    const closeEmailClient = () => setIsEmailClientOpened(false)
    const openEmailClient = () => setIsEmailClientOpened(true)*/

    const isMobileOrTablet = isMobile || isTablet
    return (
        /*<Modal modalState={isEmailClientOpened} closeModal={closeEmailClient}>
                <form action="/my-handling-form-page" method="post">
                    <ul>
                        <li>
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="user_name" />
                        </li>
                        <li>
                            <label htmlFor="mail">E-mail:</label>
                            <input type="email" id="mail" name="user_email" />
                        </li>
                        <li>
                            <label htmlFor='msg'>Message:</label>
                            <textarea id="msg" name="user_message"></textarea>
                        </li>
                    </ul>
                </form>
    </Modal>*/
        <>
            <div style={{float: 'right', paddingTop: '2vh', paddingRight: '5vw'}}>
                <button style={{color: 'white'}} onClick={() => changeLanguage()}>
                    {selectedLanguage === Languages.si ? 'EN' : 'SI'}
                </button>
            </div>


            <div style={{ paddingTop: '5vh' }}>
                <h1 style={{ color: '#c2d5ed', textAlign: 'center' }}>{selectedLanguageValues.supportTranslates.headerText}</h1>
                <h3 style={{ color: '#c2d5ed', textAlign: 'center' }}>{selectedLanguageValues.supportTranslates.subheaderText}</h3>
            </div>

            <div style={{ display: 'grid', columnGap: '5vw', rowGap: '5vw', gridTemplateColumns: isMobileOrTablet ? 'auto' : 'auto auto', padding: '5vh 10vw 10vh 10vw' }}>
                <SupportCard
                    href={"mailto:support@vitasis.si"}
                    isMobileOrTablet={isMobileOrTablet}
                    headerText={selectedLanguageValues.supportTranslates.writeToUs}
                    content={<ContactContent />}
                    image={email}
                    clickText={selectedLanguageValues.supportTranslates.writeToUsInteractionText} />
                <SupportCard
                    href={"https://developers.vitasis.si/"}
                    isMobileOrTablet={isMobileOrTablet}
                    headerText={selectedLanguageValues.supportTranslates.developers}
                    content={<DevelopersContent />}
                    image={developers}
                    clickText={selectedLanguageValues.supportTranslates.developersInteractionText} />
            </div>
            <hr style={{ margin: 0, borderColor: "#c2d5ed" }} />
        </>
    )
}

export default Support;


const ContactContent = () => {
    const { selectedLanguageValues } = useTranslate()
    return (
        <>
        <p style={{color: '#c2d5ed'}}>{selectedLanguageValues.supportTranslates.writeToUsContent}</p>
        <p style={{color: '#c2d5ed'}}>{selectedLanguageValues.common.workingHours}</p>
        <ul style={{listStyle: 'none', padding: 0, marginTop: 0}}>
            <li>{selectedLanguageValues.days.monday}: 08:00 - 18:00</li>
            <li>{selectedLanguageValues.days.tuesday}: 08:00 - 18:00</li>
            <li>{selectedLanguageValues.days.wednesday}: 08:00 - 18:00</li>
            <li>{selectedLanguageValues.days.thursday}: 08:00 - 18:00</li>
            <li>{selectedLanguageValues.days.friday}: 08:00 - 18:00</li>
            <li>{selectedLanguageValues.days.saturday}: {selectedLanguageValues.common.closed}</li>
            <li>{selectedLanguageValues.days.sunday}: {selectedLanguageValues.common.closed}</li>
        </ul>
        <p style={{color: '#c2d5ed'}}>{selectedLanguageValues.common.contact}: support@vitasis.si</p>
        </>
    )
}

const DevelopersContent = () => {
    const { selectedLanguageValues } = useTranslate()
    return (
        <>
        <p style={{color: '#c2d5ed'}}>{selectedLanguageValues.supportTranslates.developersContent}</p>
        </>
    )
}