import React from "react";
import { useState } from "react";
import useTranslate from "../truebar/contexts/Translate/TranslateContext";
import { Languages } from "../truebar/contexts/Translate/types";
import FileViewAndDownloadBar from "../FileViewAndDownloadBar";
import MSWordAddinPrivacyPolicy from "./MSWordAddinPrivacyPolicy";
import MSWordAddinTermsOfService from "./MSWordAddinTermsOfService";

const MSWordAddin = () => {

    const [termsOfUseDisplayed, setTermsOfUseDisplayed] = useState<boolean>(false);
    const toggleTermsOfUse = () => setTermsOfUseDisplayed(!termsOfUseDisplayed)

    const [privacyPolicyDisplayed, setPrivacyPolicyDisplayed] = useState<boolean>(false);
    const togglePrivacyPolicy = () => setPrivacyPolicyDisplayed(!privacyPolicyDisplayed)

    const {
        selectedLanguageValues,
        selectedLanguage,
        changeLanguage
    } = useTranslate()
    
    return (
        <>
            <div style={{ float: 'right', paddingTop: '2vh', paddingRight: '5vw' }}>
                <button style={{ color: 'white' }} onClick={() => changeLanguage()}>
                    {selectedLanguage === Languages.si ? 'EN' : 'SI'}
                </button>
            </div>

            <div style={{ padding: '0 10vw' }}>
                <h1 style={{ textAlign: 'center', margin: '5vh 0' }}>MSWord-addin</h1>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FileViewAndDownloadBar filePath={"./files/msWordAddin/TERMS_OF_USE.txt"} visible={termsOfUseDisplayed} toggle={toggleTermsOfUse} headerText={selectedLanguageValues.legalPages.termsOfUse.toUpperCase()}>
                        <MSWordAddinTermsOfService />
                    </FileViewAndDownloadBar>

                    <FileViewAndDownloadBar filePath={"./files/msWordAddin/PRIVACY_POLICY.txt"} marginTop={termsOfUseDisplayed ? '0' : '2vh'} visible={privacyPolicyDisplayed} toggle={togglePrivacyPolicy} headerText={selectedLanguageValues.legalPages.privacyPolicy.toUpperCase()}>
                        <MSWordAddinPrivacyPolicy />
                    </FileViewAndDownloadBar>
                </div>
            </div>
        </>
    )
}

export default MSWordAddin;