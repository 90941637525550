import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import bannerBgImg from '../../assets/images/banner/Vistasis_naslovna_transp.png';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages } from '../../Contexts/types';
import NewsCard from '../Common/NewsCard';
import { useDimensions } from '../../hooks/useDimensions';
import { introSectionTexts } from '../../data/texts';

interface BannerInfoProps {
    mobile?: boolean;
    currentLanguage?: Languages;
}

enum BannerStateEnum {
    Normal = 'normal',
    Tablet = 'Tablet',
    Mobile = 'Mobile',
}

const isTablet = 985;
const isMobileStart = 550;

function Banner(): JSX.Element {
    const { isMobile, currentLanguage, lastNews } = useAppStateContext();
    const [bannerState, setBannerState] = useState<BannerStateEnum>();

    const { ref, dimensions } = useDimensions();

    useEffect(() => {
        if (dimensions.width <= isMobileStart) {
            setBannerState(BannerStateEnum.Mobile);
        } else if (dimensions.width <= isTablet) {
            setBannerState(BannerStateEnum.Tablet);
        } else {
            setBannerState(BannerStateEnum.Normal);
        }
    }, [dimensions]);

    return (
        <BannerWrapper ref={ref} isMobile={isMobile} id="intro-section">
            <BannerContent>
                <BannerInfo mobile={bannerState === BannerStateEnum.Mobile} currentLanguage={currentLanguage} />
                {lastNews && lastNews.length > 0 && (
                    <NewsCard
                        currentLanguage={currentLanguage}
                        moveRight={bannerState === BannerStateEnum.Tablet}
                        data={lastNews}
                    />
                )}
                <BannerImg variant={bannerState} src={bannerBgImg} />
            </BannerContent>
        </BannerWrapper>
    );
}

function BannerInfo({ mobile, currentLanguage }: BannerInfoProps): JSX.Element {
    return (
        <BannerInfoWrapper mobile={mobile} style={{ maxWidth: '600px' }}>
            {currentLanguage === Languages.SI && (
                <>
                    {' '}
                    <p>
                        {introSectionTexts.mainText.firstLine.si}
                        {'\n'}
                        <span style={{ color: '#c2d5ed' }}>{introSectionTexts.mainText.blueLine.si}</span>
                    </p>
                    <p>{introSectionTexts.subtitle.si}</p>{' '}
                </>
            )}
            {currentLanguage === Languages.EN && (
                <>
                    {' '}
                    <p>
                        {introSectionTexts.mainText.firstLine.en}
                        {'\n'}
                        <span style={{ color: '#c2d5ed' }}>{introSectionTexts.mainText.blueLine.en}</span>
                    </p>
                    <p>{introSectionTexts.subtitle.en}</p>{' '}
                </>
            )}
        </BannerInfoWrapper>
    );
}

export default Banner;

const bannerImgVariantMain = `
  width: 120%;
  position: absolute;
  right: -11.2%;
  top: 0;
  top: -21vw;
  z-index: -1;`;
const bannerImgVariantTablet = `
  width: 190%;
  position: absolute;
  right: -15%;
  top: -23%;
  z-index: -1;`;
const bannerImgVariantMobile = `
  width: 600px;
  position: absolute;
  right: -9.2%;
  /* top: 0; */
  /* top: 5px; */
  z-index: -1;
  bottom: 90%;`;
const BannerWrapper = styled.div<{ isMobile: boolean | undefined }>`
    width: 100%;
    min-height: 820px;
    padding: ${(props): string => (props.isMobile ? '0 20px' : '0px 3vw 0 3vw')};
    padding-top: 250px;
    padding-bottom: 55px;
`;

const BannerContent = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 1200px;

    justify-content: space-between;

    flex-wrap: wrap;
`;

const BannerImg = styled.img<{ variant: BannerStateEnum | undefined }>`
    ${(props) => props.variant === BannerStateEnum.Normal && bannerImgVariantMain}
    ${(props) => props.variant === BannerStateEnum.Tablet && bannerImgVariantTablet}
  ${(props) => props.variant === BannerStateEnum.Mobile && bannerImgVariantMobile}
`;

const BannerInfoWrapper = styled.div<{ mobile: boolean | undefined }>`
    display: flex;
    flex-direction: column;
    min-width: ${(props) => (props.mobile ? '100%' : '580px')};
    margin-right: 10px;
    margin-bottom: 50px;

    font-family: 'Roboto';
    font-size: ${(props) => (props.mobile ? '28px' : '42px')};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: ${(props) => (props.mobile ? '1.29' : '1.14')};
    letter-spacing: normal;
    color: #ffffff;

    & p {
        padding: 0;
        margin: 0;
        white-space: pre-wrap;
    }

    & p:first-child {
        margin-bottom: 35px;
    }

    & p:last-child {
        font-family: 'Roboto';
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        color: #ffffff;
        max-width: 280px;
    }
`;
