import styled from "styled-components";

const AllInOneButton = styled.a<{isMobile: boolean | undefined, backgroundColor: string}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background-color: ${props => props.backgroundColor};
  border-radius: 72px;
  margin-top: ${props => props.isMobile ? "24" : "40"}px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  z-index: 10000;
`

export default AllInOneButton;