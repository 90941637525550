import React, { FC } from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import useTranslate from '../../contexts/Translate/TranslateContext';
import { Category } from '../../contexts/types';

interface Props {
  mainColor: string;
  category: Category;
  active: boolean;
  updateCategory: (category: Category) => void;
}
const FilterButton: FC<Props> = ({mainColor, active, category, updateCategory}) => {

  const { windowSize, isMobile } = useAppStateContext()
  const { translateCategory } = useTranslate();

  /*
    let imageMarginTop = 300;
    if (windowSize.width) {
      if (windowSize.width > 1440) {
        imageMarginTop = 230
      } else if (windowSize.width > 1024 && windowSize.width <= 1440) {
        imageMarginTop = 300 + (windowSize.width - 1024) * ((230-300)/(1440-1024))
      }
    }
  */
  //max31 min18

  let fontSize = 18;
  if (windowSize.width) {
    if (windowSize.width > 1440) {
      fontSize = 31;
    } else if (windowSize.width >= 375 && windowSize.width <= 1440) {
      fontSize = 18 + (windowSize.width - 375) * ((31-18)/(1440-375))
    }
  }

  if (isMobile) {
    fontSize = 24;
  }

  //max46 min28
  let lineHeight = 28
  if (windowSize.width) {
    if (windowSize.width > 1440) {
      lineHeight = 46;
    } else if (windowSize.width >= 375 && windowSize.width <= 1440) {
      lineHeight = 28 + (windowSize.width - 375) * ((46-28)/(1440-375))
    }
  }

  if (isMobile) {
    lineHeight = 36;
  }

  return (
    <ButtonBackground isMobile={isMobile} backgroundColor={active ? mainColor : "#FFFF"} onClick={() => updateCategory(category)}>
      <ButtonText lineHeight={lineHeight} fontSize={fontSize} color={active ? "#F2F2F2" : mainColor}>{translateCategory(category).toUpperCase()}</ButtonText>
    </ButtonBackground>
  )
}

export default FilterButton;

export const ButtonBackground = styled.button<{backgroundColor: string, isMobile: boolean | undefined}>`
  padding: ${props => props.isMobile ? "4px 34px" : "4px 27px"};
  background-color: ${props => props.backgroundColor};
  border-radius: 8px;
`

const ButtonText = styled.p<{color: string, lineHeight: number, fontSize: number}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.lineHeight}px;
  color: ${props => props.color};
  margin: 0;
`