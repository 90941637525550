import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../Contexts/AppStateProvider';
import AllInOneButton from '../components/atoms/AllInOneButton';
import AllInOneDescription from '../components/atoms/AllInOneDescription';
import AllInOneTitle, { AllInOneTitleBlack, AllInOneTitleColored } from '../components/atoms/AllInOneTitle';
import BG_FUNKY from '../assets/images/common/bg_funky.svg';
import BG_MOBILE_FUNKY from '../assets/images/common/bg_mobile_funky.svg';
import BG_BUSSINES from '../assets/images/common/bg_bussines.svg';
import BG_MOBILE_BUSSNIES from '../assets/images/common/bg_mobile_bussines.svg';
import Together from '../assets/images/common/Together.svg';
import Hands from '../assets/images/common/Hands.png';
import Woman from '../assets/images/common/Woman.png';
import Man from '../assets/images/common/Man.png';
import Slider from 'react-slick';
import useTranslate from '../contexts/Translate/TranslateContext';
import useTheme from '../contexts/Theme/ThemeContext';
import { ColorSchemes } from '../contexts/Theme/types';

const AllInOne = () => {
  const { isTablet, isMobile } = useAppStateContext()
  const { selectedLanguageValues } = useTranslate()
  const { colorSchemeValues, colorScheme } = useTheme()

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplay: true,
    speed: 7000,
    autoplaySpeed: 0,
    cssEase: "ease-out",
  };


  //<a href={"mailto:info@vitasis.si"}></a>
  return (
    <AllInOneContainer isMobile={isMobile} isTablet={isTablet}>
      <AllInOneWrapper isMobile={isMobile} isTablet={isTablet}>
        <AllInOneContent>
          <AllInOneTitle isTablet={isTablet}>
            <AllInOneTitleBlack>{selectedLanguageValues.allInOneTranslate.titleOne}</AllInOneTitleBlack>
            <AllInOneTitleColored color={colorSchemeValues.extraordinary}>{selectedLanguageValues.allInOneTranslate.titleTwo}</AllInOneTitleColored>
            <AllInOneTitleBlack>{selectedLanguageValues.allInOneTranslate.titleThree}</AllInOneTitleBlack>
          </AllInOneTitle>
          <AllInOneDescription isTablet={isTablet}>{selectedLanguageValues.allInOneTranslate.description}</AllInOneDescription>
          <AllInOneButton href={"mailto:info@vitasis.si"} backgroundColor={colorSchemeValues.extraordinary} isMobile={isMobile}>{selectedLanguageValues.allInOneTranslate.buttonText}</AllInOneButton>
        </AllInOneContent>
      </AllInOneWrapper>
      <img src={colorScheme === ColorSchemes.Business ? isMobile ? BG_MOBILE_BUSSNIES : BG_BUSSINES : isMobile ? BG_MOBILE_FUNKY : BG_FUNKY} width={'100%'} />
      <IlustrationsWrapper>
      {isMobile && (
        <SliderWrapper>
          <Slider {...settings}>
            <ImageContainer>
              <img style={{maxHeight: '200px'}} src={Woman} />
            </ImageContainer>
            <ImageContainer>
              <img style={{maxHeight: '100px'}} src={Hands} />
            </ImageContainer>
            <ImageContainer>
              <img style={{maxHeight: '190px'}} src={Man} />
            </ImageContainer>
          </Slider>
        </SliderWrapper>
      )
      ||
      (
        <IlustrationContainer isTablet={isTablet}>
          <img src={Together} width={'100%'}/>
        </IlustrationContainer>
      )}
      </IlustrationsWrapper>
    </AllInOneContainer>
  )
}

export default AllInOne;

const ImageContainer = styled.div`
  display: flex !important;
  align-items: flex-end;
  justify-content: center;
`

const AllInOneContainer = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  position: relative;
`

const AllInOneWrapper = styled.div<{ isMobile: boolean | undefined, isTablet: boolean | undefined }>`
  display: flex;
  padding: ${props => props.isMobile ? "97px 40px 100px 40px" : props.isTablet ? "80px 124px 100px 124px" : "131px 0 100px 0"};
  width: 100%;
`

const AllInOneContent = styled.div`
  margin: 0 auto;
  max-width: 587px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IlustrationsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const IlustrationContainer = styled.div<{ isTablet: boolean | undefined }>`
  margin: 0 auto;
  max-width: 1440px;
  padding-left: ${props => props.isTablet ? "65" : "122"}px;
  padding-right: ${props => props.isTablet ? "65" : "122"}px;
`

const SliderWrapper = styled.div`
  & .slick-slider .slick-list .slick-track .slick-slide div {
    height: 200px !important;
  }
`