import styled from 'styled-components';

const CardDescription = styled.p<{isTablet: boolean | undefined, isMobile: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: ${props => props.isTablet ? "normal" : "500"};
  font-size: ${props => (props.isTablet || props.isMobile) ? "17" : "20"}px;
  line-height: ${props => (props.isTablet || props.isMobile) ? "25" : "30"}px;
  color: #2C2C2C;
  margin: 0;
  max-width: 400px;
  margin-top: 16px;
`

export default CardDescription;