import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { DialogVariant, Languages } from '../../Contexts/types';
import { useDimensions } from '../../hooks/useDimensions';
import { cookiesFooter } from '../../data/texts';

const CookiesVariant = {
    hidden: {
        y: '100%',
        transition: { ease: 'easeOut', duration: 0.3 },
    },
    visible: {
        y: 0,
        transition: { ease: 'easeOut', duration: 0.3, delay: 0.8 },
    },
};

function CookiesFooter(): JSX.Element {
    const { acceptCookies, toggleDialogState, currentLanguage, acceptedCookies } = useAppStateContext();
    const { ref, dimensions } = useDimensions();

    return (
    <AnimatePresence>
        {!acceptedCookies && (
                <CookiesWrapper ref={ref} variants={CookiesVariant} initial="hidden" animate="visible" exit="hidden">
                    <ContentWrapper changeLayout={dimensions.width <= 1066}>
                        {currentLanguage === Languages.SI && <p>{cookiesFooter.text.si}</p>}
                        {currentLanguage === Languages.EN && <p>{cookiesFooter.text.en}</p>}
                        <div>
                            <button
                                onClick={() => {
                                    toggleDialogState(DialogVariant.ReadMore, false);
                                }}
                            >
                                {currentLanguage === Languages.SI && <span>{cookiesFooter.buttonReadMore.si}</span>}
                                {currentLanguage === Languages.EN && <span>{cookiesFooter.buttonReadMore.en}</span>}
                            </button>
                            <button onClick={acceptCookies}>
                                {currentLanguage === Languages.SI && <span>{cookiesFooter.buttonAccept.si}</span>}
                                {currentLanguage === Languages.EN && <span>{cookiesFooter.buttonAccept.en}</span>}
                            </button>
                        </div>
                    </ContentWrapper>
                </CookiesWrapper>
        )}    
        </AnimatePresence>
    );
}

export default CookiesFooter;

const CookiesWrapper = styled(motion.div)`
    position: fixed;
    left: 0;
    bottom: 0;
    rigth: 0;
    z-index: 999;

    padding: 24px 20px;

    min-height: 10%;
    background-color: #fff;
    width: 100%;

    font-size: 16px;
`;

const ContentWrapper = styled.div<{ changeLayout: boolean | undefined }>`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;

    display: flex;
    ${(props) => props.changeLayout && 'flex-direction: column'};
    justify-content: space-between;
    align-items: flex-start;

    & p,
    button {
        font-family: Roboto;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
    }

    & button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        flex-shrink: 0;
        text-decoration: underline;
        cursor: pointer;

        & span {
            transition: transform 200ms ease-in-out;
        }

        &:active span {
            transform: scale(0.98);
            transition: transform 200ms ease-in-out;
        }
    }

    & p {
        width: 100%;
        max-width: 780px;
        margin-right: ${(props) => (props.changeLayout ? '0' : '45px')};
        margin-bottom: ${(props) => (props.changeLayout ? '25px' : '0')};
    }

    & div {
        width: ${(props) => (props.changeLayout ? '100%' : 'auto')};
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;
