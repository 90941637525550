import styled from "styled-components";

const SubHeaderTitle = styled.h1<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: ${props => props.isMobile || props.isTablet ? "24" : "31"}px;
  line-height: ${props => props.isMobile ? "36" : props.isTablet ? "34" : "46"}px;
  color: #2C2C2C;
  margin-bottom: 24px;
`
export default SubHeaderTitle;