import React from 'react';

import styled from 'styled-components';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages, DialogVariant } from '../../Contexts/types';

import { Transition } from 'react-transition-group';

import { links, LinkDivider } from '../Common/HeaderLinks';
import { headerFooterLinks } from '../../data/texts';

const transitionStyles: any = {
    entering: { transform: 'translateX(-100%)' },
    entered: { transform: 'translateX(0)' },
    exiting: { transform: 'translateX(0)' },
    exited: { transform: 'translateX(-100%)' },
};

export default function MobileMenu(): JSX.Element {
    const { mobileMenueOpen } = useAppStateContext();

    return (
        <Transition in={mobileMenueOpen} timeout={100}>
            {(state) => (
                <MobileMenuOverlay style={{ ...transitionStyles[state] }}>
                    <MobileMenuContent>
                        <MobileMenuLinks />
                    </MobileMenuContent>
                </MobileMenuOverlay>
            )}
        </Transition>
    );
}

function MobileMenuLinks() {
    const {
        currentLanguage,
        toggleDialogState,
        projectLinks,
        toggleMobileMenuOpen,
        toggleLanugage,
    } = useAppStateContext();

    function handleLinkClick(id: number, sectionId: string) {
        if (id === 4) {
            toggleDialogState(DialogVariant.Careers, false);
            return;
        }

        toggleMobileMenuOpen();
        setTimeout(() => {
            const anchorTarget = document.getElementById(sectionId);
            if (!anchorTarget) return;
            anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 800);
    }

    return (
        <>
            {links &&
                links.map((link, i) => (
                    <ButtonHolder
                        key={link.id}
                        style={
                            i === 0
                                ? { flexDirection: 'row', justifyContent: 'space-between' }
                                : { justifyContent: 'flex-start' }
                        }
                    >
                        <MainButton
                            style={{
                                marginBottom:
                                    link.linkLabelEn === headerFooterLinks[headerFooterLinks.length - 1].en
                                        ? '0'
                                        : '6px',
                            }}
                            onClick={() => handleLinkClick(link.id, link.sectionId)}
                        >
                            {currentLanguage === Languages.EN ? link.linkLabelEn : link.linkLabelSi}
                        </MainButton>
                        {i === 0 ? (
                            <LanguagesWrapper>
                                {/* <button onClick={() => setCurrentLanguage(Languages.EN)}>EN</button>
              <LinkDivider />
              <button onClick={() => setCurrentLanguage(Languages.SI)}>SI</button> */}
                                <button onClick={() => toggleLanugage()}>
                                    {currentLanguage === Languages.SI ? 'EN' : 'SI'}
                                </button>
                            </LanguagesWrapper>
                        ) : null}
                        {link.linkLabelEn === headerFooterLinks[headerFooterLinks.length - 1].en && (
                            <ChildrenLinks>
                                {projectLinks &&
                                    projectLinks.map((project) => (
                                        <button
                                            key={project.projectID}
                                            onClick={() => {
                                                toggleDialogState(project.projectID, false);
                                            }}
                                        >
                                            {project.projectShort}
                                            {/* {currentLanguage === Languages.EN
                        ? project.projectNameEn
                        : project.projectNameSi} */}
                                        </button>
                                    ))}
                            </ChildrenLinks>
                        )}
                    </ButtonHolder>
                ))}
        </>
    );
}

const MobileMenuOverlay = styled.div`
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    top: 0;
    z-index: 10;
    padding-top: 145px;
    background-color: #141414;
    will-change: transform, opacity;
    transition: all 0.1s ease-in-out;
`;

const MobileMenuContent = styled.div`
    width: 100%;
    padding: 20px;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    font-size: 18px;
    font-family: 'Roboto';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #141414;
`;

const ButtonHolder = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    flex-direction: column;
    align-items: baseline;
`;

const MainButton = styled.button`
    padding: 0;
    margin: 6px 0;
    display: inline-flex;
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;

    line-height: 2.33;
`;
const ChildrenLinks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & button {
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: normal;
        color: rgb(194, 213, 237);
        padding: 0;
        margin: 4px 0;
    }
`;

const LanguagesWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        font-family: 'Roboto';
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;

        margin: 0;
        padding: 0;
    }

    & button:first-child {
        padding-right: 15px;
    }

    & button:last-child {
        padding-left: 15px;
    }
`;
