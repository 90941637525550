import React, { createContext, FC, useContext, useState } from 'react';
import { businessTheme, funkyTheme } from './constants';
import { ColorSchemes, ColorSchemeValues, ContextType } from './types';
import { Category } from '../types'

const ThemeContext = createContext<ContextType>({
  colorScheme: ColorSchemes.Funky,
  colorSchemeValues: funkyTheme,
  changeToColorScheme: () => {},
  getCategoryColor: () => '',
  getCardBackgroundColor: () => '',
})

export const ThemeProvider: FC = ({children}) => {
  const [colorSchemeValues, setColorSchemeValues] = useState<ColorSchemeValues>(businessTheme)
  const [colorScheme, setColorScheme] = useState<ColorSchemes>(ColorSchemes.Business)

  const changeToColorScheme = (selectedColorScheme: ColorSchemes): void => {
    switch (selectedColorScheme) {
      case ColorSchemes.Funky:
        setColorSchemeValues(funkyTheme)
        break;
      case ColorSchemes.Business:
        setColorSchemeValues(businessTheme)
        break;
      default: setColorSchemeValues(funkyTheme)
    }
    setColorScheme(selectedColorScheme)
  }

  const getCategoryColor = (category: Category) => {
    switch (category) {
      case Category.Recognition: return colorSchemeValues.recognitionProducts
      case Category.Synthesis: return colorSchemeValues.synthesisProducts
      case Category.Translate: return colorSchemeValues.translateProducts
      case Category.All: return colorSchemeValues.allProducts
      default: return colorSchemeValues.extraordinary
    }
  }

  const getCardBackgroundColor = (category: Category) => {
    if (colorScheme === ColorSchemes.Funky) {
      switch (category) {
        case Category.Recognition: return colorSchemeValues.recognitionProducts + "33"
        case Category.Synthesis: return colorSchemeValues.synthesisProducts + "33"
        case Category.Translate: return colorSchemeValues.translateProducts + "4D"
        default: return colorSchemeValues.extraordinary
      }
    } else {
      return '#F2F2F2'
    }

  }

  return (
    <ThemeContext.Provider value={{colorScheme, colorSchemeValues, changeToColorScheme, getCategoryColor, getCardBackgroundColor}}>
      {children}
    </ThemeContext.Provider>
  )
}

const useTheme = () => {
  const context = useContext(ThemeContext)

  return context
}

export default useTheme;