import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../Contexts/AppStateProvider';
import { Languages } from '../../../Contexts/types';

//@ts-ignore
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../../prismic-html-serializer';

function CareersDialog(): JSX.Element {
    const { currentLanguage, careers } = useAppStateContext();

    const careersToRender = currentLanguage === Languages.EN ? careers?.careers_info_en : careers?.careers_info_si;

    return (
        <CareersDialogWrapper>
            <MainTitle>{currentLanguage === Languages.EN ? 'CAREERS' : 'DELO'}</MainTitle>

            {careersToRender && <RichText render={careersToRender} htmlSerializer={htmlSerializer} />}
        </CareersDialogWrapper>
    );
}

export default CareersDialog;

const MainTitle = styled.h1`
    color: #000000;
    min-width: 100%;
    margin: 0;
    margin-bottom: 25px;
    padding: 0;
    font-weight: normal;

    font-size: 36px;
`;

const CareersDialogWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
