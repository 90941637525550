import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

//@ts-ignore
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../../prismic-html-serializer';

import { projectDialogFacts } from '../../../data/texts';

import { useAppStateContext } from '../../../Contexts/AppStateProvider';
import { Languages } from '../../../Contexts/types';
import Prismic from 'prismic-javascript';
import { client } from '../../../prismic-config';
import { Project } from '../../../Contexts/types';

import MainLoader from '../MainLoader';

function ProjectDialog(): JSX.Element {
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [loadingProject, setLoadingProject] = useState<boolean>(false);

    const { currentLanguage, currentDialogState, isMobile } = useAppStateContext();

    useEffect(() => {
        const fetchedProject = async () => {
            setLoadingProject(true);
            try {
                const response = await client.query(
                    Prismic.Predicates.at('document.id', currentDialogState.projectUID!)
                );
                if (response) {
                    setLoadingProject(false);
                    const fetched = response.results[0].data;
                    setSelectedProject({
                        ...selectedProject,
                        project_name_si: fetched.project_name_si,
                        project_name_en: fetched.project_name_en,
                        project_duration_en: fetched.project_duration_en,
                        project_duration_si: fetched.project_duration_si,
                        projectDescSI: fetched.project_description_si,
                        projectDescEN: fetched.project_description_en,
                        partners: fetched.partners,
                        logosEn: fetched.logos_en.map((logo: any) => {
                            return logo.logo.url;
                        }),
                        logosSi: fetched.logotipi_si.map((logo: any) => {
                            return logo.logo.url;
                        }),
                    });
                }
            } catch (err) {
                console.log(err);
                setLoadingProject(false);
            }
        };
        fetchedProject();
    }, []);

    if (loadingProject)
        return (
            <LoaderCenterer>
                <MainLoader loaderOnly color={'#000'} />
            </LoaderCenterer>
        );

    if (!selectedProject) return <></>;

    const projectRichText =
        currentLanguage === Languages.EN ? selectedProject.projectDescEN : selectedProject.projectDescSI;

    const projectName =
        currentLanguage === Languages.EN ? selectedProject.project_name_en : selectedProject.project_name_si;

    return (
        <ProjectDialogWrapper>
            <ProjectTitleWrapper>
                <ProjectName>{projectName}</ProjectName>
                <ProjectYear>
                    {currentLanguage === Languages.EN
                        ? selectedProject.project_duration_en
                        : selectedProject.project_duration_si}
                </ProjectYear>
            </ProjectTitleWrapper>
            <ProjectDescriptionWrapper>
                <RichText render={projectRichText} htmlSerializer={htmlSerializer} />
            </ProjectDescriptionWrapper>

            {currentLanguage === Languages.EN && selectedProject.logosEn ? (
                <LogosWrapper>
                    {selectedProject.logosEn.map((logoUrl, i) => {
                        return (
                            <LogoWrapper isMobile={isMobile} key={i.toString()}>
                                <img src={logoUrl} alt={`partner_logo${i}`} />
                            </LogoWrapper>
                        );
                    })}
                </LogosWrapper>
            ) : (
                selectedProject.logosSi && (
                    <LogosWrapper>
                        {selectedProject.logosSi.map((logoUrl, i) => {
                            return (
                                <LogoWrapper isMobile={isMobile} key={i.toString()}>
                                    <img src={logoUrl} alt={`partner_logo${i}`} />
                                </LogoWrapper>
                            );
                        })}
                    </LogosWrapper>
                )
            )}
            
            {(selectedProject.project_name_si === "Tourism 4.0 Demo Pilot" || selectedProject.project_name_en === "Tourism 4.0 Demo Pilot") && (
                <>
                    <h2 style={{textAlign: 'center'}}>Zaslonske maske</h2>
                    <ApplicationImage first={true} title="Prijava" imageSrc='./images/vitabits-login.png' />
                    <ApplicationImage title="Pregled zadnjih meritev" imageSrc='./images/vitabits-dashboard.png' />
                    <ApplicationImage title="Pregled naprav" imageSrc='./images/vitabits-devices-extended.png' />
                    <ApplicationImage title="Filtriranje zadnje opravljenih meritev" imageSrc='./images/vitabits-filters.png' />
                    <ApplicationImage title="Graf #1" imageSrc='./images/vitabits-graph1.png' />
                    <ApplicationImage title="Graf #2" imageSrc='./images/vitabits-graph2.png' />
                    <ApplicationImage title="Graf #3" imageSrc='./images/vitabits-graph3.png' />
                    <ApplicationImage title="Aktivnosti" imageSrc='./images/vitabits-activities.png' />
                    <ApplicationImage title="Rezultati meritve" imageSrc='./images/vitabits-measurement-result.png' />
                    <ApplicationImage title="Deljenje aktivnosti" imageSrc='./images/vitabits-share.png' />
                    <ApplicationImage title="Pametni asistent" imageSrc='./images/vitabits-smart-asistant.png' />
                    <ApplicationImage title="Naloge" imageSrc='./images/vitabits-tasks.png' />                
                </>
            )}

        </ProjectDialogWrapper>
    );
}

interface Props {
    first?: boolean
    imageSrc: string;
    title: string,
}
const ApplicationImage = (props: Props) => {
    const {
        first,
        imageSrc,
        title
    } = {...props}
    return (
        <div style={{ paddingTop: 10, paddingBottom: 10, borderTop: `${!first ? "1px solid #BBBBBB" : "2px solid #676767"}`, maxHeight: 414, width: '100%', display: "flex", flexDirection: 'row'}}>
            <div style={{width: '70%'}}>
                <img width="100%" height="100%" src={imageSrc} style={{objectFit: 'contain'}} />
            </div>
            <div style={{width: '50%', alignItems: 'center', height: '100%'}}>
                <p style={{fontSize: 16, fontWeight: 'bold'}}>{title}</p>
            </div>
        </div>
    )
}

export default ProjectDialog;

//Project title styles
const ProjectDialogWrapper = styled.div``;
const ProjectTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    font-size: 36px;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
`;
const ProjectName = styled.h4`
    color: #000000;
    min-width: 100%;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    font-weight: normal;

    font-size: 36px;
`;
const ProjectYear = styled.h4`
    color: #c3d5ed;
    margin: 0;
    padding: 0;
    min-width: 100%;
    font-weight: normal;

    font-size: 22px;
`;

//Project description styles
const ProjectDescriptionWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
`;

/* //Project fact styles
const ProjectFactWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  margin-bottom: 95px;
`;
const ProjectFactRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & p {
    color: #000;
  }
  & p:first-child {
    font-weight: bold;
    width: 60%;
  }
  & p:last-child {
    width: 40%;
  }
`; */

const LogosWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 35px;

    max-width: 100%;
`;

const LogoWrapper = styled.div<{ isMobile: boolean | undefined }>`
    margin-bottom: 25px;
    max-width: 300px;

    & img {
        width: 100%;
    }
`;

const LoaderCenterer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
`;



/**
 * <div class="row">
  <div class="column">
    <img src="wedding.jpg">
    <img src="rocks.jpg">
    <img src="falls2.jpg">
    <img src="paris.jpg">
    <img src="nature.jpg">
    <img src="mist.jpg">
    <img src="paris.jpg">
  </div>
  <div class="column">
    <img src="underwater.jpg">
    <img src="ocean.jpg">
    <img src="wedding.jpg">
    <img src="mountainskies.jpg">
    <img src="rocks.jpg">
    <img src="underwater.jpg">
  </div>
  <div class="column">
    <img src="wedding.jpg">
    <img src="rocks.jpg">
    <img src="falls2.jpg">
    <img src="paris.jpg">
    <img src="nature.jpg">
    <img src="mist.jpg">
    <img src="paris.jpg">
  </div>
  <div class="column">
    <img src="underwater.jpg">
    <img src="ocean.jpg">
    <img src="wedding.jpg">
    <img src="mountainskies.jpg">
    <img src="rocks.jpg">
    <img src="underwater.jpg">
  </div>
</div>
 */

const ImageRow = styled.div`
display: flex;
flex-wrap: wrap;
padding: 0 4px;
`
const ImageColumn = styled.div`
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;

    &img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
    }

    @media (max-width: 800px) {
        flex: 50%;
        max-width: 50%;
    }

    @media (max-width: 600) {
        flex: 100%;
        max-width: 100%;
    }
`