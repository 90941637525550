import { Category } from "../types"

export type ColorSchemeValues = {
  headerBackground: string;
  subHeaderBackground: string;
  extraordinary: string;
  recognitionProducts: string;
  synthesisProducts: string;
  translateProducts: string;
  allProducts: string;
  invalidButtonColor: string;
}

export enum ColorSchemes {
  Funky = 'Funky',
  Business = 'Business',
}

export type ContextType = {
  colorScheme: ColorSchemes;
  colorSchemeValues: ColorSchemeValues;
  changeToColorScheme: (selectedColorScheme: ColorSchemes) => void;
  getCategoryColor: (category: Category) => string;
  getCardBackgroundColor: (category: Category) => string;
}