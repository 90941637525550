import React, { FC } from "react"
import styled from "styled-components"
import { useAppStateContext } from "../../../../../Contexts/AppStateProvider"
import CategoryDescription from "../atoms/CategoryDescription"
import CategoryProductItem from "../atoms/CategoryProductItem"
import CategoryProductsList from "../atoms/CategoryProductsList"
import CategoryProductsTitle from "../atoms/CategoryProductsTitle"
import CategoryTitle from "../atoms/CategoryTitle"
import { CategoryKind } from "../organisms/CategoryContainer"

interface Props {
  categoryKind: CategoryKind;
  categoryTitle: string;
  categoryDescription: string;
  categoryProductsTitle: string;
  categoryProductsList: string[];
}
const CategoryText: FC<Props> = ({categoryTitle, categoryDescription, categoryProductsTitle, categoryProductsList, categoryKind}) => {
  
  const { isMobile, isTablet } = useAppStateContext()
  
  return (
    <CategoryTextWrapper categoryKind={categoryKind} isMobile={isMobile}>
      <CategoryTitle isSmaller={isMobile || isTablet}>{categoryTitle}</CategoryTitle>
      <CategoryDescription isMobile={isMobile} isTablet={isTablet}>{categoryDescription}</CategoryDescription>
      <CategoryProductsTitle isSmaller={isMobile || isTablet}>{categoryProductsTitle}</CategoryProductsTitle>
      <CategoryProductsList isMobile={isMobile}>
        {categoryProductsList.map((product, index) => <CategoryProductItem key={index} isSmaller={isMobile || isTablet}>{product}</CategoryProductItem>)}
      </CategoryProductsList>
    </CategoryTextWrapper>
  )
}

export default CategoryText

const CategoryTextWrapper = styled.div<{categoryKind: CategoryKind, isMobile: boolean | undefined}>`
  max-width: 465px;
  display: flex;
  flex-direction: column;
  ${props => props.isMobile ? "margin-bottom: 24px" : ""};
`