import React, { createContext, FC, useContext, useState } from 'react';
import { Category } from '../types';
import { englishTranslates, slovenianTranslates, Translates } from './texts';
import { ContextType, Languages } from './types';

const TranslateContext = createContext<ContextType>({
  selectedLanguage: Languages.si,
  selectedLanguageValues: slovenianTranslates,
  changeLanguage: () => {},
  translateCategory: () => ""
})

export const TranslateProvider: FC = ({children}) => {
  const [selectedLanguageValues, setSelectedLanguageValues] = useState<Translates>(slovenianTranslates)
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>(Languages.si)

  const changeLanguage = () => {
    switch (selectedLanguage) {
      case Languages.si: 
        setSelectedLanguageValues(englishTranslates)
        break;
      case Languages.en: 
        setSelectedLanguageValues(slovenianTranslates)
        break;
      default: setSelectedLanguageValues(slovenianTranslates)
    }
    setSelectedLanguage(selectedLanguage === Languages.en ? Languages.si : Languages.en);
  }

  const translateCategory = (category: Category) => {
    switch (category) {
      case Category.Recognition: return selectedLanguageValues.categoryTranslate.Recognition
      case Category.Synthesis: return selectedLanguageValues.categoryTranslate.Synthesis
      case Category.Translate: return selectedLanguageValues.categoryTranslate.Translate
      default: return selectedLanguageValues.categoryTranslate.All
    }
  }

  return (
    <TranslateContext.Provider value={{translateCategory, changeLanguage, selectedLanguageValues, selectedLanguage}}>
      {children}
    </TranslateContext.Provider>
  )
}

const useTranslate = () => {
  const context = useContext(TranslateContext)

  return context
}

export default useTranslate;