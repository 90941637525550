import useWindowSize from './useWindowSize';
const useScalable = () => {
    const { windowSize } = useWindowSize();
    const BASELINE_WIDTH = 1440;

    const scale = (size: number) => {
        if (!windowSize.width) return 0;
        return (windowSize.width / BASELINE_WIDTH) * size;
    };
    return {
        scale,
    };
};

export default useScalable;
