/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from './components/Common/Layout';
import Home from './pages/landing/Home';
import MSWordAddin from './pages/products/msword_addin/MSWordAddin';
import { TranslateProvider } from './pages/products/truebar/contexts/Translate/TranslateContext';
import Truebar from './pages/products/truebar/Truebar';
import Support from './pages/support/Support';
import TruebarApps from './pages/products/truebar_apps/TruebarApps';
import PrivacyPolicy from './pages/products/privacy_policy/PrivacyPolicy';
import ClientsTermsOfUse from './pages/products/clients_terms_of_use/ClientsTermsOfUse';
import EndUsersTermsOfUse from './pages/products/end_users_terms_of_use/EndUsersTermsOfUse';

interface SingleRoute {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Route;
    provider?: any;
}

interface RoutesProps {
    routes: SingleRoute[];
}

const Routes: FC<RoutesProps> = ({ routes }): JSX.Element => {
    return (
        <TranslateProvider>
            <Switch>
                {routes.map((route, i) => {
                    const Layout = route.layout || Fragment;
                    const Component = route.component;
                    const Provider = route.provider || Fragment;

                    return (
                        <Route
                            key={i.toString()}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Provider>
                                    <Layout>
                                        <Component {...props} />
                                    </Layout>
                                </Provider>
                            )}
                        />
                    );
                })}
            </Switch>
        </TranslateProvider>
    );
};

export default Routes;

export const mainRoutes: SingleRoute[] = [
    {
        exact: true,
        path: '/home',
        component: Home,
        layout: Layout,
    },
    {
        exact: true,
        path: '/products/truebar',
        component: Truebar,
        layout: Layout,
    },
    {
        exact: true,
        path: '/support',
        component: Support,
        layout: Layout,
    },
    {
        exact: true,
        path: '/msword-addin',
        component: MSWordAddin,
        layout: Layout,
    },
    {
        exact: true,
        path: '/diafon',
        component: () => <Redirect to="/truebar_apps" />,
    },
    {
        exact: true,
        path: '/truebar_apps',
        component: TruebarApps,
        layout: Layout,
    },
    {
        exact: true,
        path: '/privacy_policy',
        component: PrivacyPolicy,
        layout: Layout,
    },
    {
        exact: true,
        path: '/client_terms_of_use',
        component: ClientsTermsOfUse,
        layout: Layout,
    },
    {
        exact: true,
        path: '/end_users_terms_of_use',
        component: EndUsersTermsOfUse,
        layout: Layout,
    },
    {
        component: () => <Redirect to="/home" />,
    },
];