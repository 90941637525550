import React from 'react';

import Banner from '../../components/Home/Banner';
import ProductsSection from '../../components/Home/ProductsSection';
import PartnersSection from '../../components/Home/PartnersSection';
import ContactUsSection from '../../components/Home/ContastUsSection';
import AboutUsSection from '../../components/Home/AboutUsSection';
import OthersAboutUs from '../../components/Home/OthersAboutUsSection';

import FadeInSection from '../../components/Common/FadeInSection';

export default function Home(): JSX.Element {
    return (
        <>
            <FadeInSection>
                <Banner />
            </FadeInSection>
            <FadeInSection>
                <ProductsSection />
            </FadeInSection>
            <FadeInSection>
                <AboutUsSection />
            </FadeInSection>
            <FadeInSection>
                <ContactUsSection />
            </FadeInSection>
            <FadeInSection>
                <PartnersSection />
            </FadeInSection>
            <FadeInSection>
                <OthersAboutUs />
            </FadeInSection>
        </>
    );
}
