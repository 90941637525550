import React, { useRef } from 'react';
import styled, { keyframes } from "styled-components";
import { useAppStateContext } from "../../Contexts/AppStateProvider";
import useOnClickOutside from '../../hooks/useOnClickOutside';
import usePath from '../../hooks/usePath';
import Player from './Player';

const VideoModal = () => {
  const { videoModalState, closeVideoModal } = useAppStateContext();

  const ModalContainerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ModalContainerRef, closeVideoModal)
  
  return (
    <VideoModalWrapper visible={videoModalState}>
      <VideoModalContainer ref={ModalContainerRef} visible={videoModalState}>
        {videoModalState && (<Player />)}
      </VideoModalContainer>
    </VideoModalWrapper>
  )
}

export default VideoModal;

const fadeInModal = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOutModal = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const VideoModalWrapper = styled.div<{visible: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${props => props.visible ? "visible" : "hidden"};
  animation: ${props => props.visible ? fadeInModal : fadeOutModal} ${props => props.visible ? '0.8' : '0.5'}s linear;
  transition: visibility ${props => props.visible ? '0.8' : '0.5'}s linear;
  z-index: 10;
`

const scaleInModal = keyframes`
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
`;

const scaleOutModal = keyframes`
  from {
    transform: scale(1)
  }

  to {
    transform: scale(0)
  }
`;

const VideoModalContainer = styled.div<{visible: boolean}>`
  background-color: #FFF;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 75%;
  animation: ${props => props.visible ? scaleInModal : scaleOutModal} ${props => props.visible ? "0.5s" : "0.8s"} linear;
  z-index: 20;
  padding: 16px;
`