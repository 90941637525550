import React, { FC } from 'react';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import CategoryText from '../molecules/CategoryText';

export enum CategoryKind {
  TextLeftPlaced = "TextLeftPlaced",
  TextRightPlaced = "TextRightPlaced",
}

interface Props {
  categoryKind: CategoryKind,
  image: string;
  categoryTitle: string;
  categoryDescription: string;
  categoryProductsTitle: string;
  categoryProductsList: string[];
}
const CategoryContainer: FC<Props> = ({categoryKind, categoryTitle, categoryDescription, categoryProductsTitle, categoryProductsList, image}) => {

  const { isMobile } = useAppStateContext();
  return (
    <>

      {categoryKind === CategoryKind.TextRightPlaced && !isMobile && (<img alt={categoryTitle} width={isMobile ? '90%' : '50%'} src={image}/>)}
      
      <CategoryText
          categoryKind={categoryKind}
          categoryTitle={categoryTitle}
          categoryDescription={categoryDescription}
          categoryProductsTitle={categoryProductsTitle}
          categoryProductsList={categoryProductsList}/>

      {(categoryKind === CategoryKind.TextLeftPlaced || isMobile) && (<img alt={categoryTitle} width={isMobile ? '90%' : '50%'} src={image}/>)}
    </>
  )
}

export default CategoryContainer;