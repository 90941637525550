import React, { useState, useEffect, FC, Children } from 'react';

import styled from 'styled-components';
//import { useDimensions } from "../../hooks/useDimensions";
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages, DialogVariant } from '../../Contexts/types';

import logo from '../../assets/images/vitasis-logo-white.png';

import fbIcon from '../../assets/images/social/fb.svg';
import igIcon from '../../assets/images/social/ig.svg';
import twIcon from '../../assets/images/social/tw.svg';
import { links, DropdownVariants } from './HeaderLinks';
import { AnimatePresence, motion } from 'framer-motion';
import { headerFooterLinks } from '../../data/texts';
import usePath from '../../hooks/usePath';
import { Link } from 'react-router-dom';

interface PopupProps {
    show: boolean;
}

export default function Footer(): JSX.Element {
    //const { ref, dimensions } = useDimensions();
    const { isMobile, currentLanguage, toggleDialogState } = useAppStateContext();
    const [showProjects, setShowProjects] = useState<boolean>(false);

    const path = usePath();

    const NavigateToHome: FC = ({ children }) => <Link to={'/home'}>{children}</Link>;

    const handleLinkClick = (id: number, sectionId: string) => {
        if (id === 4) {
            toggleDialogState(DialogVariant.Careers, false);
            return;
        }
        const anchorTarget = document.getElementById(sectionId);
        if (!anchorTarget) return;
        anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <FooterWrapper isMobile={isMobile}>
            <FooterContent>
                <LeftBlock>
                    <LogoWrapper>
                        {/*       <VitasisLogo pathStyle={{fill: 'red'}}  /> */}

                        <NavigateToHome>
                            <img src={logo} alt="logo" style={{ width: '160px', height: '20px' }} />
                        </NavigateToHome>
                    </LogoWrapper>
                    
                    {isMobile && (
                        <>
                        <img src="nvidia.png" width={200} style={{objectFit: 'contain', marginTop: 32}}/>
                        
                        <RightBlock isMobile={isMobile}>
                            {path.isPathHome() && (
                                <LinksColumn>
                                    {currentLanguage === Languages.SI && (
                                        <>
                                            {links &&
                                                links.slice(0, 3).map((link, i) => (
                                                    <div style={{ position: 'relative' }} key={i.toString()}>
                                                        <button
                                                            onClick={() => handleLinkClick(link.id, link.sectionId)}
                                                        >
                                                            <span>{link.linkLabelSi}</span>
                                                        </button>
                                                    </div>
                                                ))}
                                        </>
                                    )}
                                    {currentLanguage === Languages.EN && (
                                        <>
                                            {links &&
                                                links.slice(0, 3).map((link, i) => (
                                                    <div key={i.toString()}>
                                                        <button
                                                            onClick={() => handleLinkClick(link.id, link.sectionId)}
                                                        >
                                                            <span>{link.linkLabelEn}</span>
                                                        </button>
                                                    </div>
                                                ))}
                                        </>
                                    )}
                                </LinksColumn>
                            )}

                            <LinksColumn>
                                {currentLanguage === Languages.SI && path.isPathHome() && (
                                    <>
                                        {links &&
                                            links.slice(3, 6).map((link, i) => (
                                                <div
                                                    style={{ position: 'relative' }}
                                                    key={i.toString()}
                                                    onMouseEnter={() =>
                                                        link.linkLabelEn ===
                                                            headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                        setShowProjects(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        link.linkLabelEn ===
                                                            headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                        setShowProjects(false)
                                                    }
                                                >
                                                    <button onClick={() => handleLinkClick(link.id, link.sectionId)}>
                                                        <span>{link.linkLabelSi}</span>
                                                    </button>
                                                    <ProjectsPopup
                                                        show={
                                                            link.linkLabelEn ===
                                                                headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                            showProjects
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    </>
                                )}
                                {currentLanguage === Languages.EN && path.isPathHome() && (
                                    <>
                                        {links &&
                                            links.slice(3, 6).map((link, i) => (
                                                <div
                                                    style={{ position: 'relative' }}
                                                    key={i.toString()}
                                                    onMouseEnter={() =>
                                                        link.linkLabelEn ===
                                                            headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                        setShowProjects(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        link.linkLabelEn ===
                                                            headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                        setShowProjects(false)
                                                    }
                                                >
                                                    <button onClick={() => handleLinkClick(link.id, link.sectionId)}>
                                                        <span>{link.linkLabelEn}</span>
                                                    </button>
                                                    <ProjectsPopup
                                                        show={
                                                            link.linkLabelEn ===
                                                                headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                            showProjects
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    </>
                                )}
                                {/*<Socials>
                                    {/* To-do: Add links to socials }
                                    <a>
                                        {' '}
                                        <img src={twIcon} alt="tw-icon" />{' '}
                                    </a>
                                    <a>
                                        {' '}
                                        <img src={fbIcon} alt="fb-icon" />
                                    </a>
                                    <a>
                                        <img src={igIcon} alt="ig-icon" />
                                    </a>
                                </Socials>*/}
                            </LinksColumn>
                        </RightBlock>
                        </>
                    )}
                    {!path.isPathHome() && !isMobile && <div style={{ marginTop: '77px' }} />}
                    <span>
                        {currentLanguage === Languages.EN
                            ? 'Copyright 2020 Vitasis. All rights reserved.'
                            : 'Copyright 2020 Vitasis. Vse pravice pridržane.'}
                    </span>
                </LeftBlock>
                {!isMobile && (
                    <>
                    <img src="nvidia.png" width={200} style={{objectFit: 'contain'}}/>
                    <RightBlock isMobile={isMobile}>
                        {path.isPathHome() && (
                            <LinksColumn>
                                {currentLanguage === Languages.SI && (
                                    <>
                                        {links &&
                                            links.slice(0, 3).map((link, i) => (
                                                <div key={i.toString()}>
                                                    <button onClick={() => handleLinkClick(link.id, link.sectionId)}>
                                                        <span>{link.linkLabelSi}</span>
                                                    </button>
                                                </div>
                                            ))}
                                    </>
                                )}
                                {currentLanguage === Languages.EN && (
                                    <>
                                        {links &&
                                            links.slice(0, 3).map((link, i) => (
                                                <div key={i.toString()}>
                                                    <button onClick={() => handleLinkClick(link.id, link.sectionId)}>
                                                        <span>{link.linkLabelEn}</span>
                                                    </button>
                                                </div>
                                            ))}
                                    </>
                                )}
                            </LinksColumn>
                        )}
                        <LinksColumn>
                            {currentLanguage === Languages.SI && path.isPathHome() && (
                                <>
                                    {links &&
                                        links.slice(3, 6).map((link, i) => (
                                            <div
                                                style={{ position: 'relative' }}
                                                key={i.toString()}
                                                onMouseEnter={() =>
                                                    link.linkLabelEn ===
                                                        headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                    setShowProjects(true)
                                                }
                                                onMouseLeave={() =>
                                                    link.linkLabelEn ===
                                                        headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                    setShowProjects(false)
                                                }
                                            >
                                                <button onClick={() => handleLinkClick(link.id, link.sectionId)}>
                                                    <span>{link.linkLabelSi}</span>
                                                </button>
                                                <ProjectsPopup
                                                    show={
                                                        link.linkLabelEn ===
                                                            headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                        showProjects
                                                    }
                                                />
                                            </div>
                                        ))}
                                </>
                            )}
                            {currentLanguage === Languages.EN && path.isPathHome() && (
                                <>
                                    {links &&
                                        links.slice(3, 6).map((link, i) => (
                                            <div
                                                style={{ position: 'relative' }}
                                                key={i.toString()}
                                                onMouseEnter={() =>
                                                    link.linkLabelEn ===
                                                        headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                    setShowProjects(true)
                                                }
                                                onMouseLeave={() =>
                                                    link.linkLabelEn ===
                                                        headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                    setShowProjects(false)
                                                }
                                            >
                                                <button onClick={() => handleLinkClick(link.id, link.sectionId)}>
                                                    <span>{link.linkLabelEn}</span>
                                                </button>
                                                <ProjectsPopup
                                                    show={
                                                        link.linkLabelEn ===
                                                            headerFooterLinks[headerFooterLinks.length - 1].en &&
                                                        showProjects
                                                    }
                                                />
                                            </div>
                                        ))}
                                </>
                            )}
                            {/*<Socials>
                                <a>
                                    {' '}
                                    <img src={twIcon} alt="tw-icon" />{' '}
                                </a>
                                <a>
                                    {' '}
                                    <img src={fbIcon} alt="fb-icon" />
                                </a>
                                <a>
                                    <img src={igIcon} alt="ig-icon" />
                                </a>
                            </Socials>*/}
                        </LinksColumn>
                    </RightBlock>
                    </>
                )}
            </FooterContent>
        </FooterWrapper>
    );
}

function ProjectsPopup({ show }: PopupProps): JSX.Element {
    const { projectLinks, toggleDialogState } = useAppStateContext();

    return (
        <AnimatePresence>
            {show && (
                <ProjectsPopupWrapper variants={DropdownVariants} initial="hidden" animate="visible" exit="hidden">
                    {/* <div /> optional filter for dropdowns */}
                    {projectLinks &&
                        projectLinks.map((project) => (
                            <span key={project.projectID} onClick={() => toggleDialogState(project.projectID, false)}>
                                {project.projectShort}
                            </span>
                        ))}
                </ProjectsPopupWrapper>
            )}
        </AnimatePresence>
    );
}

const ProjectsPopupWrapper = styled(motion.div)`
    background-color: #000000;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    position: absolute;
    padding-bottom: 10px;
    padding-top: 25px;
    bottom: calc(100%);
    transform: translateX(-25%);
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & div {
        background: #4373af;
        word-break: 100%;
        height: 110%;
        width: 60%;
        position: absolute;
        z-index: -1;
        filter: blur(60px);
    }

    & span:nth-child(even) {
        color: #c2d5ed;
        transition: transform 0.1s ease-in-out;
    }

    & span {
        margin-bottom: 12px;
        white-space: nowrap;
        transition: transform 0.1s ease-in-out;
    }

    & span:last-child {
        margin-bottom: 0;
    }

    & span:hover {
        transform: scale(1.1);
        transition: transform 0.1s ease-in-out;
    }
`;

const FooterWrapper = styled.div<{ isMobile: boolean | undefined }>`
    z-index: 100;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;

    align-items: flex-end;
    background-color: #000000;
    padding: ${(props): string => (props.isMobile ? '60px 0 60px 20px' : '105px 3vw 120px 3vw')};
`;

{
    /**TOOD: Pokaži Žanu

height: 10vh;

*/
}
const FooterContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1200px;
`;

const LogoWrapper = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
`;

const LeftBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
`;

const RightBlock = styled.div<{ isMobile: boolean | undefined }>`
    display: flex;
    padding: ${(props): string => (props.isMobile ? '48px 0' : '0')};
`;

const LinksColumn = styled.div`
    display: grid;
    gap: 25px;
    flex-direction: column;
    width: 200px;

    & button {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;

        & span {
        }
    }
`;

const Socials = styled.div`
    display: flex;

    & img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    & a:nth-child(2) {
        margin: 0 15px;
    }
`;
