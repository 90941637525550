import React, { useState } from 'react';
import { useAppStateContext } from '../../../../Contexts/AppStateProvider';
import styled from 'styled-components';
import ProductsGrid from '../components/organisms/ProductsGrid';
import FilterBar from '../components/molecules/FilterBar';
import useTranslate from '../contexts/Translate/TranslateContext';
import { Category } from '../contexts/types';
import { Product } from '../contexts/Translate/types';

const Products = () => {

  const { isMobile, isTablet } = useAppStateContext()
  const {selectedLanguageValues} = useTranslate();

  //if undefined all categories are active
  const [activeProductsCategory, setActiveProductsCategory] = useState<Category>(Category.All);

  const updateCategory = (category: Category) => setActiveProductsCategory(category)

  const fetchActiveProducts = (): Product[] => {
    if (activeProductsCategory === Category.All) {
      return selectedLanguageValues.productTranslate
    }

    return selectedLanguageValues.productTranslate.filter(product => product.categories.includes(activeProductsCategory))
  }

  return (
    <ProductsContainer isMobile={isMobile} isTablet={isTablet}>
      <ProductsContent>
        <FilterBar activeFilter={activeProductsCategory} updateCategory={updateCategory}/>
        <ProductsGrid products={fetchActiveProducts()}/>
      </ProductsContent>
    </ProductsContainer>
  )
}

export default Products;

const ProductsContainer = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  display: flex;
  justify-content: center;
  align-items: center;  
  padding: ${props => props.isMobile ? "0 22px" : props.isTablet ? "0 40px" : "0 70px"};
  width: 100%;
`

const ProductsContent = styled.div`
  margin: 0 auto;
  max-width: 1300px;
`