import React from 'react';

import styled from 'styled-components';

import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages } from '../../Contexts/types';
import { appTitles } from '../../data/texts';

export default function PartnersSection(): JSX.Element {
    const { isMobile, currentLanguage, ourPartners } = useAppStateContext();

    if (!ourPartners || ourPartners.length === 0) return <></>;

    return (
        <PartnersSectionWrapper isMobile={isMobile} id="partners">
            <PartnersSectionContent>
                {currentLanguage === Languages.SI && <Title>{appTitles.partnersTitle.si}</Title>}
                {currentLanguage === Languages.EN && <Title>{appTitles.partnersTitle.en}</Title>}

                <PartnersLogosWrapper>
                    {ourPartners.map((logoURL, i) => (
                        <img key={i.toString()} src={logoURL} alt={(i + 1).toString()} />
                    ))}
                </PartnersLogosWrapper>
            </PartnersSectionContent>
        </PartnersSectionWrapper>
    );
}

const PartnersSectionWrapper = styled.div<{ isMobile: boolean | undefined }>`
    width: 100%;
    background-color: #000000;
    padding: ${(props): string => (props.isMobile ? '70px 20px 130px 20px' : '120px 3vw 170px 3vw')};

    @media (max-width: 550px) {
        padding: 50px 20px 100px 20px;
    }
`;

const PartnersSectionContent = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0px auto;
`;

const Title = styled.h1`
    font-size: 36px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    padding: 0;

    @media (max-width: 550px) {
        font-size: 28px;
    }
`;
const PartnersLogosWrapper = styled.div`
    width: 100%;
    margin-top: 65px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-around;
    align-items: center;

    & img {
        width: 80px;
        margin: 25px;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);

        @media (max-width: 550px) {
            width: 50px;
            margin: 20px;
        }
    }
`;
