import styled from "styled-components";

const AllInOneDescription = styled.p<{isTablet: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: ${props => props.isTablet ? "17" : "20"}px;
  line-height: ${props => props.isTablet ? "25" : "30"}px;
  text-align: center;
  color: #2C2C2C;
`

export default AllInOneDescription;