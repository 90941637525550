import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useDimensions } from '../../hooks/useDimensions';
// import useWindowSize from '../../hooks/useWindowSize';
import { useAppStateContext } from '../../Contexts/AppStateProvider';

import logo from '../../assets/images/vitasis-logo-white.png';
// import VitasisLogo from '../Icons/VitasisLogo';
import HeaderLinks from './HeaderLinks';
import { useHistory } from 'react-router';
// import { useScrollPosition } from "../../hooks/useScrollPosition";

const BreakPoints = {
    first: 975,
    second: 760,
    third: 660,
};

export enum Views {
    Normal = 'normal',
    NormalSmall = 'normalSmall',
    Tablet = 'tablet',
    Mobile = 'mobile',
}

interface MobileMenuButtonProps {
    open: boolean;
    onClick: () => void;
}

interface Scroller {
    opacity: string;
    onTop: boolean;
}

const Header = (scroller: Scroller): JSX.Element => {
    const { ref, dimensions } = useDimensions();
    const { isMobile, toggleMobileMenuOpen, mobileMenueOpen } = useAppStateContext();

    const history = useHistory();

    const [headerView, setHeaderView] = useState<Views>(Views.Normal);

    useEffect(() => {
        if (dimensions.width >= BreakPoints.first) {
            setHeaderView(Views.Normal);
        } else if (dimensions.width >= BreakPoints.second && dimensions.width < BreakPoints.first) {
            setHeaderView(Views.NormalSmall);
        } else if (dimensions.width >= BreakPoints.third && dimensions.width < BreakPoints.second) {
            setHeaderView(Views.Tablet);
        } else {
            setHeaderView(Views.Mobile);
        }
    }, [dimensions.width]);

    const handleLogoClick = () => {
        history.push('/');
        const anchorTarget = document.getElementById('intro-section');
        if (!anchorTarget) return;
        anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <HeaderWrapper opacity={scroller.opacity} isMobile={isMobile} mobileMenueOpen={mobileMenueOpen}>
            <HeaderContent ref={ref}>
                <LogoWrapper onClick={handleLogoClick}>
                    {/*       <VitasisLogo pathStyle={{fill: 'red'}}  /> */}
                    <img src={logo} alt="logo" style={{ width: '160px', height: '20px' }} />
                </LogoWrapper>
                {headerView === Views.Mobile ? (
                    <MobileMenuButton open={mobileMenueOpen} onClick={toggleMobileMenuOpen} />
                ) : (
                    <HeaderLinks view={headerView} />
                )}
            </HeaderContent>
        </HeaderWrapper>
    );
};

export default Header;

function MobileMenuButton({ open, onClick }: MobileMenuButtonProps) {
    return (
        <MobileMenuButtonWrapper open={open} onClick={onClick}>
            <MenuButtonLineTop open={open} />
            <MenuButtonLineBottom open={open} />
        </MobileMenuButtonWrapper>
    );
}

export const HeaderWrapper = styled.div<{
    isMobile: boolean | undefined;
    opacity: string;
    mobileMenueOpen: boolean | undefined;
}>`
    z-index: 100;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1;
    flex: 0 0 82px;
    align-items: flex-end;
    padding: ${(props) => (props.isMobile ? '0 20px' : '0px 3vw 0 3vw')};

    align-items: center;

    box-shadow: ${(props) => (props.mobileMenueOpen ? 'none' : props.opacity)};
    -webkit-box-shadow: ${(props) => (props.mobileMenueOpen ? 'none' : props.opacity)};
    -moz-box-shadow: ${(props) => (props.mobileMenueOpen ? 'none' : props.opacity)};
`;

export const HeaderContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    /*   padding-bottom: 20px; */
`;

export const LogoWrapper = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;

    cursor: pointer;
`;

const MenuButtonLineTop = styled.span<{ open: boolean | undefined }>`
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition: transform 0.2s ease-in-out;

    position: absolute;
    top: 0;
    transform: ${(props) => (props.open ? 'translateY(4px) rotate(45deg)' : 'translateY(0) rotate(0)')};
`;

const MenuButtonLineBottom = styled.span<{ open: boolean | undefined }>`
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition: transform 0.2s ease-in-out;
    position: absolute;

    transform: ${(props) => (props.open ? 'translateY(-3px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
`;

const MobileMenuButtonWrapper = styled.button<{ open: boolean | undefined }>`
    margin: 0;
    padding: 0;
    width: 20px;
    /*   height: 12px; */
    display: inline-flex;
    position: relative;
    flex-direction: column;
    padding-top: 9px;
    justify-content: flex-end;
    margin-top: 2px;
    transition: transform 0.2s ease-in-out;

    /* 
  & div {
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition: transform 0.2s ease-in-out;
  }

  & div:first-child {
    position: absolute;
    top: 0;
    background: red;
    transform: ${(props) => (props.open ? 'translateY(4px) rotate(45deg)' : 'translateY(0) rotate(0)')};
  }

  & div:last-child {
    background: blue;
    transform: ${(props) => (props.open ? 'translateY(-5px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
  } */
`;
