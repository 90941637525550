import { useLocation } from 'react-router-dom';

type UsePathReturnType = {
    isPathHome: () => boolean;
    isPathTruebar: () => boolean;
    pathName: string;
};
const usePath = (): UsePathReturnType => {
    const location = useLocation();
    const isPathHome = () => location.pathname === '/home';
    const isPathTruebar = () => location.pathname === '/products/truebar';
    const pathName = location.pathname;

    return {
        isPathHome,
        isPathTruebar,
        pathName,
    };
};

export default usePath;
