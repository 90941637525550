import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter as Router } from 'react-router-dom';
import AppStateProvider from './Contexts/AppStateProvider';
import MainLoader from './components/Common/MainLoader';

import './styles/global.css';
import './styles/reset.css';

// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(
    <React.StrictMode>
        <AppStateProvider>
            <Router>
                <Suspense fallback={<MainLoader />}>
                    <App />
                </Suspense>
            </Router>
        </AppStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
