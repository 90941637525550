import React from 'react';
import styled from 'styled-components';

import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages } from '../../Contexts/types';
import { useDimensions } from '../../hooks/useDimensions';
import { appTitles } from '../../data/texts';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function OthersAboutUs(): JSX.Element {
    const { isMobile, currentLanguage, testimonials, ourPartners } = useAppStateContext();
    const { ref, dimensions } = useDimensions();

    if (!testimonials) return <></>;

    return (
        <OthersAboutUsWrapper isMobile={isMobile} ref={ref} style={!ourPartners ? { paddingTop: '100px' } : {}}>
            <OthersAboutUsContent resizeTitle={dimensions.width <= 550}>
                <h1>
                    {currentLanguage === Languages.SI
                        ? appTitles.othersAboutUsTitle.si
                        : appTitles.othersAboutUsTitle.en}
                </h1>

                {isMobile ? (
                    <Slider
                        //dotsClass='dots_override'
                        dots={true}
                        slidesToShow={dimensions.width <= 400 ? 1.2 : 1.5}
                        slidesToScroll={1}
                        autoplay={true}
                        autoplaySpeed={300000}
                        centerMode={true}
                        arrows={false}
                    >
                        {testimonials.slice(0.3).map((testimonial) => (
                            <CardWrapper
                                isMobile={isMobile}
                                key={testimonial.name_and_surname}
                                resize={dimensions.width <= 945}
                            >
                                <CardContent resize={dimensions.width <= 780}>
                                    <ImageContainer alt="testimonial_pic" src={testimonial.image_url} />
                                    <CardTitle>
                                        <p>{testimonial.name_and_surname}</p>
                                        <p>{testimonial.position}</p>
                                        <p>{testimonial.company_name}</p>
                                    </CardTitle>
                                    <Testimonial>
                                        {currentLanguage === Languages.EN
                                            ? testimonial.testimonial_text_en
                                            : testimonial.testimonial_text_si}
                                    </Testimonial>
                                </CardContent>
                            </CardWrapper>
                        ))}
                    </Slider>
                ) : (
                    <CardsWrapperRegular>
                        {testimonials.slice(0.3).map((testimonial) => (
                            <CardWrapper
                                isMobile={isMobile}
                                key={testimonial.name_and_surname}
                                resize={dimensions.width <= 945}
                            >
                                <CardContent resize={dimensions.width <= 780}>
                                    <ImageContainer alt="testimonial_pic" src={testimonial.image_url} />
                                    <CardTitle>
                                        <p>{testimonial.name_and_surname}</p>
                                        <p>{testimonial.position}</p>
                                        <p>{testimonial.company_name}</p>
                                    </CardTitle>
                                    <Testimonial>
                                        {currentLanguage === Languages.EN
                                            ? testimonial.testimonial_text_en
                                            : testimonial.testimonial_text_si}
                                    </Testimonial>
                                </CardContent>
                            </CardWrapper>
                        ))}
                    </CardsWrapperRegular>
                )}
            </OthersAboutUsContent>
        </OthersAboutUsWrapper>
    );
}

export default OthersAboutUs;

const OthersAboutUsWrapper = styled.div<{ isMobile: boolean | undefined }>`
    background-color: #000000;
    width: 100%;
    padding: ${(props) => (props.isMobile ? '0 0 120px 20px' : '0 3vw 120px 3vw')};
`;

const CardWrapper = styled.div<{
    isMobile: boolean | undefined;
    resize: boolean | undefined;
}>`
    overflow: hidden;
    max-width: 365px;
    width: 90% !important;
    border: 1px solid #808080;
    /* height: ${(props) => (props.resize ? '600px' : '455px')}; */
`;

const OthersAboutUsContent = styled.div<{ resizeTitle: boolean | undefined }>`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    & ${CardWrapper}:nth-child(2) {
        margin: 0 35px;
    }

    & h1 {
        margin: 0;
        padding: 0;
        font-size: ${(props) => (props.resizeTitle ? '28px' : '36px')};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: calc(2em + 15px);
    }
`;
const CardsWrapperRegular = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
`;

const CardContent = styled.div<{ resize: boolean | undefined }>`
    padding: ${(props) => (props.resize ? '17px 15px 20px 15px' : '28px 25px')};
    height: 100%;
`;
const ImageContainer = styled.img`
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-bottom: 35px;

    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
`;
const CardTitle = styled.div`
    margin-bottom: 40px;
    & p {
        font-weight: bold;
        font-size: 17px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
    }
`;

const Testimonial = styled.p`
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    padding: 0;
`;
