import React from "react";
import useTranslate from "../truebar/contexts/Translate/TranslateContext";
import { Languages } from "../truebar/contexts/Translate/types";
import { useHistory } from "react-router-dom";

const TruebarApps = () => {
    const {
        selectedLanguage,
        changeLanguage
    } = useTranslate()

    const history = useHistory();

    const navigateToClientsTermsOfUse = () => history.push('/client_terms_of_use');
    const navigateToEndUserTermsOfUse = () => history.push('/end_users_terms_of_use');
    const navigateToPrivacyPolicy = () => history.push('/privacy_policy');


    return (
        <>
            <div style={{ float: 'right', paddingTop: '2vh', paddingRight: '5vw' }}>
                <button style={{ color: 'white' }} onClick={() => changeLanguage()}>
                    {selectedLanguage === Languages.si ? 'EN' : 'SI'}
                </button>
            </div>

            <div style={{ padding: '0 10vw' }}>
                <h1 style={{ textAlign: 'center', margin: '5vh 0' }}>{selectedLanguage === Languages.si ? 'Pogoji uporabe in politike' : 'Terms of use and policies'}</h1>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <a
                        style={{
                            padding: '2vh',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#C2D5ED',
                            textDecoration: 'none',
                        }}
                        onClick={navigateToClientsTermsOfUse}>
                        {`${selectedLanguage === Languages.si ? 'Pogoji uporabe za naročnike' : 'Terms of Use for Clients'}`}
                    </a>

                    <a
                        style={{
                            padding: '2vh',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#C2D5ED',
                            textDecoration: 'none',
                        }}
                        onClick={navigateToEndUserTermsOfUse}>
                        {`${selectedLanguage === Languages.si ? 'Pogoji uporabe za končne uporabnike' : 'Terms of Use for End Users'}`}
                    </a>

                    <a
                        style={{
                            padding: '2vh',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#C2D5ED',
                            textDecoration: 'none',
                        }}
                        onClick={navigateToPrivacyPolicy}>
                        {`${selectedLanguage === Languages.si ? 'Politika zasebnosti' : 'Privacy policy'}`}
                    </a>
                </div>
            </div>
        </>
    )
}

export default TruebarApps;