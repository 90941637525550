import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../Contexts/AppStateProvider';
import SubHeaderContent from '../components/organisms/SubHeaderContent';
import useTheme from '../contexts/Theme/ThemeContext';

const SubHeader = () => {

  const { isMobile, isTablet } = useAppStateContext()
  const { colorSchemeValues } = useTheme()

  return (
    <CurvedSubHeaderContainer isTablet={isTablet} isMobile={isMobile} backgroundColor={colorSchemeValues.subHeaderBackground} relativeBackgroundColor={'#fff'}>
      <SubHeaderContent />
    </CurvedSubHeaderContainer>
  )
}

export default SubHeader;

const CurvedSubHeaderContainer = styled.div<{ isMobile: boolean | undefined, backgroundColor: string, relativeBackgroundColor: string, isTablet: boolean | undefined }>`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${props => props.relativeBackgroundColor};
    padding: ${props => props.isMobile ? "41px 20px 40px 20px" : props.isTablet ? "68px 64px 60px 39px" : "98px 160px 200px 66px"};

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.backgroundColor};
        border-radius: 0 0 50% 50% / 0 0 100% 100%;
        transform: scaleX(${props => props.isMobile ? "5" : "4"});
    }
`