import React from "react";
import useTranslate from "../truebar/contexts/Translate/TranslateContext";
import { Languages } from "../truebar/contexts/Translate/types";
import DownloadSection, { IVersions } from "../DownloadSection";

const END_USERS_TERMS_OF_USE_FILE_PATH_PREFIX = "./files/terms_of_use_end_users/"
const versions: IVersions[] = [
    {
        englishFileName: 'TERMS AND CONDITIONS FOR USE OF TRUEBAR SERVICE FOR END USERS_v1.pdf',
        slovenianFileName: 'DOLOČILA IN POGOJI UPORABE STORITVE TRUEBAR ZA KONČNE UPORABNIKE_v1.pdf'
    }
]

const EndUsersTermsOfUse = () => {

    const {
        selectedLanguage,
        changeLanguage
    } = useTranslate()

    const currentVersionFileName = selectedLanguage === Languages.si ? versions[0].slovenianFileName : versions[0].englishFileName

    return (
        <>
            <div style={{ float: 'right', paddingTop: '2vh', paddingRight: '5vw' }}>
                <button style={{ color: 'white' }} onClick={() => changeLanguage()}>
                    {selectedLanguage === Languages.si ? 'EN' : 'SI'}
                </button>
            </div>

            <div style={{ padding: '0 10vw' }}>
                <h1 style={{ textAlign: 'center', margin: '5vh 0' }}>{selectedLanguage === Languages.si ? 'Pogoji uporabe za končne uporabnike' : 'Terms of use for End Users'}</h1>
                <h2 style={{ margin: '10vh 0 5vh 0' }}>{selectedLanguage === Languages.si ? 'Trenutna verzija' : 'Current version'}</h2>
                <DownloadSection versionName={currentVersionFileName} filePathPrefix={END_USERS_TERMS_OF_USE_FILE_PATH_PREFIX} />

                {versions.length > 1 && (
                    <>
                        <h2 style={{ margin: '10vh 0 5vh 0' }}>{selectedLanguage === Languages.si ? 'Zgodovin verzij' : 'Version history'}</h2>
                        {versions.slice(1).map(version => {
                            const historyVersionFileName = selectedLanguage === Languages.si ? version.slovenianFileName : version.englishFileName
                            return <DownloadSection versionName={historyVersionFileName} filePathPrefix={END_USERS_TERMS_OF_USE_FILE_PATH_PREFIX} />
                        })}
                    </>
                )}
            </div>
        </>
    )

}

export default EndUsersTermsOfUse;