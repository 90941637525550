import React, { createContext, useContext, useState, useEffect } from 'react';
import { DialogState, Languages, AppSate, WindowDimensions, Orientations, DialogVariant } from './types';
import useInitialFetch from '../hooks/useInitialFetch';
import { useRef } from 'react';

const BreakPoints = {
    mobile: 680,
    mountMobileMenu: 705,
    tablet: 1024
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const AppStateContext = createContext({} as AppSate);

const AppStateProvider = ({ children }: any): JSX.Element => {
    const isClient = typeof window === 'object';
    const [mobileMenueOpen, setMobileMenueOpen] = useState<boolean>(false);
    const cookieKey = useRef<string>('vitasisCookie').current;
    const [acceptedCookies, setAcceptedCookies] = useState<boolean>(localStorage.getItem(cookieKey) ? true : false);
    const [mountMenu, setMountMenu] = useState<boolean>(false);
    const [currentLanguage, setCurrentLanguage] = useState<Languages>(Languages.SI);
    const [videoModalState, setVideoModalState] = useState<boolean>(false);
    const [videoModalUrl, setVideoModalUrl] = useState<string | undefined>(undefined);
    const [currentDialogState, setDialogState] = useState<DialogState>({
        dialogVariant: undefined,
        projectUID: undefined,
    });

    const { mainLoading, projectLinks, careers, ourPartners, lastNews, testimonials } = useInitialFetch(
        currentLanguage
    );

    const getSize = (): WindowDimensions => ({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
        // eslint-disable-next-line no-nested-ternary
        orientation: isClient
            ? window.innerHeight > window.innerWidth
                ? Orientations.PORTRAIT
                : Orientations.LANDSCAPE
            : undefined,
    });

    const [windowSize, setWindowSize] = useState<WindowDimensions>(getSize());
    const [isMobile, setIsMobile] = useState<boolean>();
    const [isTablet, setIsTablet] = useState<boolean>();

    
    useEffect((): any => {
        const setSize = (): void => {
            setWindowSize(getSize());
        };

        console.log(`Cookie key ${localStorage.getItem(cookieKey)}`)

        if (!isClient) {
            return false;
        }
        // timeoutId for debounce mechanism
        let timeoutId: any = null;
        const resizeListener = (): void => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 50 milliseconds
            timeoutId = setTimeout(() => setSize(), 50);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return (): void => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    useEffect(() => {
        if (windowSize.width) {
            if (windowSize.width < BreakPoints.mobile && !isMobile) {
                setIsMobile(true)
                setIsTablet(false)
            } else if (windowSize.width < BreakPoints.tablet && windowSize.width >= BreakPoints.mobile && !isTablet) {
                setIsTablet(true)
                setIsMobile(false)
            } else if ((windowSize.width > BreakPoints.tablet && isTablet) || (windowSize.width > BreakPoints.mobile && isMobile)) {
                setIsTablet(false);
                setIsMobile(false)
            }
        }
    }, [windowSize.width, isMobile, isTablet]);


    useEffect(() => {
        if (windowSize.width) {
            if (windowSize.width < BreakPoints.mountMobileMenu && !mountMenu) {
                setMountMenu(true);
            } else if (windowSize.width > BreakPoints.mountMobileMenu && mountMenu) {
                setMountMenu(false);
            }
        }
    }, [windowSize.width, mountMenu]);

    function toggleMobileMenuOpen() {
        setMobileMenueOpen(!mobileMenueOpen);
    }

    function toggleLanugage() {
        if (currentLanguage === Languages.EN) {
            setCurrentLanguage(Languages.SI);
        } else if (currentLanguage === Languages.SI) {
            setCurrentLanguage(Languages.EN);
        }
    }

    function toggleDialogState(UID?: string, closeBtn?: boolean): void {
        if (closeBtn) {
            setDialogState({
                dialogVariant: undefined,
                projectUID: undefined,
            });
            return;
        }

        switch (UID) {
            case DialogVariant.ReadMore:
                setDialogState({
                    dialogVariant: DialogVariant.ReadMore,
                    projectUID: undefined,
                });
                break;

            case DialogVariant.Careers:
                setDialogState({
                    dialogVariant: DialogVariant.Careers,
                    projectUID: undefined,
                });
                break;

            default:
                setDialogState({
                    dialogVariant: DialogVariant.Project,
                    projectUID: UID,
                });
        }
    }

    const acceptCookies = (): void => {
        console.log('Enabling cookies')
        try { 
            localStorage.setItem(cookieKey, "accepted")
            setAcceptedCookies(true);
            console.log('Cookies enabled')
        } catch (error) {
            console.log(error);
        }
    }
    
    const openVideoModal = (url: string) => {
        setVideoModalState(true);
        setVideoModalUrl(url)
    }

    const closeVideoModal = () => {
        setVideoModalState(false);
        setVideoModalUrl(undefined)
    }

    return (
        <AppStateContext.Provider
            value={{
                windowSize,
                isMobile,
                isTablet,
                mobileMenueOpen,
                toggleMobileMenuOpen,
                mountMenu,
                currentLanguage,
                toggleLanugage,
                projectLinks,
                currentDialogState,
                acceptedCookies,
                toggleDialogState,
                acceptCookies,
                mainLoading,
                careers,
                ourPartners,
                lastNews,
                testimonials,
                setCurrentLanguage,
                videoModalState,
                openVideoModal,
                closeVideoModal,
                videoModalUrl,
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
};

export default AppStateProvider;

export const useAppStateContext = () => useContext(AppStateContext);
