export enum Orientations {
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSPACE',
}

export enum Languages {
    SI = 'SI',
    EN = 'EN',
}

export enum DialogVariant {
    Project = 'project',
    ReadMore = 'read-more',
    Careers = 'careers',
}

export interface DialogState {
    dialogVariant: DialogVariant | undefined;
    projectUID?: string;
}

export interface WindowDimensions {
    height: number | undefined;
    width: number | undefined;
    orientation: Orientations | undefined;
}

export interface AppSate {
    windowSize: WindowDimensions;
    isMobile: boolean | undefined;
    isTablet: boolean | undefined;
    mobileMenueOpen: boolean;
    toggleMobileMenuOpen: () => void;
    mountMenu: boolean;
    currentLanguage: Languages;
    toggleLanugage: () => void;
    projectLinks: ProjectLink[] | undefined;
    currentDialogState: DialogState;
    toggleDialogState: (UID?: string, closeBtn?: boolean) => void;
    acceptedCookies: boolean;
    acceptCookies: () => void;
    mainLoading: boolean;
    careers: Careers | undefined;
    ourPartners: string[] | undefined;
    lastNews: News[] | undefined;
    testimonials: TestimonialCard[] | undefined;
    setCurrentLanguage: React.Dispatch<React.SetStateAction<Languages>>;
    videoModalState: boolean;
    openVideoModal: (url: string) => void;
    closeVideoModal: () => void;
    videoModalUrl: string | undefined;
}

export interface Project {
    project_name_si: string | undefined;
    project_name_en: string | undefined;
    project_duration_si: string | undefined;
    project_duration_en: string | undefined;
    projectDescSI: any;
    projectDescEN: any;
    partners: string | undefined;
    logosEn: string[] | undefined;
    logosSi: string[] | undefined;
}

export interface ProjectDescription {
    type: string;
    text: string;
}

export interface ProjectLink {
    projectNameSi: string;
    projectNameEn: string;
    projectID: string;
    projectShort: string;
}

export interface News {
    title_si: string;
    title_en: string;
    date: string;
    text_si: string;
    text_en: string;
    link: string;
    exp_date: string;
}

export interface Careers {
    careers_info_si: any;
    careers_info_en: any;
}

export interface TestimonialCard {
    image_url: string;
    name_and_surname: string;
    position: string;
    company_name: string;
    testimonial_text_si: string;
    testimonial_text_en: string;
}

export interface InitLoadingRequests {
    mainLoading: boolean;
    projectLinks: ProjectLink[] | undefined;
    careers: Careers | undefined;
    ourPartners: string[] | undefined;
    testimonials: TestimonialCard[] | undefined;
    lastNews: News[] | undefined;
}
