import React, { FC } from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import useScalable from '../../../../../hooks/useScalable';
import useTheme from '../../contexts/Theme/ThemeContext';
import { Product } from '../../contexts/Translate/types';
import CardCategory from '../atoms/card/CardCategory';
import CardDescription from '../atoms/card/CardDescription';
import CardTitle from '../atoms/card/CardTitle';
import PlayButton from '../atoms/card/PlayButton';

interface Props {
  product: Product
}
const Card: FC<Props> = ({ product }) => {

  const { isMobile, isTablet, windowSize } = useAppStateContext();
  const { getCardBackgroundColor, colorSchemeValues } = useTheme();

  const { scale } = useScalable();

  let cardTitle = (<CardTitle isSmaller={isMobile || isTablet}>{product.title}</CardTitle>)
  let cardSubtitle = product.subtitle.length > 0 && (<CardSubtitle isMobile={isMobile} isTablet={isTablet} fontColor={colorSchemeValues.extraordinary}>{product.subtitle}</CardSubtitle>)
  let cardCategories = (
    <CategoryWrapper>
      {product.categories.map(category => <CardCategory key={category} category={category} />)}
    </CategoryWrapper>
  )
  let cardDescription = <CardDescription isTablet={isTablet} isMobile={isMobile}>{product.description}</CardDescription>
  let cardContent = <></>

  if (isTablet) {
    cardContent = (
      <TabletCardContainer>
        <div>
          {cardTitle}
          {cardSubtitle}
          {cardDescription}
          {cardCategories}
        </div>
        <PlayButton url={product.url}/>
      </TabletCardContainer>
    )
  } else if (isMobile) {
    cardContent = (
      <div>
        {cardTitle}
        {cardSubtitle}
        {cardCategories}
        {cardDescription}
        <PlayButton url={product.url}/>
      </div>
    )
  } else {

    const initialWrapperWidth = product.categories.length === 1 ? 290 : 240;
    let textWrapperWidth = initialWrapperWidth
    if (windowSize.width) {
      if (windowSize.width > 1440) {
        textWrapperWidth = 380
      } else if (windowSize.width > 1350 && windowSize.width <= 1440) {
        textWrapperWidth = initialWrapperWidth + (windowSize.width - 1350) * ((380-initialWrapperWidth)/(1440-1350))
      }
    }

    let imageMarginTop = 280;
    if (windowSize.width) {
      if (windowSize.width > 1440) {
        imageMarginTop = 200
      } else if (windowSize.width > 1024 && windowSize.width <= 1440) {
        imageMarginTop = 280 + (windowSize.width - 1024) * ((200-280)/(1440-1024))
      }
    }
    

    cardContent = (
      <DesktopWrapper width={scale(560)}>
        <DesktopContainer>
          <div>
            <CardImage marginTop={imageMarginTop} image={product.image} alt={product.title} width='60%' src={product.image} />
            <TextWrapper maxWidth={textWrapperWidth}>
              {cardTitle}
              {cardSubtitle}
              {cardDescription}
            </TextWrapper>
          </div>
        </DesktopContainer>
        <CategoriesWrapper>
          {cardCategories}
        </CategoriesWrapper>

        <ButtonWrapper>
          <PlayButton url={product.url} productName={product.title}/>
        </ButtonWrapper>
      </DesktopWrapper>
    )
  }
  return (
    <CardWrapper isSmaller={isMobile || isTablet} backgroundColor={getCardBackgroundColor(product.categories[product.categories.length - 1])}>
      {cardContent}
    </CardWrapper>
  )
}

export default Card;

const DesktopWrapper = styled.div<{ width: number }>`
  display: flex;
  width: ${props => props.width}px;
  max-width: 560px;
  flex-direction: column;
  max-height: 490px;
`
const DesktopContainer = styled.div`
  flex: 1;
`
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 20px;
`

const TextWrapper = styled.div<{maxWidth: number}>`
  max-width: ${props => props.maxWidth}px;
`

const CardWrapper = styled.div<{ isSmaller: boolean | undefined, backgroundColor: string }>`
  padding: ${props => props.isSmaller ? "24px 16px" : "40px 40px 40px 40px"};
  background-color: ${props => props.backgroundColor};
  border-radius: 16px;
  position: relative;
  display: flex;
`

const CategoriesWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 30px;
`

const TabletCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 470px;
  flex: 1;
`

export const CardCategoryContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor};
  width: 137px;
  height: 34px;
  padding: 8px 16px;
  border-radius: 8px;
`

const CategoryWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  & ${CardCategoryContainer}:not(:first-child) {
    margin-top: 16px;
  }
`

const CardImage = styled.img<{ image: string, marginTop: number }>`
  float: right;
  shape-outside: url(${props => props.image});
  shape-margin: 1em;
  margin-top: ${props => props.marginTop}px;
`

const CardSubtitle = styled.p<{isMobile: boolean | undefined, isTablet: boolean | undefined, fontColor: string}>`
  font-size: ${props => props.isMobile ? 18 : props.isTablet ? 20 : 22}px;
  font-weight: bold;
  color: ${props => props.fontColor};
  font-family: Roboto;
`