import styled from "styled-components";


const CategoryDescription = styled.p<{ isTablet: boolean | undefined, isMobile: boolean | undefined }>`
  margin-top: ${props => props.isMobile ? "8" : "16"}px;
  margin-bottom: ${props => props.isMobile ? "24" : "40"}px;
  font-family: Roboto;
  font-style: normal;
  font-weight: ${props => props.isMobile ? "400" : props.isTablet ? "400" : "500"};
  font-size: ${props => props.isMobile ? "16" : props.isTablet ? "17" : "19"}px;
  line-height: ${props => props.isMobile ? "24" : props.isTablet ? "25" : "28"}px;
  color: #2C2C2C;
`

export default CategoryDescription;