import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import useTranslate from "./truebar/contexts/Translate/TranslateContext";

interface Props {
    visible: boolean;
    toggle: () => void;
    headerText: string;
    marginTop?: string;
    filePath: string;
}

const FileViewAndDownloadBar: FC<Props> = ({children, visible, toggle, headerText, filePath, marginTop=0}) => {
    const {
        selectedLanguageValues,
    } = useTranslate()

    const toggleButtonText = visible ? selectedLanguageValues.legalPages.close.toUpperCase() : selectedLanguageValues.legalPages.open.toUpperCase()
    return (
        <>
            <div style={{ marginTop: marginTop, padding: '2vh 2vw', backgroundColor: '#C2D5ED', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ fontWeight: 'bold', color: '#0E0E0E' }}>{headerText}</p>
                <div>
                    <a style={{ color: 'white', fontWeight: 'bold', padding: '1vh 1vw', backgroundColor: '#0E0E0E' }} onClick={toggle}>{toggleButtonText}</a>
                    
                    <a style={{ color: 'white', fontWeight: 'bold', marginLeft: '1vw', padding: '1vh 1vw', backgroundColor: '#0E0E0E', textDecoration: 'none' }} href={filePath} download={filePath}>
                        {selectedLanguageValues.legalPages.download.toUpperCase()}
                    </a>
                </div>
            </div>
            <Wrapper visible={visible}>
                {visible && children}
            </Wrapper>
        </>
    )
}

export default FileViewAndDownloadBar;

const fadeInModal = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOutModal = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Wrapper = styled.div<{ visible: boolean }>`
  visibility: ${props => props.visible ? "visible" : "hidden"};
  animation: ${props => props.visible ? fadeInModal : fadeOutModal} ${props => props.visible ? '0.8' : '0.5'}s linear;
  transition: visibility ${props => props.visible ? '0.8' : '0.5'}s linear;
`