import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { DialogVariant } from '../../Contexts/types';
import ProjectDialog from './Dialogs/ProjectDialog';
import CookiesDialog from './Dialogs/CookiesDialog';
import CareersDialog from './Dialogs/CareersDialog';
import { motion, AnimatePresence } from 'framer-motion';

const DialogVariants = {
    hidden: {
        x: '100%',
        transition: { delay: 0.2, ease: 'easeOut', duration: 0.3 },
    },
    visible: {
        x: 0,
        transition: { ease: 'easeOut', duration: 0.3 },
    },
};

const ButtonVariants = {
    hidden: {
        x: '100%',
        opacity: 0,
        transition: { ease: 'easeOut', duration: 0.2 },
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { ease: 'easeOut', duration: 0.2 },
    },
};

interface DialogProject {
    projectName: string;
    projectYear: string;
    projectDescription: any;
    projectFacts: ProjectFacts[];
    logos: Logo[];
}
interface Logo {
    url: string;
}
interface ProjectFacts {
    financing: string;
    investment_amount: string;
    partners: string[];
    duration: string;
}

function Dialog(): JSX.Element {
    const { currentDialogState, toggleDialogState, isMobile } = useAppStateContext();

    return (
        <AnimatePresence>
            {currentDialogState.dialogVariant && (
                <>
                    <ButtonWrapper mobile={isMobile}>
                        <Button
                            variants={ButtonVariants}
                            initial={'hidden'}
                            animate="visible"
                            exit="hidden"
                            mobile={isMobile}
                            onClick={() => toggleDialogState(undefined, true)}
                        >
                            <div /> <div />
                        </Button>
                    </ButtonWrapper>
                    <DialogWrapper
                        variants={DialogVariants}
                        initial={'hidden'}
                        animate="visible"
                        exit="hidden"
                        mobile={isMobile}
                        style={{ color: '#000' }}
                    >
                        <ContentWrapper>
                            {currentDialogState.dialogVariant === DialogVariant.Project && <ProjectDialog />}
                            {currentDialogState.dialogVariant === DialogVariant.ReadMore && <CookiesDialog />}
                            {currentDialogState.dialogVariant === DialogVariant.Careers && <CareersDialog />}
                        </ContentWrapper>
                    </DialogWrapper>
                </>
            )}
        </AnimatePresence>
    );
}

export default Dialog;

//Cover style
const DialogWrapper = styled(motion.div)<{ mobile: boolean | undefined}>`
    min-height: 100vh;
    background-color: #fff;
    z-index: 1100;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: fixed;
    padding: 20px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: ${(props) => (props.mobile ? '40px' : '135px')};
    padding-bottom: ${(props) => (props.mobile ? '80px' : '150px')};
`;
const ContentWrapper = styled.div`
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`;

//Button styles
const ButtonWrapper = styled.div<{ mobile: boolean | undefined }>`
    position: fixed;
    top: ${(props) => (props.mobile ? '30px' : '50px')};
    right: ${(props) => (props.mobile ? '0px' : '0px')};

    z-index: 10000;
    width: 100%;
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Button = styled(motion.button)<{ mobile: boolean | undefined }>`
    background-color: #000000;
    height: 24px;
    width: 24px;
    color: white;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: ${(props) => (props.mobile ? '20px' : '-5%')};

    & div {
        position: absolute;
        height: 2px;
        width: 14px;
        background-color: #FFFFFF;
    }
    & div:first-child {
        transform: rotate(45deg);
    }
    & div:last-child {
        transform: rotate(315deg);
    }
`;
