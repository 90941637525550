import styled from "styled-components";

const TruebarSubtitle = styled.h1<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.isMobile || props.isTablet ? "24" : "40"}px;
  line-height: ${props => props.isMobile || props.isTablet ? "36" : "60"}px;
  letter-spacing: -0.025em;
  color: #222222;
  margin-top: ${props => props.isMobile ? "2" : props.isTablet ? "9" : "15"}px;
  margin-bottom: ${props => props.isMobile ? "16" : props.isTablet ? "24" : "30"}px;
  ${props => props.isTablet ? "margin-left: 5px" : ""};
`

export default TruebarSubtitle;