import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { News, Languages } from '../../Contexts/types';

interface NewsCardProps {
    data: News[];
    moveRight?: boolean;
    currentLanguage: Languages;
}

export default function NewsCard({ data, moveRight, currentLanguage }: NewsCardProps): JSX.Element {
    const [currentNews, setCurrentNews] = useState<number>(0);

    function handleChangeNewsClick(i: number) {
        setCurrentNews(i);
    }

    const title = currentLanguage === Languages.EN ? data[currentNews]?.title_en : data[currentNews]?.title_si;
    const text = currentLanguage === Languages.EN ? data[currentNews]?.text_en : data[currentNews]?.text_si;
    const date = data[currentNews].date ? moment(data[currentNews].date).format('D.M.YYYY') : '';
    const link = data[currentNews].link;

    return (
        <CardWrapper style={{ marginLeft: moveRight ? 'auto' : '' }}>
            <CardContent>
                <div>
                    <TitleLine>
                        <span>{title ? title : 'Novica'}</span>
                        <span>{date ? date : ''}</span>
                    </TitleLine>
                    <p>{text ? text : ''}</p>
                </div>
                {link && (
                    <a style={{ width: 'max-content' }} href={link} rel="noopener noreferrer" target="_blank">
                        Več
                    </a>
                )}
            </CardContent>
            <ButtonsWrapper>
                {data.length > 1 &&
                    data.map((item, i) => (
                        <TestDiv
                            selected={i === currentNews}
                            onClick={() => handleChangeNewsClick(i)}
                            key={i.toString()}
                        />
                    ))}
            </ButtonsWrapper>
        </CardWrapper>
    );
}
const TestDiv = styled.div<{ selected: boolean }>`
    background-color: ${(props) => (props.selected ? '#fff' : 'transparent')};
    animation: ${(props) => (props.selected ? 'switch 0.3s ease-in;' : false)};
    background-position: left bottom;

    @keyframes switch {
        0% {
            background-color: transparent;
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            background-color: #fff;
            transform: scale(1);
        }
    }
`;

const CardWrapper = styled.div`
    width: 100%;
    position: relative;
    min-height: 290px;
    max-height: 300px;
    max-width: 365px;
    border: solid 1px #c2d5ed;
    background-color: #000000;
    padding: 30px 25px;
`;

const CardContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: 'Roboto';
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #c2d5ed;

    & P {
        margin: 0;
        paddin: 0;
    }

    & a {
        margin-top: 25px;
        float: left;
        text-decoration: none;
        color: inherit;
    }
`;

const TitleLine = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    & span:first-child {
        font-weight: bold;
    }
`;

const ButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    bottom: 30px;
    & div {
        width: 16px;
        height: 16px;
        border: solid 1px #ffffff;
        border-radius: 50%;
        cursor: pointer;
        touch-action: manipulation;
    }

    & div:not(:last-child) {
        margin-right: 12px;
    }
`;
