import React, { useState } from 'react';
import styled from 'styled-components';
import { Views } from './Header';
import { Languages, DialogVariant } from '../../Contexts/types';
import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { headerFooterLinks } from '../../data/texts';

import { motion, AnimatePresence } from 'framer-motion';

export const DropdownVariants = {
    hidden: {
        opacity: 0,
        transition: { ease: 'easeOut', duration: 0.8 },
    },
    visible: { opacity: 1, transition: { ease: 'easeOut', duration: 0.8 } },
};

interface HeaderLinksProps {
    view: Views;
}

interface Props {
    showDropdown: boolean;
}

export interface HeaderLinkProps {
    linkLabel: string;
    showLine: boolean;
    onClick: () => void;
    dropdown: boolean;
    specificSection: string;
}

export interface Link {
    id: number;
    linkLabelEn: string;
    linkLabelSi: string;
    sectionId: string;
}

export const links: Link[] = [
    {
        id: 1,
        sectionId: 'products',
        linkLabelEn: headerFooterLinks[0].en,
        linkLabelSi: headerFooterLinks[0].si,
    },
    {
        id: 2,
        sectionId: 'about-us',
        linkLabelEn: headerFooterLinks[1].en,
        linkLabelSi: headerFooterLinks[1].si,
    },
    {
        id: 3,
        sectionId: 'contact',
        linkLabelEn: headerFooterLinks[2].en,
        linkLabelSi: headerFooterLinks[2].si,
    },
    {
        id: 4,
        sectionId: 'careers',
        linkLabelEn: headerFooterLinks[3].en,
        linkLabelSi: headerFooterLinks[3].si,
    },
    {
        id: 5,
        sectionId: 'funding',
        linkLabelEn: headerFooterLinks[4].en,
        linkLabelSi: headerFooterLinks[4].si,
    },
    // {
    //     id: 6,
    //     sectionId: '',
    //     linkLabelEn: '',
    //     linkLabelSi: '',
    // },
];

export default function HeaderLinks({ view }: HeaderLinksProps): JSX.Element {
    const { currentLanguage, toggleDialogState, toggleLanugage } = useAppStateContext();

    const [showLanguageDropdown, setShowLanguageDropdown] = useState<boolean>(false);

    const handleLinkClick = (id: number, sectionId: string) => {
        if (id === 4) {
            toggleDialogState(DialogVariant.Careers, false);
            return;
        }
        const anchorTarget = document.getElementById(sectionId);
        if (!anchorTarget) return;
        anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <HeaderLinksWrapper>
            {links &&
                links.map((link) => (
                    <HeaderLink
                        dropdown={link.linkLabelEn === headerFooterLinks[headerFooterLinks.length - 1].en}
                        linkLabel={currentLanguage === Languages.EN ? link.linkLabelEn : link.linkLabelSi}
                        showLine={view === Views.Normal /* || view === Views.NormalSmall */}
                        onClick={() => handleLinkClick(link.id, link.sectionId)}
                        key={link.id}
                        specificSection={link.sectionId}
                    />
                ))}
            <LanguageWrapper
                onMouseEnter={() => setShowLanguageDropdown(true)}
                onMouseLeave={() => setShowLanguageDropdown(false)}
                style={{
                    marginLeft: view === Views.Normal ? '42px' : '20px',
                    position: 'relative',
                }}
                onClick={() => toggleLanugage()}
            >
                <span>{currentLanguage === Languages.EN ? 'SI' : 'EN'}</span>
                {/* <LanguageDropdown showDropdown={showLanguageDropdown} /> */}
            </LanguageWrapper>
        </HeaderLinksWrapper>
    );
}

function HeaderLink({ linkLabel, onClick, showLine, dropdown, specificSection }: HeaderLinkProps): JSX.Element {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
        >
            <LinkWrapper onClick={onClick}>
                <span style={{ margin: showLine ? '0 40px' : '0 11px' }}>{linkLabel}</span>
                {showLine && <LinkDivider>{/* / */}</LinkDivider>}
            </LinkWrapper>
            <LinkDropdown showDropdown={dropdown && showDropdown} />
        </div>
    );
}

function LinkDropdown(props: Props): JSX.Element {
    const { projectLinks, toggleDialogState, currentLanguage } = useAppStateContext();
    return (
        <AnimatePresence>
            {props.showDropdown && (
                <LinkDropdownWrapper variants={DropdownVariants} initial="hidden" animate="visible" exit="hidden">
                    {/*<div /> optional filter for dropdowns */}
                    {projectLinks &&
                        projectLinks.map((project) => (
                            <span key={project.projectID} onClick={() => toggleDialogState(project.projectID, false)}>
                                {project.projectShort}
                                {/* {currentLanguage === Languages.EN
                  ? project.projectNameEn
                  : project.projectNameSi} */}
                            </span>
                        ))}
                </LinkDropdownWrapper>
            )}
        </AnimatePresence>
    );
}

function LanguageDropdown(props: Props): JSX.Element {
    const { currentLanguage, toggleLanugage } = useAppStateContext();
    return (
        <AnimatePresence>
            {props.showDropdown && (
                <LinkDropdownWrapper variants={DropdownVariants} initial="hidden" animate="visible" exit="hidden">
                    {/* <div /> optional filter for dropdowns */}
                    <span key={currentLanguage} onClick={() => toggleLanugage()}>
                        {currentLanguage === Languages.EN ? 'SI' : 'EN'}
                    </span>
                </LinkDropdownWrapper>
            )}
        </AnimatePresence>
    );
}

const HeaderLinksWrapper = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
`;

const LinkWrapper = styled.a`
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    transition: all 0.2s ease-in;

    text-decoration: none;

    & span:first-child {
        cursor: pointer;
        /*     padding: 10px 0; */
        color: #ffffff;
        transition: all 0.2s ease-in;
    }

    &:hover span:first-child {
        color: #c2d5ed;
        transition: all 0.2s ease-in;
    }

    &:active span:first-child {
        transform: scale(0.95);
    }
`;

export const LinkDivider = styled.span`
    color: #ffffff;
    font-size: 23px;
    background-color: #ffffff;
    height: 24px;
    width: 1px;
    transform: rotate(20deg);
`;

const LanguageWrapper = styled.button`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c2d5ed;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease-in, color 0.2s linear;
`;

const LinkDropdownWrapper = styled(motion.div)`
    background-color: #000000;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transform: translateX(-50%);
    transition: transform 0.1s ease-in-out;
    position: absolute;
    padding-top: 25px;
    padding-bottom: 10px;
    top: calc(100%);
    left: 50%;
    min-width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;

    & div {
        background: #4373af;
        word-break: 100%;
        height: 110%;
        width: 60%;
        position: absolute;
        z-index: -1;
        filter: blur(60px);
    }

    // & span:nth-child(even) {
    //   color: #c2d5ed;
    //   transition: transform 0.1s ease-in-out;
    // }

    & span {
        margin-bottom: 12px;
        white-space: nowrap;
        transition: transform 0.1s ease-in-out;
    }

    & span:last-child {
        margin-bottom: 0;
    }

    & span:hover {
        // transform: scale(1.1);
        color: #c2d5ed;
        transition: all 0.1s ease-in-out;
    }
`;
