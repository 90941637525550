import React, { ChangeEvent, useState } from 'react';
import { useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import InputButtonIcon from '../../assets/images/common/InputButtonIcon.svg'
import useTranslate from '../../contexts/Translate/TranslateContext';
import axios, { CancelTokenSource } from 'axios';
import { ERROR_VALUES } from '../../services/types';
import useTheme from '../../contexts/Theme/ThemeContext';
import { getAudioUrlForText } from '../../services/ttsService';

const HeaderInput = () => {

  const { isMobile, isTablet } = useAppStateContext();
  const { selectedLanguageValues } = useTranslate();
  const { colorSchemeValues } = useTheme();
  const audioRef = useRef<HTMLAudioElement>(null);

  const [inputValue, setInputValue] = useState<string>("");
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const setValidInput = () => setIsInputValid(true)
  const setInvalidInput = () => setIsInputValid(false)
  const [errorText, setErrorText] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const cancelToken = useRef<CancelTokenSource>(axios.CancelToken.source());

  const charLimit = 50;
  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > charLimit) {
      setInvalidInput();
      setErrorText(selectedLanguageValues.jurcekError.toLongText)
      return;
    } else if (event.target.value.length === 0) {
      setInvalidInput();
      setInputValue(event.target.value)
      return;
    }
    setInputValue(event.target.value)
    setValidInput()
  }

  const checkFinalPunctuation = () => inputValue.endsWith('.') || inputValue.endsWith('?') || inputValue.endsWith('!') ? undefined : inputValue + "."

  const resetFetchingStates = () => {
    setInputValue("");
    setFetching(false);
  }


  const onClick = async () => {
    const finalPunctuationResponse = checkFinalPunctuation();
    const finalInputValue = finalPunctuationResponse ? finalPunctuationResponse : inputValue

    setFetching(true);

    try {
      const response = await getAudioUrlForText(finalInputValue, cancelToken.current);

      if (response === ERROR_VALUES.Canceled) {
        cancelToken.current = axios.CancelToken.source();
        resetFetchingStates()
        setInvalidInput()
      } else if (response === ERROR_VALUES.Common) {
        setErrorText(selectedLanguageValues.jurcekError.genericError)
        setInvalidInput()
        resetFetchingStates();
      } else if (response === ERROR_VALUES.Too_many_requests) {
        setErrorText(selectedLanguageValues.jurcekError.tooManyRequestsError);
        setInvalidInput()
        resetFetchingStates();
      } else {
        setFetching(false);
        setPlaying(true);
        if (audioRef.current) {
          audioRef.current.src = response;
          audioRef.current.play();
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const resetPlayingStates = () => {
    setInputValue("");
    setPlaying(false);
    setInvalidInput()
  }

  const inputButtonHandler = () => {
    if (!isInputValid) return;
    if (!playing && !fetching) { onClick() }
    else if (!playing && fetching) { cancelToken.current.cancel(ERROR_VALUES.Canceled) }
    else if (playing && !fetching && playing) { resetPlayingStates() }
  }

  return (
    <>
      {playing && <audio onEnded={resetPlayingStates} ref={audioRef}>
        Your browser does not support the audio element.
      </audio>
      }
      <Wrapper isSmaller={isMobile || isTablet}>
        <InputWrapper isSmaller={isMobile || isTablet}>
          <InputProper ref={inputRef} value={inputValue} onChange={onTextChange} placeholder={selectedLanguageValues.header.inputPlaceholder} />
          <InputButton  fetching={fetching} playing={playing} onClick={inputButtonHandler} backgroundColor={isInputValid ? colorSchemeValues.extraordinary : colorSchemeValues.invalidButtonColor} isMobile={isMobile} isTablet={isTablet}>
            {!fetching && !playing && (
              <MicrophoneImage isSmaller={isTablet || isMobile} src={InputButtonIcon} alt="Submit" />
            )}
            {fetching && (
              <DotLoading>
                <Dot isSmaller={isMobile || isTablet} />
                <Dot isSmaller={isMobile || isTablet} />
                <Dot isSmaller={isMobile || isTablet} />
              </DotLoading>
            )}
            {playing && <LoadingSquare />}
            {!isInputValid && inputValue.length > 0 && <HintSpan><p>{errorText}</p></HintSpan>}
          </InputButton>

        </InputWrapper>
      </Wrapper>
    </>
  )
}

export default HeaderInput;
const Wrapper = styled.div<{ isSmaller: boolean | undefined }>`
  background-color: #FFFFFF80;
  padding: ${props => props.isSmaller ? "3" : "7"}px;
  border-radius: 50px;
`
const InputWrapper = styled.div<{ isSmaller: boolean | undefined }>`
  border: 1px solid #2222221A;
  display: flex;
  flex-direction: row;
  padding: ${props => props.isSmaller ? "5px 5px 5px 14px" : "7px 7px 7px 28px"};
  border-radius: 50px;
  background-color: #FFFFFF;
`

const InputProper = styled.input`
  font-family: Roboto;
  flex: 5;
  border: none;
  font-size: 15px;
  line-height: 22.5px;
  background-color: transparent;

  &:focus {
    outline-width: 0;
  }
`

const bgAnimation = keyframes`
  from {
    background-color: #55759C;
  }
  to {
    background-color: #6e98cc;
  }
`

const HintSpan = styled.span`
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100%;
  background-color: black;
  color: #fff;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  //left tooltip
  right: 105%;


  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  & p {
    color: #FFF;
    font-weight: bold;
  }
`

const animationCss = css`
  animation: ${bgAnimation} 1s infinite alternate;
`
const InputButton = styled.button<{ fetching: boolean, playing: boolean, backgroundColor: string, isTablet: boolean | undefined, isMobile: boolean | undefined }>`
  position: relative;
  ${props => props.fetching || props.playing ? animationCss : null}
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  width: ${props => props.isMobile ? "28" : props.isTablet ? "28" : "52"}px;
  height: ${props => props.isMobile ? "28" : props.isTablet ? "28" : "52"}px;
  display: flex;
  align-items: center;
  ${props => !props.fetching ? "justify-content: center;" : ""}

  cursor: pointer;
  &:hover ${HintSpan} {
    visibility: visible;
 }
`

const LoadingSquare = styled.div`
  position: absolute;
  width: 30%;
  height: 30%;
  background-color: white;
`


const dotAnimate = keyframes`
0% {
  opacity: .4;
  transform: scale(1, 1);
}

50% {
  opacity: 1;
  transform: scale(1.2, 1.2);
}

100% {
  opacity: .4;
  transform: scale(1, 1);
}
`

const Dot = styled.div<{ isSmaller: boolean | undefined }>`
  animation: ${dotAnimate} 1.5s infinite ease-in-out;
  background-color: #fff;
  border-radius: 50%;
  height: ${props => props.isSmaller ? "3" : "6"}px;
  width: ${props => props.isSmaller ? "3" : "6"}px;
  
  &:nth-child(2) {
    margin-left: ${props => props.isSmaller ? "3" : "7"}px;
    margin-right: ${props => props.isSmaller ? "3" : "7"}px;
    animation-delay: .5s;
  }
  
  &:nth-child(3) {
    animation-delay: 1s;
  }
`

const DotLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const MicrophoneImage = styled.img<{ isSmaller: boolean | undefined }>`
  width: ${props => props.isSmaller ? "12" : "24"}px;
  height: ${props => props.isSmaller ? "12" : "24"}px;
`