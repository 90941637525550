import styled from "styled-components";

const CategoryProductsTitle = styled.h2<{isSmaller: boolean | undefined}>`
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.isSmaller ? "20" : "24"}px;
  line-height: ${props => props.isSmaller ? "26" : "31"}px;
  color: #000000;
`

export default CategoryProductsTitle;
