import React from 'react';

import styled from 'styled-components';

import { useAppStateContext } from '../../Contexts/AppStateProvider';
import { Languages } from '../../Contexts/types';

import vitaBitsImg from '../../assets/images/products/Vistasis_A.png';
import truebarImg from '../../assets/images/products/Vistasis_B.png';
import { useDimensions } from '../../hooks/useDimensions';

import { firstProductSectionText, secondProductSectionText, appTitles } from '../../data/texts';
import { Link } from 'react-router-dom';

interface Product {
    imageUrl?: string;
    productTitle: string;
    productDesc: string;
    productWebLink?: string;
}

interface ProductSectionProps {
    products?: Product[];
}

interface ProductCardProps {
    imageUrl?: string;
    productTitle: string;
    productDesc: string;
    productWebLink?: string;
}

const cardBreakingPoints = {
    mobile: 375,
};

export default function ProductsSection({ products }: ProductSectionProps): JSX.Element {
    const { isMobile, currentLanguage } = useAppStateContext();
    const { ref, dimensions } = useDimensions();
    return (
        <ProductsSectionWrapper isMobile={isMobile} id="products">
            <ProductsSectionContent ref={ref}>
                {currentLanguage === Languages.SI && (
                    <Title resizeTitle={dimensions.width <= 550}>{appTitles.productsAndProjectTitle.si}</Title>
                )}
                {currentLanguage === Languages.EN && (
                    <Title resizeTitle={dimensions.width <= 550}>{appTitles.productsAndProjectTitle.en}</Title>
                )}

                <ProductCardsWrapper>
                    {currentLanguage === Languages.SI && (
                        <>
                            <ProductCard
                                imageUrl={vitaBitsImg}
                                productTitle={firstProductSectionText.productTitle.si}
                                productDesc={firstProductSectionText.productDesc.si}
                                productWebLink={firstProductSectionText.productWebsiteLink.si}
                            />

                            <ProductCard
                                imageUrl={truebarImg}
                                productTitle={secondProductSectionText.productTitle.si}
                                productDesc={secondProductSectionText.productDesc.si}
                                productWebLink={secondProductSectionText.productWebsiteLink.si}
                            />
                        </>
                    )}
                    {currentLanguage === Languages.EN && (
                        <>
                            <ProductCard
                                imageUrl={vitaBitsImg}
                                productTitle={firstProductSectionText.productTitle.en}
                                productDesc={firstProductSectionText.productDesc.en}
                                productWebLink={firstProductSectionText.productWebsiteLink.en}
                            />

                            <ProductCard
                                imageUrl={truebarImg}
                                productTitle={secondProductSectionText.productTitle.en}
                                productDesc={secondProductSectionText.productDesc.en}
                                productWebLink={secondProductSectionText.productWebsiteLink.en}
                            />
                        </>
                    )}
                </ProductCardsWrapper>
            </ProductsSectionContent>
        </ProductsSectionWrapper>
    );
}

function ProductCard({ imageUrl, productTitle, productDesc, productWebLink }: ProductCardProps) {
    const { ref, dimensions } = useDimensions();
    // const { currentLanguage } = useAppStateContext();
    return (
        <ProductCardWrapper ref={ref}>
            {productTitle === 'Truebar' ? (
                <Link to={productWebLink || 'product/truebar'}>
                    <ProductImage src={imageUrl} alt="product-image" />
                </Link>
            ) : (
                <a href={productWebLink} target="_blank" rel="noopener noreferrer">
                    <ProductImage src={imageUrl} alt="product-image" />
                </a>
            )}
            <ProductCardInfo mobileVersion={dimensions.width <= cardBreakingPoints.mobile}>
                {productTitle === 'Truebar' ? (
                    <ProductTitleLinkRouter to={productWebLink || 'product/truebar'}>
                        <h5>{productTitle}</h5>
                    </ProductTitleLinkRouter>
                ) : (
                    <ProductTitleLink href={productWebLink} target="_blank" rel="noopener noreferrer">
                        <h5>{productTitle}</h5>
                    </ProductTitleLink>
                )}
                <BottomWrapp>
                    <p>{productDesc}</p>
                    {/* <a href={productWebLink} target="_blank" rel="noopener noreferrer">
            {currentLanguage === Languages.SI && (
              <p>{firstProductSectionText.productWebsite.si}</p>
            )}
            {currentLanguage === Languages.EN && (
              <p>{firstProductSectionText.productWebsite.en}</p>
            )}
          </a> */}
                </BottomWrapp>
            </ProductCardInfo>
        </ProductCardWrapper>
    );
}

const ProductsSectionWrapper = styled.div<{ isMobile: boolean | undefined }>`
    width: 100%;
    min-height: 900px;
    background-color: #0e0e0e;
    padding: ${(props): string => (props.isMobile ? '70px 20px 110px 20px' : '70px 3vw 80px 3vw')};
`;

const ProductsSectionContent = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0px auto;
`;

const Title = styled.h1<{ resizeTitle: boolean | undefined }>`
    font-family: Roboto;
    font-size: ${(props) => (props.resizeTitle ? '28px' : '36px')};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    padding: 0;
`;
const ProductCardsWrapper = styled.div`
    width: 100%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
    gap: 55px;

    @media (max-width: 850px) {
        gap: 25px;
    }

    @media (max-width: 490px) {
        grid-template-columns: 1fr;
    }
`;

const ProductCardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 660px;
`;

const ProductImage = styled.img`
    width: 100%;
`;

const ProductCardInfo = styled.div<{ mobileVersion: boolean }>`
    width: 100%;
    height: 100%;
    padding: ${(props) => (props.mobileVersion ? '50px 30px 20px' : '46px 52px 40px')};
    background-color: #000000;
    display: flex;
    flex-direction: column;

    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #ffffff;

    & h5 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 55px;
    }

    & p {
        font-size: 17px;
        margin: 0;
        padding: 0;
    }

    & a {
        color: #c2d5ed;
        cursor: pointer;
    }
`;

const BottomWrapp = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
`;

const ProductTitleLink = styled.a`
    text-decoration: none;
    color: #ffffff !important;
    transition: color 200ms ease-in-out;

    &:hover {
        color: #c2d5ed !important;
        transition: color 200ms ease-in-out;
    }
`;

const ProductTitleLinkRouter = styled(Link)`
    text-decoration: none;
    color: #ffffff !important;
    transition: color 200ms ease-in-out;

    &:hover {
        color: #c2d5ed !important;
        transition: color 200ms ease-in-out;
    }
`;
