import { Category } from "../types"
import Govornik from '../../assets/images/common/Govornik.png';
import Klepetalnik from '../../assets/images/common/Klepetalnik.png';
import Narekovalnik_prepisovanje_v_živo from '../../assets/images/common/Narekovalnik_prepisovanje_v_zivo.png';
import Prevajalnik from '../../assets/images/common/Prevajalnik.png';
import Tekstovni_prevajalnik from '../../assets/images/common/Tekstovni_prevajalnik.png';
import TVpodnaslovljalnik from '../../assets/images/common/TVpodnaslovljalnik.png';
import Urejevalnik_prepisov from '../../assets/images/common/Urejevalnik_prepisov.png';
import ZOOM from '../../assets/images/common/ZOOM.png';
import { AllInOneTranslate, CategoryTranslate, CommonTranslates, Companies, DaysTranslate, ErrorTranslates, Header, LegalPagesTranslates, Product, ProductCategory, SubHeader, SupportTranslates } from "./types";

export interface Translates {
  days: DaysTranslate;
  header: Header,
  subheader: SubHeader,
  companies: Companies,
  categoryTranslate: CategoryTranslate,
  recognitionTranslates: ProductCategory,
  synthesisTranslates: ProductCategory,
  translateTranslates: ProductCategory,
  productTranslate: Product[],
  allInOneTranslate: AllInOneTranslate;
  common: CommonTranslates;
  jurcekError: ErrorTranslates;
  supportTranslates: SupportTranslates;
  legalPages: LegalPagesTranslates;
}



/*SLOVENIAN START*/
export const slovenianTranslates: Translates = {
  common: {
    openVideo: "Poglej video",
    contactUs: 'Kontaktirajte nas',
    closed: "Zaprto",
    workingHours: 'Delovne ure',
    contact: 'Kontakt'
  },
  jurcekError: {
    tooManyRequestsError: 'Uporaba demo produkta je omejena. Presegli ste dovoljeno število poizkusov. Poizkusite kasneje.',
    genericError: 'Se opravičujemo. Očitno je prišlo do težave.',
    toLongText: 'Vnos je omejen na 50 znakov',
  },
  header: {
    title: "Napiši nekaj...",
    inputPlaceholder: "Besedilo...",
  },
  subheader: {
    firstTitle: "Oče slovenske govorne tehnologije.",
    firstDescription: "Nič več napačnih prevodov, angleške izgovorjave slovenskih besed in naprav, ki ne razumejo, kaj jim narekujete.",
    secondTitle: "Popolnoma optimiziran za slovenski jezik",
    secondDescription: "Truebar je stoodstotno optimiziran za slovenski jezik. Tehnologija omogoča zapisovanje, sintetiziranje in prevajanje slovenskega jezika.",
  },
  companies: {
    title: "Podjetja, ki že uporabljajo Truebar"
  },
  categoryTranslate: {
    All: "Vse",
    Recognition: "Razpoznava",
    Synthesis: "Sinteza",
    Translate: "Prevajanje",
  },
  recognitionTranslates: {
    desription: "Razpoznavalnik govora zajeti zvok pretvori v zapisano besedilo. Uporaben je na mnogih področjih, na primer v zdravstvu, sodstvu ali avdiovizualnih medijih. Uporabljamo ga lahko v živo ali naložimo posnetek.",
    subtitle: "Naši produkti, ki temeljijo na tej tehnologiji",
    products: [
      "Narekovalnik",
      "Urejevalnik prepisov",
      "TV podnaslavljalnik",
      "Klepetalnik",
      "Prevajalnik",
      "Zoom podnaslavljalnik",
      "Tekstovni prevajalnik",
    ],
  },
  synthesisTranslates: {
    desription: "Sintetizatorju predložimo besedilo, ta pa ga na glas prebere. Uporaben je pri raznih programih in aplikacijah, ki vključujejo komunikacijo z uporabnikom.",
    subtitle: "Naši produkti, ki temeljijo na tej tehnologiji",
    products: [
      "Klepetalnik",
      "Prevajalnik",
      "Govornik",
    ],
  },
  translateTranslates: {
    desription: "Prevajalniku povemo besedilo, ki ga želimo prevesti v angleščino. Prevajalnik razpozna naš govor in zapiše izrečeno besedilo, ki ga nato prevede v angleščino in prebere.",
    subtitle: "Naš produkt, ki temeljijo na tej tehnologiji",
    products: [
      "Prevajalnik",
    ],
  },
  productTranslate: [
    {
      title: "Narekovalnik",
      subtitle: "Prepisovanje v živo",
      description: "Narekovanje slovenskega jezika v živo, vključno z izrazi iz specifičnih domen (npr. zdravstvo, sodstvo).",
      categories: [Category.Recognition],
      url: "https://vitasis.si/video/narekovalnik.mp4",
      image: Narekovalnik_prepisovanje_v_živo
    },
    {
      title: "Urejevalnik prepisov",
      subtitle: "Nalaganje zvočnih posnetkov",
      description: "Omogoča nalaganje obstoječega zvočnega posnetka, iz katerega s pomočjo razpoznave govora nastane prepis, ki ga je mogoče urejati.",
      categories: [Category.Recognition],
      url: "https://vitasis.si/video/urejevalnik.mp4",
      image: Urejevalnik_prepisov
    },
    {
      title: "TV-podnaslavljalnik",
      subtitle: "",
      description: "Sprotno izdelovanje podnapisov gluhim olajša ogled TV-programov.",
      categories: [Category.Recognition],
      url: undefined,
      image: TVpodnaslovljalnik
    },
    {
      title: "Klepetalnik",
      subtitle: "",
      description: "Klepetalnik razpozna zastavljeno vprašanje in nanj odgovori.",
      categories: [Category.Recognition, Category.Synthesis],
      url: undefined,
      image: Klepetalnik
    },
    {
      title: "Prevajalnik",
      subtitle: "",
      description: "Razpozna slovenski govor, ga prevede v tuji jezik in izgovori.",
      categories: [Category.Recognition, Category.Synthesis, Category.Translate],
      url: undefined,
      image: Prevajalnik
    },
    {
      title: "Podnaslavljalnik platforme Zoom",
      subtitle: "",
      description: "Prikazovanje slovenskih podnapisov tekom (Zoom) videokonference v realnem času.",
      categories: [Category.Recognition],
      url: undefined,
      image: ZOOM
    },
    {
      title: "Govornik",
      subtitle: "",
      description: "Pretvori slovensko besedilo v naravno zveneč govor.",
      categories: [Category.Synthesis],
      url: undefined,
      image: Govornik
    },
    {
      title: "Tekstovni prevajalnik",
      subtitle: "",
      description: "Tekstovno prevajanje iz slovenskega jezika v tuji jezik.",
      categories: [Category.Recognition],
      url: undefined,
      image: Tekstovni_prevajalnik
    },
  ],
  allInOneTranslate: {
    titleOne: "ALL-IN-ONE",
    titleTwo: " TRUBAR ",
    titleThree: "REŠITVE",
    description: "All-in-one združuje različne govorne tehnologije, med drugim razpoznavalnik, ki govor pretvori v zapisano besedilo in ima celo možnost razpoznave govorca, in prevajalnik, ki izrečeno v slovenščini prevede in pove v angleščini. Poleg tega lahko poklepetamo tudi s klepetalnikom, ki temelji na razpoznavi in sintezi govora, ga kaj vprašamo, ta pa nam odgovori na vprašanja.",
    buttonText: "Pišite nam",
  },
  supportTranslates: {
    headerText: "Dobrodošli",
    subheaderText: "Kako vam lahko pomagamo?",
    writeToUs: "Pišite nam",
    writeToUsContent: "Imate težave z uporabo naših storitev? Pišite in pomagali vam bomo po svojih najboljših močeh.",
    writeToUsInteractionText: "Pošlji popraševanje",
    developers: "Razvijalci",
    developersContent: "Ste razvijalec in uporabljate naše storitve? Za vas smo pripravili dokumentacijo, ki vam bo pomagala pri razvoju.",
    developersInteractionText: "Pojdi",
  },
  legalPages: {
    termsOfUse: "Pogoji uporabe",
    privacyPolicy: "Politika zasebnosti",
    open: "ODPRI",
    close: "ZAPRI",
    download: "PRENESI",
  },
  days: {
    monday: "Ponedeljek",
    tuesday: "Torek",
    wednesday: "Sreda",
    thursday: "Četrtek",
    friday: "Petek",
    saturday: "Sobota",
    sunday: "Nedelja",
  }
}
/*SLOVENIAN END*/

/*ENGLISH START*/
export const englishTranslates: Translates = {
  common: {
    openVideo: "Open video",
    contactUs: 'Contact us',
    closed: "Closed",
    contact: 'Contact',
    workingHours: 'Working hours',
  },
  jurcekError: {
    tooManyRequestsError: 'Usage of demo product is limited. You have exceeded number of tries. Please try later.',
    genericError: 'Pardon us. It appears error occured.',
    toLongText: 'Input is limited to 50 characters.',
  },
  header: {
    title: "Write something...",
    inputPlaceholder: "Text...",
  },
  subheader: {
    firstTitle: "Father slovenske govorne tehnologije.",
    firstDescription: "Nothing več napačnih prevodov, angleške izgovorjave slovenskih besed in naprav, ki ne razumejo, kaj jim narekujete.",
    secondTitle: "100% optimized za slovenski jezik",
    secondDescription: "Truebar is popolnoma optimiziran za slovenski jezik. Tehnologija omogoča zapisovanje, sintetiziranje in prevajanje slovenskega jezika.",
  },
  companies: {
    title: "Companies using Truebar"
  },
  categoryTranslate: {
    All: "All",
    Recognition: "Recognition",
    Synthesis: "Synthesis",
    Translate: "Translation",
  },
  recognitionTranslates: {
    desription: "Razpoznavalnik govora zajeti zvok pretvori v zapisano besedilo. Uporaben je na mnogih področjih, na primer v zdravstvu, sodstvu ali avdiovizualnih medijih. Uporabljamo ga lahko v živo ali naložimo posnetek.",
    subtitle: "Naši produkti, ki bazirajo na tej tehnologiji",
    products: [
      "Narekovalnik",
      "Urejevalnik prepisov",
      "TV podnaslavljalnik",
      "Klepetalnik",
      "Prevajalnik",
      "Zoom podnaslavljalnik",
      "Tekstovni prevajalnik",
    ],
  },
  synthesisTranslates: {
    desription: "Sintetizatorju predložimo besedilo, ta pa ga na glas prebere. Uporaben je pri raznih programih in aplikacijah, ki vključujejo komunikacijo z uporabnikom.",
    subtitle: "Naši produkti, ki bazirajo na tej tehnologiji",
    products: [
      "Klepetalnik",
      "Prevajalnik",
      "Govornik",
    ],
  },
  translateTranslates: {
    desription: "Orodju povemo besedilo, ki ga želimo prevesti v angleščino. Prevajalnik razpozna naš govor in zapiše izrečeno besedilo, ki ga nato prevede v angleščino in prebere.",
    subtitle: "Naš produkt, ki bazira na tej tehnologiji",
    products: [
      "Prevajalnik",
    ],
  },
  productTranslate: [
    {
      title: "Dictation",
      subtitle: "Prepisovanje v živo",
      description: "Narekovanje slovenskega jezika v živo, vključno z izrazi iz specifičnih domen (npr. zdravstvo, sodstvo).",
      categories: [Category.Recognition],
      url: "https://vitasis.si/video/narekovalnik.mp4",
      image: Narekovalnik_prepisovanje_v_živo
    },
    {
      title: "Editor prepisov",
      subtitle: "Nalaganje zvočnih posnetkov",
      description: "Omogoča nalaganje obstoječega zvočnega posnetka iz katerega s pomočjo razpoznave govora nastane prepis, ki ga je mogoče urejati.",
      categories: [Category.Recognition],
      url: "https://vitasis.si/video/urejevalnik.mp4",
      image: Urejevalnik_prepisov
    },
    {
      title: "TV subtitler",
      subtitle: "",
      description: "Sprotno izdelovanje podnapisov gluhim olajša ogled TV programov.",
      categories: [Category.Recognition],
      url: undefined,
      image: TVpodnaslovljalnik
    },
    {
      title: "Chatter",
      subtitle: "",
      description: "Klepetalnik razpozna zastavljeno vprašanje in nanj (bolj ali manj) smiselno odgovori.",
      categories: [Category.Recognition, Category.Synthesis],
      url: undefined,
      image: Klepetalnik
    },
    {
      title: "Translator",
      subtitle: "",
      description: "Razpozna slovenski govor, ga prevede v tuj jezik in izgovori.",
      categories: [Category.Recognition, Category.Synthesis, Category.Translate],
      url: undefined,
      image: Prevajalnik
    },
    {
      title: "Zoom subtitler",
      subtitle: "",
      description: "Prikazovanje slovenskih podnapisov tekom (Zoom) videokonference v realnem času.",
      categories: [Category.Recognition],
      url: undefined,
      image: ZOOM
    },
    {
      title: "Speaker",
      subtitle: "",
      description: "Pretvori slovensko besedilo v naravno zveneč govor.",
      categories: [Category.Synthesis],
      url: undefined,
      image: Govornik
    },
    {
      title: "Text translator",
      subtitle: "",
      description: "Tekstovno prevajanje iz slovenskega jezika v tuj jezik. (podobno kot Google Translate)",
      categories: [Category.Recognition],
      url: undefined,
      image: Tekstovni_prevajalnik
    },
  ],
  allInOneTranslate: {
    titleOne: "ALL-IN-ONE",
    titleTwo: " TRUBAR ",
    titleThree: "SOLUTIONS",
    description: "All-in-one združuje različne govorne tehnologije. med drugim razpoznavalnik, ki govor pretvori v zapisano besedilo in ima celo možnost razpoznave govorca, in prevajalnik, ki izrečeno v slovenščini prevede in pove v angleščini. Poleg tega lahko poklepetamo tudi s klepetalnikom, ki temelji na razpoznavi in sintezi govora, ga kaj vprašamo, ta pa nam odgovori na vprašanja.",
    buttonText: "Contact us",
  },
  supportTranslates: {
    headerText: "Welcome",
    subheaderText: "How can we help you?",
    writeToUs: "Write to us",
    writeToUsContent: "Are you having problems using our services? Write to us and we will help you.",
    writeToUsInteractionText: "Send inquiry",
    developers: "Developers",
    developersContent: "Are you a developer using our services? We have documentation available, which guides you trough development process.",
    developersInteractionText: "Go",
  },
  legalPages: {
    termsOfUse: "Terms of use",
    privacyPolicy: "Privacy policy",
    open: "OPEN",
    close: "CLOSE",
    download: "DOWNLOAD",
  },
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  }
}
/*ENGLISH END*/