import { ColorSchemeValues } from "./types"

export const businessTheme: ColorSchemeValues = {
  headerBackground: '#D6E8FF',
  subHeaderBackground: '#F5F5F5',
  extraordinary: '#55759C',
  recognitionProducts: '#55759C',
  synthesisProducts: '#B5D5FF',
  translateProducts: '#D6E8FF',
  allProducts: '#2C2C2C',
  invalidButtonColor: '#B8B8B8',
}

export const funkyTheme: ColorSchemeValues = {
  headerBackground: '#BCE2EB',
  subHeaderBackground: '#F5F5F5',
  extraordinary: '#E63A28',
  recognitionProducts: '#4DB9D3',
  synthesisProducts: '#FFB703',
  translateProducts: '#8CBB8B',
  allProducts: '#2C2C2C',
  invalidButtonColor: '#B8B8B8'
}