import React, { FC } from 'react';
import styled from 'styled-components';
import Card from '../../components/molecules/Card';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import { Product } from '../../contexts/Translate/types';

interface Props {
  products: Product[]
}

const ProductsGrid: FC<Props> = ({products}) => {
  const { isMobile } = useAppStateContext()

  return (
    <Grid isMobile={isMobile}>
      {products.map((product, index) => <Card
                                          key={index}
                                          product={product} />
      )}
    </Grid>
  )
}

export default ProductsGrid;

const Grid = styled.div<{ isMobile: boolean | undefined }>`
  display: grid;
  grid-template-columns: repeat(${props => props.isMobile ? "1" : "2"}, 1fr);
  grid-column-gap: ${props => props.isMobile ? "24" : "20"}px;
  grid-row-gap: 20px;
  grid-auto-rows: minmax(min-content, max-content);
`