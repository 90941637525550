import React from 'react';
import CategoryContainer, { CategoryKind } from '../components/organisms/CategoryContainer';
import { ColorSchemes } from '../contexts/Theme/types';
import useTheme from '../contexts/Theme/ThemeContext';

import TranslationFunky from '../assets/images/funky/Translation.png';
import TranslationBusiness from '../assets/images/business/Translation.png';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../Contexts/AppStateProvider';
import useTranslate from '../contexts/Translate/TranslateContext';

const Translation = () => {

  const { colorScheme } = useTheme()
  const { isMobile } = useAppStateContext()
  const {selectedLanguageValues} = useTranslate();


  return (
    <TranslationContainer isMobile={isMobile}>
      <TranslationContent isMobile={isMobile}>
        <CategoryContainer
                  categoryKind={CategoryKind.TextLeftPlaced}
                  image={colorScheme === ColorSchemes.Funky ? TranslationFunky : TranslationBusiness}
                  categoryTitle={selectedLanguageValues.categoryTranslate.Translate}
                  categoryDescription={selectedLanguageValues.translateTranslates.desription}
                  categoryProductsTitle={selectedLanguageValues.translateTranslates.subtitle}
                  categoryProductsList={selectedLanguageValues.translateTranslates.products}/>
      </TranslationContent>
    </TranslationContainer>
  )
}

export default Translation;

const TranslationContainer = styled.div<{isMobile: boolean | undefined}>`
  min-height: 676px;  
  display: flex;
  justify-content: center;
  align-items: center;  
  padding: ${props => props.isMobile ? "40px 32px" : "100px 5vw"};
  width: 100%;
`

const TranslationContent = styled.div<{isMobile: boolean | undefined}>`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
  align-items: center;
  justify-content: space-between;
`