import styled from 'styled-components';

const AllInOneTitle = styled.h2<{isTablet: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: ${props => props.isTablet ? "24" : "35"}px;
  line-height: ${props => props.isTablet ? "36" : "52"}px;
  text-align: center;
`

export const AllInOneTitleColored = styled.span<{color: string}>`
  color: ${props => props.color};
`
export const AllInOneTitleBlack = styled.span`
  color: #2C2C2C;
`

export default AllInOneTitle;