import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../Contexts/AppStateProvider';
import CategoryContainer, { CategoryKind } from '../components/organisms/CategoryContainer';
import useTheme from '../contexts/Theme/ThemeContext';
import { ColorSchemes } from '../contexts/Theme/types';

import SynthesisFunky from '../assets/images/funky/Synthesis.png';
import SynthesisBusiness from '../assets/images/business/Synthesis.png';
import useTranslate from '../contexts/Translate/TranslateContext';

const Synthesis = () => {

  const { isMobile } = useAppStateContext()
  const { colorSchemeValues, colorScheme } = useTheme()
  
  const {selectedLanguageValues} = useTranslate();

  return (
    <ElipseContainer isMobile={isMobile} backgroundColor={colorSchemeValues.subHeaderBackground} relativeBackgroundColor={'#fff'}>
      <ElipseContent isMobile={isMobile}>
        <CategoryContainer
                  categoryKind={CategoryKind.TextRightPlaced}
                  image={colorScheme === ColorSchemes.Funky ? SynthesisFunky : SynthesisBusiness}
                  categoryTitle={selectedLanguageValues.categoryTranslate.Synthesis}
                  categoryDescription={selectedLanguageValues.synthesisTranslates.desription}
                  categoryProductsTitle={selectedLanguageValues.synthesisTranslates.subtitle}
                  categoryProductsList={selectedLanguageValues.synthesisTranslates.products}/>
      </ElipseContent>
    </ElipseContainer>
  )
}

export default Synthesis;

const ElipseContainer = styled.div<{ isMobile: boolean | undefined, backgroundColor: string, relativeBackgroundColor: string }>`
    position: relative;
    width: 100%;
    min-height: 676px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${props => props.relativeBackgroundColor};
    padding: ${props => props.isMobile ? "40px 32px" : "100px 3vw"};

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.backgroundColor};
        border-radius: 100% / 10%;
        transform: scaleX(${props => props.isMobile ? "1" : "1"});
    }
`

const ElipseContent = styled.div<{isMobile: boolean | undefined}>`
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    align-items: center;
    justify-content: space-between;
`