import { useEffect } from 'react';
import ReactGa from 'react-ga';

// We are using https://github.com/react-ga/react-ga for GA.

function useGoogleAnalytics(gaId: string | undefined): void {
    useEffect(() => {
        ReactGa.initialize(gaId ? gaId : '', {
            debug: true,
        });
        ReactGa.pageview('/');
    }, [gaId]);
}

export default useGoogleAnalytics;
