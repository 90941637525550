import React from 'react';
import styled from 'styled-components';
import useTheme from '../../contexts/Theme/ThemeContext';
import { ColorSchemes } from '../../contexts/Theme/types';
import HeaderText from '../molecules/HeaderText';
import HeaderFunky from '../../assets/images/funky/Header.png';
import HeaderBusiness from '../../assets/images/business/Header.png';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';

const HeaderContent = () => {
  const { colorScheme } = useTheme()
  const { isMobile, isTablet } = useAppStateContext();

  return (

    <CurvedHeaderContent isMobile={isMobile}>

      {isMobile && (
        <ImageWrapper isMobile={isMobile}>
          <MobileImage alt="header" src={colorScheme === ColorSchemes.Funky ? HeaderFunky : HeaderBusiness}  />
        </ImageWrapper>
      )}
      <TextWrapper isTablet={isTablet} isMobile={isMobile}>
        <HeaderText />
      </TextWrapper>
      {!isMobile && (
        <ImageWrapper isMobile={isMobile}>
          <img alt="header" src={colorScheme === ColorSchemes.Funky ? HeaderFunky : HeaderBusiness} width='100%' />
        </ImageWrapper>
      )}
    </CurvedHeaderContent>
  )
}

export default HeaderContent;

const CurvedHeaderContent = styled.div<{ isMobile: boolean | undefined }>`
    position: relative;
    z-index: 1;
    margin: 0 ${props => props.isMobile ? "0" : "auto"};
    max-width: 1400px;
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : 'row'};
    justify-content: ${props => props.isMobile ? "center" : "space-between"};
`

const TextWrapper = styled.div<{isTablet: boolean | undefined, isMobile: boolean | undefined}>`
  flex: 1;
  display: flex;
  align-items: ${props => props.isTablet ? 'flex-end' : 'center'};
  justify-content: ${props => props.isMobile ? "center" : "flex-start"};
  padding: ${props => props.isMobile ? "20px 30px 140px 30px" : "0"};
  ${props => props.isMobile || props.isTablet ? "" : "margin-top: 100px;"}
`

const ImageWrapper = styled.div<{isMobile: boolean | undefined}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.isMobile ? "89px 16px 2px 61px" : "0"}
`

const MobileImage = styled.img`
  max-width: 350px;
  width: 100%;
`