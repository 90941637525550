import React from "react";
import { Link } from "react-router-dom";
import Prismic from "prismic-javascript";
import { apiEndpoint, accessToken, linkResolver } from "./prismic-config";
import { /* RichText, */ Elements } from "prismic-reactjs";

import styled from "styled-components";

// Helper function to convert Prismic Rich Text links to React Link components
export const customLink = (type, element, content, children, index) => (
  <Link to={linkResolver(element.data)} key={index}>
    {content}
  </Link>
);

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken });

// HTML serializer for RichTextField in Prismic
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

export const htmlSerializer = (type, element, content, children, key) => {
  var props = {};
  switch (type) {
    // Returns hyperlinks as a elements
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : { target: "_blank" };
      const relAttr = element.data.target ? { rel: "noopener" } : {};
      props = Object.assign(
        {
          href: element.data.url || linkResolver(element.data),
          style: { color: "#000000" },
        },
        targetAttr,
        relAttr
      );
      //return React.createElement("a", propsWithUniqueKey(props, key), children);
      return (
        <LinkStyled href={props.href} rel="noopener" target="_blank" key={key}>
          {children}
        </LinkStyled>
      );

    case Elements.paragraph: // Paragraph
      props = {
        style: {
          margin: "0",
          padding: "0",
          marginBottom: "10px",
          fontFamily: "Roboto",
          fontSize: "15px",
          textAlign: "left",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "20px",
          letterSpacing: "normal",
          color: "#000000",
          maxWidth: "100%",
        },
      };
      return React.createElement("p", propsWithUniqueKey(props, key), children);

    case Elements.preformatted: // Preformatted
      props = { style: { marginBottom: "35px" } };
      const customHTML = React.createElement("div", {
        dangerouslySetInnerHTML: { __html: element.text },
      });
      return React.createElement(
        "div",
        propsWithUniqueKey(props, key),
        customHTML
      );

    case Elements.image: // IMAGE
      return (
        <ImageWrapper key={element.alt}>
          <StyledImage src={element.url} alt={element.alt || ""} key={key} />
          <ImageText>{element.alt}</ImageText>
        </ImageWrapper>
      );

    case Elements.heading1: // Heading 1
      props = {
        style: {
          margin: "0",
          marginTop: "15px",
          marginBottom: "15px",
          fontFamily: "Roboto",
          fontSize: "30px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.4",
          letterSpacing: "normal",
          color: "#000",
          maxWidth: "100%",
        },
      };
      return React.createElement(
        "h1",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading2: // Heading 2
      props = {
        style: {
          margin: "0",
          marginTop: "15px",
          marginBottom: "15px",
          fontFamily: "Roboto",
          fontSize: "24px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.4",
          letterSpacing: "normal",
          color: "#000",
          maxWidth: "100%",
        },
      };
      return React.createElement(
        "h2",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.heading5: // Heading 5
      props = {
        style: {
          margin: "0",
          marginTop: "5px",
          fontFamily: "Roboto",
          fontSize: "17px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.4",
          letterSpacing: "normal",
          color: "#000",
          maxWidth: "100%",
        },
      };
      return React.createElement(
        "h5",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.listItem: // Unordered List Item
      props = {
        style: {
          margin: "0",
          padding: "0",
          marginBottom: "10px",
          maxWidth: "100%",
        },
      };

      return React.createElement(
        "li",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.oListItem: // Ordered List Item
      props = {
        style: {
          margin: "0",
          padding: "0",
          marginBottom: "10px",
          maxWidth: "100%",
        },
      };
      return React.createElement(
        "li",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.list: // Unordered List
      props = {
        style: {
          listStyle: "disc",
          padding: "0",
          margin: "0",
          paddingLeft: "40px",
          fontFamily: "Roboto",
          fontWeight: "normal",
          fontSize: "15px",
          textAlign: "left",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "22px",
          letterSpacing: "normal",
          color: "#000000",
          maxWidth: "100%",
          /*   backgroundColor: "rgba(8%, 8%, 8%, 0.1)", */
          /*   borderRadius: "15px", */
        },
      };
      return React.createElement(
        "ul",
        propsWithUniqueKey(props, key),
        children
      );

    case Elements.oList: // Ordered List
      props = {
        style: {
          listStyle: "decimal",
          padding: "0",
          margin: "0",
          marginBottom: "10px",
          paddingLeft: "20px",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "28px",
          letterSpacing: "normal",
          color: "#000000",
          maxWidth: "100%",
          /* backgroundColor: "rgba(8%, 8%, 8%, 0.1)", */
          /*  borderRadius: "15px", */
        },
      };
      return React.createElement(
        "ol",
        propsWithUniqueKey(props, key),
        children
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

const LinkStyled = styled.a`
  color: #4373af;
  margin-left: 3px;

  maxwidth: "100%";
`;

const ImageWrapper = styled.div`
  width: 100%;
  margin: 15px 0;
  maxwidth: "100%";
`;

const StyledImage = styled.img`
  width: 100%;
`;

const ImageText = styled.p`
  font-family: Roboto;
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgb(0, 0, 0);

  maxWidth: '100%',

  margin-top: 3px;
`;
