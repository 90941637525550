import React, { FC } from "react"
import styled from "styled-components"
import { useAppStateContext } from "../../../../../Contexts/AppStateProvider"
import SubHeaderDescription from "../atoms/SubHeaderDescription"
import SubHeaderTitle from "../atoms/SubHeaderTitle"

interface Props {
  header: string;
  description: string;
}

const SubHeaderText: FC<Props> = ({header, description}) => {
  
  const { isMobile, isTablet } = useAppStateContext()
  
  return (
    <SubHeaderTextContainer>
      <SubHeaderTitle isMobile={isMobile} isTablet={isTablet}>{header}</SubHeaderTitle>
      <SubHeaderDescription isMobile={isMobile} isTablet={isTablet}>{description}</SubHeaderDescription>
    </SubHeaderTextContainer>
  )
}

export default SubHeaderText

export const SubHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
`