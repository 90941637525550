import React, { FC } from "react";
import styled from "styled-components";
import useTheme from "../../../contexts/Theme/ThemeContext";
import useTranslate from "../../../contexts/Translate/TranslateContext";
import { Category } from "../../../contexts/types";
import { CardCategoryContainer } from "../../molecules/Card";

interface Props {
  category: Category
}

const CardCategory: FC<Props> = ({category}) => {

  const { getCategoryColor } = useTheme();
  const { translateCategory } = useTranslate()
  return (
    <CardCategoryContainer backgroundColor={getCategoryColor(category)}>
      <CardCategoryText>{translateCategory(category).toUpperCase()}</CardCategoryText>
    </CardCategoryContainer>
  )
}


const CardCategoryText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
  margin: 0;
  text-align: center;
`
export default CardCategory;