import { useEffect, useState } from 'react';

import Prismic from 'prismic-javascript';
import { client } from '../prismic-config';

import { ProjectLink, InitLoadingRequests, News, Languages, Careers, TestimonialCard } from '../Contexts/types';
import moment from 'moment';
import { delay } from '../Contexts/AppStateProvider';

export default function useInitialFetch(currentLanguage: Languages): InitLoadingRequests {
    const [mainLoading, setMainLoading] = useState<boolean>(true);

    const [newsLoading, setNewsLoading] = useState<boolean>();
    const [projectsLoading, setProjectsLoading] = useState<boolean>();
    const [testimonialsLoading, setTestimonialsLoading] = useState<boolean>();
    const [partnersLoading, setPartnersLoading] = useState<boolean>();
    const [careersLoading, setCareersLoading] = useState<boolean>();

    const [showContent, setShowContent] = useState<boolean>(false);

    const [projectLinks, setProjectsLinks] = useState<ProjectLink[]>();
    const [lastNews, setLastNews] = useState<News[]>();
    const [ourPartners, setOurPartners] = useState<string[]>();
    const [careers, setCareers] = useState<Careers>();
    const [testimonials, setTestimonials] = useState<TestimonialCard[]>();

    const fetchProjectsInfo = async () => {
        setProjectsLoading(true);
        try {
            const response = await client.query(Prismic.Predicates.at('document.type', 'project'));
            setProjectsLoading(false);
            if (response) {
                if (response.results.length === 0) {
                    return;
                }
                let projects = response.results;

                const headerLinks: ProjectLink[] = [];
                projects.forEach((project, i) => {
                    headerLinks.push({
                        projectShort: project.data.project_short_name
                            ? project.data.project_short_name
                            : `Projekt ${i + 1}`,
                        projectNameSi: project.data.project_name_si ? project.data.project_name_si : `Projekt ${i + 1}`,
                        projectNameEn: project.data.project_name_en ? project.data.project_name_en : `Projekt ${i + 1}`,
                        projectID: project.id,
                    });
                });
                setProjectsLinks(headerLinks);
            }
        } catch (err) {
            setProjectsLoading(false);
            console.log(err);
        }
    };

    const fetchNews = async () => {
        setNewsLoading(true);
        try {
            const response = await client.query(Prismic.Predicates.at('document.type', 'news'));
            setNewsLoading(false);
            if (response) {
                if (response.results.length === 0) {
                    return;
                }
                const numOfNewsToShow = 4;
                const data = response.results.slice(0, numOfNewsToShow);
                let newNews: News[] = [];
                //const validUntill = moment(data[currentNews].exp_date).format("D.M.YYYY - HH:mm");
                //console.log(validUntill, "current:", moment().format("D.M.YYYY - HH:mm"));
                data.forEach((news) => {
                    if (moment(news.data.valid_untill) < moment()) {
                        return;
                    }

                    newNews.push({
                        title_en: news.data.news_title_en,
                        title_si: news.data.news_title_si,
                        date: news.data.news_date,
                        text_si: news.data.short_description_si,
                        text_en: news.data.short_description_en,
                        link: news.data.news_link.url,
                        exp_date: news.data.valid_untill,
                    });
                });
                newNews.reverse();
                setLastNews(newNews);
            }
        } catch (err) {
            setNewsLoading(false);
            console.log(err);
        }
    };

    const fetchCareersInfo = async () => {
        setCareersLoading(true);
        try {
            const response = await client.query(Prismic.Predicates.at('document.type', 'careers'));
            setCareersLoading(false);
            if (response) {
                if (response.results.length === 0) {
                    return;
                }
                const careersInfo = response.results[0];
                const careersInfoSi = careersInfo.data.careers_info_si;
                const careersInfoEn = careersInfo.data.careers_info_en;
                setCareers({
                    careers_info_en: careersInfoEn ? careersInfoEn : '',
                    careers_info_si: careersInfoSi ? careersInfoSi : '',
                });
            }
        } catch (err) {
            setCareersLoading(false);
            console.log(err);
        }
    };

    const fetchOurPartnersSection = async () => {
        setPartnersLoading(true);
        try {
            const response = await client.query(Prismic.Predicates.at('document.type', 'our_partners_section'));
            setPartnersLoading(false);
            if (response) {
                if (response.results.length === 0) {
                    return;
                }
                let logos = response.results[0].data.partners_logos;
                const newLogos: string[] = [];
                logos.forEach((logo: any) => {
                    newLogos.push(logo.partners_logo_image.url);
                });
                setOurPartners(newLogos);
            }
        } catch (err) {
            setPartnersLoading(false);
            console.log(err);
        }
    };

    const fetchTestimonials = async () => {
        setTestimonialsLoading(true);
        try {
            const response = await client.query(Prismic.Predicates.at('document.type', 'testimonial_card'));
            setTestimonialsLoading(false);
            if (response) {
                if (response.results.length === 0) {
                    return;
                }

                const data = response.results;

                let testimonialCards: TestimonialCard[] = [];
                data.forEach((testimonial, i) => {
                    const nameAndSurname = testimonial.data.name_and_surname;
                    const position = testimonial.data.position;
                    const company = testimonial.data.company;
                    const imageURL = testimonial.data.testimonial_card_image.url;
                    const testimonialTextEn = testimonial.data.testimonial_text_en;
                    const testimonialTextSi = testimonial.data.testimonial_text_si;
                    testimonialCards.push({
                        name_and_surname: nameAndSurname || '',
                        position: position || '',
                        company_name: company || '',
                        image_url: imageURL || '',
                        testimonial_text_en: testimonialTextEn || '',
                        testimonial_text_si: testimonialTextSi || '',
                    });
                });
                setTestimonials(testimonialCards);
            }
        } catch (err) {
            setTestimonialsLoading(false);
            console.log(err);
        }
    };

    useEffect(() => {
        fetchProjectsInfo();
        fetchNews();
        fetchCareersInfo();
        fetchOurPartnersSection();
        fetchTestimonials();
    }, []);

    useEffect(() => {
        delay(1800).then(() => {
            setShowContent(true);
        });
    }, []);

    useEffect(() => {
        if (
            !newsLoading &&
            !projectsLoading &&
            !testimonialsLoading &&
            !partnersLoading &&
            !careersLoading &&
            showContent
        ) {
            setMainLoading(false);
        }
    }, [careersLoading, newsLoading, partnersLoading, projectsLoading, testimonialsLoading, showContent]);

    return {
        mainLoading,
        projectLinks,
        careers,
        ourPartners,
        testimonials,
        lastNews,
    };
}
