import axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios";
import { ERROR_VALUES, ILoginResponse, SUCCESS_VALUES } from "./types";

export const loginUser = async (cancelToken: CancelTokenSource): Promise<ERROR_VALUES | SUCCESS_VALUES> => {
    try {
        const response: AxiosResponse<ILoginResponse> = await axios.post(
            'https://tts.true-bar.si/token',
            new URLSearchParams({
              'grant_type': '',
              'username': (window as any)._env_.REACT_APP_USER,
              'password': (window as any)._env_.REACT_APP_PASSWORD.slice(1, -1),
              'scope': '',
              'client_id': '',
              'client_secret': ''
            }),
            {
              headers: {
                'accept': 'application/json'
              },
              cancelToken: cancelToken.token
            },
          );

        localStorage.setItem('token', response.data.access_token);
        return SUCCESS_VALUES.SUCCESS;
    } catch (error) {
        const axiosError = error as AxiosError
        if (axiosError.message === ERROR_VALUES.Canceled) {
            return ERROR_VALUES.Canceled
        } else if (axiosError.response && axiosError.response.status === 429) {
            return ERROR_VALUES.Too_many_requests
        }
        return ERROR_VALUES.Common
    }
}

export const getAudioUrlForText = async (text: string, cancelToken: CancelTokenSource): Promise<string | ERROR_VALUES> => {
    const data = JSON.stringify({
        userid: "vitasis-webpage",
        voice: "ziga",
        input_text: text,
        normalize: true,
        accentuate: true,
        simple_accentuation: true,
        use_cache: true,
        pace: 1,
        tokenize: true,
        pause_for_spelling: 250
    });

    let localToken = localStorage.getItem('token')
    if (!localToken) {
        const loginResponse = await loginUser(cancelToken)
        if (loginResponse !== SUCCESS_VALUES.SUCCESS) return ERROR_VALUES.Common
        localToken = localStorage.getItem('token')
    }

    try {
        const response: AxiosResponse<string> = await axios({
            method: 'post',
            url: `https://tts.true-bar.si/v1/speak`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localToken}`
            },
            responseType: 'blob',
            data,
            cancelToken: cancelToken.token
        })


        
        return window.URL.createObjectURL(new Blob([response.data], { type: 'audio/x-wav' }));;
    } catch (error) {
        const axiosError = error as AxiosError
        if (axiosError.message === ERROR_VALUES.Canceled) {
            return ERROR_VALUES.Canceled
        } else if (axiosError.response && axiosError.response.status === 429) {
            return ERROR_VALUES.Too_many_requests
        } else if (axiosError.response && axiosError.response.status === 401) {
            localStorage.removeItem('token')
            return await getAudioUrlForText(text, cancelToken)
        }
        return ERROR_VALUES.Common
    }
}