import styled from "styled-components";

const CategoryTitle = styled.h1<{isSmaller: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: ${props => props.isSmaller ? "24" : "35"}px;
  line-height: ${props => props.isSmaller ? "36" : "52"}px;
  letter-spacing: 0.1em;
  color: #000000;
`

export default CategoryTitle;