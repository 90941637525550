import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../../Contexts/AppStateProvider';
import useTheme from '../../contexts/Theme/ThemeContext';
import IntroductionBusiness from '../../assets/images/business/Introduction.png';
import IntroductionFunky from '../../assets/images/funky/Introduction.png';
import { ColorSchemes } from '../../contexts/Theme/types';
import SubHeaderText, { SubHeaderTextContainer } from '../molecules/SubHeaderText';
import useTranslate from '../../contexts/Translate/TranslateContext';

const SubHeaderContent = () => {

  const { isMobile, isTablet } = useAppStateContext();
  const { colorScheme } = useTheme()
  const { selectedLanguageValues } = useTranslate()

  return (
    <CurvedSubHeaderContent isMobile={isMobile} isTablet={isTablet}>
      {!isMobile && (<img alt="subheader" width='50%' src={colorScheme === ColorSchemes.Funky ? IntroductionFunky : IntroductionBusiness}/>)}
      
      <SubHeaderTextWrapper isMobile={isMobile} isTablet={isTablet}>
        <SubHeaderText header={selectedLanguageValues.subheader.firstTitle} description={selectedLanguageValues.subheader.firstDescription} />
        <SubHeaderText header={selectedLanguageValues.subheader.secondTitle} description={selectedLanguageValues.subheader.secondDescription} />
      </SubHeaderTextWrapper>

      {isMobile && (<img alt="subheader" width='90%' src={colorScheme === ColorSchemes.Funky ? IntroductionFunky : IntroductionBusiness}/>)}
    </CurvedSubHeaderContent>

  )
}

export default SubHeaderContent;

const CurvedSubHeaderContent = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    align-items: center;
    justify-content: space-between;
`

const SubHeaderTextWrapper = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
    display: flex;
    flex-direction: column;

    & ${SubHeaderTextContainer}:first-child {
      margin-bottom: ${props => props.isMobile ? "40" : props.isTablet ? "24" : "35"}px;
    }

    & ${SubHeaderTextContainer}:last-child {
      margin-bottom: ${props => props.isMobile ? "40" : "0"}px;
    }
`