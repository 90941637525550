import styled from "styled-components";

const CardTitle = styled.p<{isSmaller: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.isSmaller ? "24" : "32"}px;
  line-height: ${props => props.isSmaller ? "36" : "48"}px;
  color: #2C2C2C;
  margin: 0;
  max-width: 400px;
`

export default CardTitle;