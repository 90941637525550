import React from 'react';

export interface IVersions {
    englishFileName: string,
    slovenianFileName: string,
}

interface IDownloadSection {
    versionName: string;
    filePathPrefix: string;
}
const DownloadSection = ({
    versionName,
    filePathPrefix
}: IDownloadSection) => {
    return (
        <a
            style={{
                padding: '2vh',
                fontSize: 16,
                fontWeight: 'bold',
                color: '#C2D5ED',
                textDecoration: 'none',
            }}
            download={versionName}
            href={`${filePathPrefix}${versionName}`}>
            {versionName}
        </a>
    )
}

export default DownloadSection;