import React from 'react';
import styled from 'styled-components';
import { useAppStateContext } from "../../../../Contexts/AppStateProvider";
import HeaderContent from '../components/organisms/HeaderContent';
import useTheme from '../contexts/Theme/ThemeContext';
import logo from '../../../../assets/images/vitasis-logo-white.png';
import { useHistory } from 'react-router';

const MainHeader = () => {

  const { isMobile, isTablet } = useAppStateContext();
  const { colorSchemeValues } = useTheme()
  const history = useHistory();

  const handleLogoClick = () => history.push('/home');

  return (
    <CurvedHeaderContainer isTablet={isTablet} isMobile={isMobile} backgroundColor={colorSchemeValues.headerBackground} relativeBackgroundColor={colorSchemeValues.subHeaderBackground}>
        <VitasisLogoWrapper isMobile={isMobile} isTablet={isTablet} onClick={handleLogoClick}>
          <VitasisLogoImage src={logo} />
        </VitasisLogoWrapper>
      <HeaderContent />
    </CurvedHeaderContainer>
  )
}

export default MainHeader;


const CurvedHeaderContainer = styled.div<{ isTablet: boolean | undefined, isMobile: boolean | undefined, backgroundColor: string, relativeBackgroundColor: string }>`
    position: relative;
    width: 100%;
    
    overflow: hidden;
    background-color: ${props => props.relativeBackgroundColor};
    padding: ${props => props.isTablet ? "0 40px 140px 48px" : props.isMobile ? "0" : "62px 66px 136px 160px"};

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.backgroundColor};
        border-radius: 0 0 50% 50% / 0 0 100% 100%;
        transform: scaleX(${props => props.isMobile ? "3" : "2"});
    }
`

const VitasisLogoWrapper = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
    z-index: 10;
    position: absolute;
    top: ${props => props.isMobile ? "20" : props.isTablet ? "40" : "42"}px;
    left: ${props => props.isMobile ? "20" : props.isTablet ? "40" : "62"}px;
    width: ${props => props.isMobile ? "60" : "20"}%;
`

const VitasisLogoImage = styled.img`
    width: 100%;
`