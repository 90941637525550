import React from 'react';

interface Props {
    callback?: () => void
    isMobileOrTablet: boolean | undefined;
    headerText: string;
    image: string;
    content: JSX.Element;
    clickText: string;
    href?: string;
}
const SupportCard = (props: Props) => {
    const {
        isMobileOrTablet,
        headerText,
        image,
        href,
        content,
        clickText,
        callback
    } = props
    if (href && callback) {
        throw new Error('[SupportCard.tsx]: callback and ref cannot be present at same time!')
    } else if (href) {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{backgroundColor: "#c2d5ed", padding: `1vh ${isMobileOrTablet ? '5vw' : '2vw'}`, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h2 style={{color: '#0E0E0E'}}>{headerText}</h2>
                    <img src={image} width={'10%'} />
                </div>
                <div style={{display: 'flex', flex: isMobileOrTablet ? 0 : 1, flexDirection: 'column', justifyContent: 'space-between', padding: `1vh ${isMobileOrTablet ? '5vw' : '2vw'}`, backgroundColor: '#0E0E0E'}}>
                    {content}
                    <a href={href} target="_blank" style={{textDecoration: 'none', width: 'fit-content'}}>
                        <p style={{color: '#ffb394'}}>
                            {clickText}
                        </p>
                    </a>
                </div>

            </div>
        )
    } else if (callback) {
        return (
            <div onClick={callback} style={{textDecoration: 'none', backgroundColor: '#0E0E0E', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                <h2 style={{color: '#c2d5ed', textAlign: 'center'}}>{headerText}</h2>
                <img src={image} width={isMobileOrTablet ? '30%' : '25%'} />
                <p style={{color: 'white', textAlign: 'center'}}>{content}</p>
            </div>
        )
    } else {
        throw new Error('[SupportCard.tsx]: callback or href must be present!')
    }
}

export default SupportCard;