import React from "react";
import styled from "styled-components";
import { useAppStateContext } from "../../../../../Contexts/AppStateProvider";
import useTheme from "../../contexts/Theme/ThemeContext";
import useTranslate from "../../contexts/Translate/TranslateContext";
import HeaderInput from "../atoms/HeaderInput";
import TruebarSubtitle from "../atoms/TruebarSubtitle";
import TruebarTitle from "../atoms/TruebarTitle";

const HeaderText = () => {

  const { isMobile, isTablet } = useAppStateContext()
  const { colorSchemeValues } = useTheme()
  const { selectedLanguageValues } = useTranslate()
  const truebarTitle = "TRUEBAR"

  let content = (
    <>
      <TextWrapper>
        <TruebarTitle isTablet={isTablet} textColor={colorSchemeValues.extraordinary} isMobile={isMobile}>{truebarTitle}</TruebarTitle>
        <TruebarSubtitle isMobile={isMobile} isTablet={isTablet}>{selectedLanguageValues.header.title}</TruebarSubtitle>
      </TextWrapper>
      <HeaderInput />
    </>
  )
  return (
    <HeaderTextWrapper isMobile={isMobile}>
      {content}
    </HeaderTextWrapper>
  )
}

export default HeaderText

const HeaderTextWrapper = styled.div<{ isMobile: boolean | undefined }>`
  max-width: 529px;
  width: 100%;
`

const TextWrapper = styled.div`
  margin-left: 16px;
`