import React from 'react';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import Routes, { mainRoutes } from './Routes';

//import {apiEndpoint} from "./prismic-config"

//const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
//const repoName = repoNameArray[1]
const googleAnalyticsId = (window as any)._env_.REACT_APP_GOOGLE_ANALYTICS_ID;

export default function App(): JSX.Element {
    useGoogleAnalytics(googleAnalyticsId);

    return <Routes routes={mainRoutes} />;
}
