import styled from "styled-components";

const TruebarTitle = styled.h1<{textColor: string, isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: ${props => props.isMobile ? "16" : "25"}px;
  line-height: ${props => props.isMobile ? "24" : "37"}px;
  letter-spacing: 0.7em;
  color: ${props => props.textColor};
  margin: 0;
  margin-bottom: ${props => props.isTablet ? "10" : "15"}px;
`
export default TruebarTitle;