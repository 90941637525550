import React from 'react';
import CategoryContainer, { CategoryKind } from '../components/organisms/CategoryContainer';
import { ColorSchemes } from '../contexts/Theme/types';
import useTheme from '../contexts/Theme/ThemeContext';

import RecognitionFunky from '../assets/images/funky/Recognition.png';
import RecognitionBusiness from '../assets/images/business/Recognition.png';
import styled from 'styled-components';
import { useAppStateContext } from '../../../../Contexts/AppStateProvider';
import useTranslate from '../contexts/Translate/TranslateContext';


const Recognition = () => {
  const { colorScheme } = useTheme()
  const { isMobile, isTablet } = useAppStateContext()

  const {selectedLanguageValues} = useTranslate();

  return (
    <RecognitionContainer isMobile={isMobile} isTablet={isTablet}>
      <RecognitionContent isMobile={isMobile}>
        <CategoryContainer
                  categoryKind={CategoryKind.TextLeftPlaced}
                  image={colorScheme === ColorSchemes.Funky ? RecognitionFunky : RecognitionBusiness}
                  categoryTitle={selectedLanguageValues.categoryTranslate.Recognition}
                  categoryDescription={selectedLanguageValues.recognitionTranslates.desription}
                  categoryProductsTitle={selectedLanguageValues.recognitionTranslates.subtitle}
                  categoryProductsList={selectedLanguageValues.recognitionTranslates.products}/>
      </RecognitionContent>
    </RecognitionContainer>
  )
}

export default Recognition;

const RecognitionContainer = styled.div<{isMobile: boolean | undefined, isTablet: boolean | undefined}>`
  display: flex;
  justify-content: center;
  align-items: center;  
  padding: ${props => props.isMobile ? "40px 32px" : props.isTablet ? "80px 64px 57px 64px" : "184px 60px 57px 60px"};
  width: 100%;
`

const RecognitionContent = styled.div<{isMobile: boolean | undefined}>`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
  align-items: center;
  justify-content: space-between;
`