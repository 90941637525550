import React from 'react';
import styled from 'styled-components';

import { useAppStateContext } from '../../../Contexts/AppStateProvider';
import { Languages } from '../../../Contexts/types';
import { cookiesDialog } from '../../../data/texts';

function CookiesDialog(): JSX.Element {
    const { currentLanguage } = useAppStateContext();
    return (
        <ReadMeDialogWrapper>
            {currentLanguage === Languages.SI && (
                <>
                    <MainTitle>{cookiesDialog.title.si}</MainTitle>
                    <ReadMeDescriptionWrapper>
                        <p>{cookiesDialog.terms.si}</p>
                    </ReadMeDescriptionWrapper>
                </>
            )}
            {currentLanguage === Languages.EN && (
                <>
                    <MainTitle>{cookiesDialog.title.en}</MainTitle>
                    <ReadMeDescriptionWrapper>
                        <p>{cookiesDialog.terms.en}</p>
                    </ReadMeDescriptionWrapper>
                </>
            )}
        </ReadMeDialogWrapper>
    );
}

export default CookiesDialog;

const ReadMeDialogWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const MainTitle = styled.h1`
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;

    margin: 0;
    padding: 0;
    margin-bottom: 25px;
`;

const ReadMeDescriptionWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;

    & p {
        margin: 0px 0px 10px;
        padding: 0px;
        font-family: Roboto;
        text-align: justify;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 28px;
        letter-spacing: normal;
        color: rgb(0, 0, 0);
    }
`;
